import * as React from "react";
import { List, Datagrid, TextField, EditButton, BooleanField } from 'react-admin';
import { Grid, Box, Typography, Card, CardContent } from '@mui/material';
import { LoadingComponent } from '../LoadingComponent';
import { HelpOutline } from '@mui/icons-material';

export const AccompagnatoriList = () => {

    return (
        <List sort={{ field: 'nominativo', order: 'ASC' }} perPage={50} exporter={false} pagination={false}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="nominativo" sortable={false} />
                <TextField source="telefono" />
                <BooleanField source="stato" valueLabelTrue="Attivo" valueLabelFalse="Disattivo" textAlign="center" />
                <EditButton label="Modifica" textAlign="center" />
            </Datagrid>
        </List>
    )
};