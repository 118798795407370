import { useEffect, useState } from 'react';
import { useLogin, useNotify, useRedirect } from 'react-admin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { generatePassword } from './components/PasswordGenerator';
import { apiFullUrl, apiGuestFullUrl } from "./components/generale";
import axios from 'axios';
import bcrypt from 'bcryptjs';

const TastoLoginGoogle = ({tipo}) => {
    const CLIENT_ID = "866327779349-j4b25jgjdcbfuceag6atl78jqfhd0r0n.apps.googleusercontent.com";
    const login = useLogin();
    const notify = useNotify();
    const redirect = useRedirect();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [profile, setProfile] = useState(null);

    const checkRedirectAlViaggio = () => {
        if (sessionStorage.getItem("vid")) {
            window.location.href = '/#/richiesta/create/' + sessionStorage.getItem("vid");
            // redirect('create', 'richiesta', sessionStorage.getItem("vid"));
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (profile && password) {
            // console.log(profile);
            axios.post(apiGuestFullUrl + '/checkgoogleuser', new URLSearchParams({ token: profile }))
            .then(async response => {
                console.log(response);
                if (response.data) {
                    var bodyFormData = new URLSearchParams();
                    bodyFormData.append('username', response.data.email);
                    bodyFormData.append('password', password);
                    axios.post(apiFullUrl + '/register', bodyFormData)
                        .then(async response2 => {
                            // console.log(response);
                            bodyFormData.delete('username');
                            bodyFormData.delete('password');
                            bodyFormData.append('nominativo', response.data.name);
                            bodyFormData.append('g_id', response.data.sub);
                            await axios.put(apiFullUrl + '/records/utente/' + response2.data.id, bodyFormData, { headers: { 'X-API-Key': response2.data.api_key } })
                                .then(async () => {
                                    const logData = {
                                        id_logs_operazioni: 4,
                                        id_utente: response2.data.id,
                                        dettaglio: 'Google'
                                    };
                                    await axios.post(apiFullUrl + '/records/logs/', logData, {
                                        headers: { 'X-API-Key': response2.data.api_key, 'Content-Type': 'application/json' }
                                    });
                                    login({ username: response.data.email, password: password })
                                    .then(() => {
                                        if (!checkRedirectAlViaggio())
                                        redirect('/utente/' + response2.data.id)
                                    })
                                    .catch(() =>
                                        notify('Errore nel login')
                                    );
                                })
                        }).catch(function (error) {
                            // console.log(error);
                            if (error.response.data.code === 1020) {
                                // se l'indirizzo email esiste già verificare che coincida anche g_id e quindi procedere al login piuttosto che alla registrazione di un nuovo utente
                                bodyFormData.delete('username');
                                bodyFormData.append('nominativo', response.data.name);
                                bodyFormData.append('password', bcrypt.hashSync(password));
                                bodyFormData.append('g_id', response.data.sub);
                                axios.put(apiGuestFullUrl + '/updategoogleuser', bodyFormData)
                                    .then(() => {
                                        login({ username: response.data.email, password: password })
                                            .then(() => {
                                                checkRedirectAlViaggio();
                                                // redirect('/')
                                            })
                                            .catch(() =>
                                                notify('Impossibile effettuare il login', { type: 'error' })
                                            );
                                    });

                            } else {
                                notify('Errore nella procedura di registrazione. Verificare i dati inseriti e riprovare. Se il problema persiste contattaci.', { type: 'warning' });
                            }
                        });
                } else {
                    notify('Impossibile effettuare il login o la registrazione', { type: 'error' });
                }
            });
        }
    }, [profile, password])

    return (
        <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleLogin
                theme='filled_black'
                text={tipo === 'login' ? 'signin_with' : 'signup_with'}
                onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                    setProfile(credentialResponse.credential);
                    setPassword(() => generatePassword());
                }}
                onError={() => {
                    console.log('Impossibile accedere');
                }}
            />
        </GoogleOAuthProvider>
    )
}

export default TastoLoginGoogle;