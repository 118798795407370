import React from "react";

function IconaNatale() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g>
        <path
          fillRule="nonzero"
          d="M234.478 947.362h237.63v84.871c0 9.368 7.601 16.968 16.969 16.968h101.84c9.368 0 16.982-7.6 16.982-16.968v-84.871h237.617c6.644 0 12.65-3.867 15.414-9.886a16.924 16.924 0 00-2.525-18.139l-136.03-158.686h89.203c6.684 0 12.743-3.933 15.494-10.045 2.724-6.086 1.621-13.209-2.817-18.232L713.592 607.892h64.035a16.933 16.933 0 0015.467-9.953 16.943 16.943 0 00-2.684-18.204L681.369 455.133h45.338a16.98 16.98 0 0015.414-9.834c2.778-5.993 1.834-13.075-2.445-18.111L552.966 206.54a17.6 17.6 0 00-12.969-5.727 17.6 17.6 0 00-12.969 5.727L340.317 427.188a16.967 16.967 0 00-2.431 18.111 16.96 16.96 0 0015.401 9.834h45.338L289.597 579.735a16.95 16.95 0 00-2.698 18.204 16.95 16.95 0 0015.468 9.953h64.048L255.739 732.374a16.97 16.97 0 00-2.804 18.232 16.953 16.953 0 0015.481 10.045h89.202L221.589 919.337a17.004 17.004 0 00-2.538 18.139 16.998 16.998 0 0015.427 9.886m339.47 67.875h-67.889v-67.875h67.889v67.875zM407.409 754.725a16.979 16.979 0 002.524-18.125 16.953 16.953 0 00-15.414-9.9H306.26l110.663-124.509a16.955 16.955 0 002.804-18.244 16.942 16.942 0 00-15.521-10.006h-64.5l109.042-124.602c4.385-4.996 5.435-12.105 2.711-18.178a17.027 17.027 0 00-15.428-9.993h-46.149l150.115-177.395 150.115 177.395h-46.149a17.003 17.003 0 00-15.481 9.966 17 17 0 002.698 18.205l109.108 124.602h-64.5a16.983 16.983 0 00-15.494 10.019 16.958 16.958 0 002.817 18.231L773.774 726.7h-88.26c-6.63 0-12.65 3.867-15.414 9.9a16.926 16.926 0 002.525 18.125l135.99 158.686H271.366l136.043-158.686z"
        ></path>
        <path
          fillRule="nonzero"
          d="M481.41 150.909l-8.013 46.761c-1.089 6.352 1.528 12.783 6.751 16.597a17.048 17.048 0 0017.885 1.275l41.964-22.071 41.99 22.071a17.027 17.027 0 0017.873-1.275c5.222-3.814 7.84-10.245 6.75-16.597l-8.013-46.761 33.951-33.14c4.558-4.518 6.192-11.229 4.186-17.328a16.923 16.923 0 00-13.607-11.521l-46.92-6.79-20.929-42.654a17.666 17.666 0 00-15.215-8.677 17.645 17.645 0 00-15.201 8.677l-21.075 42.535-46.92 6.79a16.952 16.952 0 00-13.7 11.547c-2.007 6.139-.332 12.876 4.279 17.394l33.964 33.167zm36.09-36.582a16.924 16.924 0 0012.77-9.288l9.727-19.706 9.727 19.706a16.967 16.967 0 0012.783 9.288l21.726 3.15-15.72 15.281a17.023 17.023 0 00-4.863 15.029l3.68 21.646-19.413-10.192a17.083 17.083 0 00-15.826 0l-19.414 10.192 3.68-21.527c.931-5.501-.877-11.109-4.863-14.989l-15.72-15.294 21.726-3.296zM522.786 706.93c-.983-5.833-4.93-10.763-10.431-12.955-5.501-2.219-11.747-1.369-16.49 2.166l-67.902 50.933a16.975 16.975 0 00-6.751 14.444c.293 5.621 3.362 10.776 8.199 13.673l84.871 50.933a16.94 16.94 0 0018.271-.545 16.967 16.967 0 007.189-16.809l-16.956-101.84zm-54.308 52.169l25.354-19.028 6.325 38.017-31.679-18.989zM611.786 591.374a16.934 16.934 0 00-15.893 4.518l-67.902 67.875c-4.146 4.186-5.873 10.205-4.504 15.919a16.894 16.894 0 0011.148 12.198l101.84 33.965c1.741.558 3.548.85 5.368.85a16.962 16.962 0 0013.753-7.056 16.923 16.923 0 002.326-15.268l-33.938-101.853a16.964 16.964 0 00-12.198-11.148m-40.343 76.991l29.021-29.061 14.537 43.598-43.558-14.537z"
        ></path>
        <path d="M573.952 743.672H607.903V777.636H573.952z"></path>
        <path d="M438.161 607.895H472.099V641.846H438.161z"></path>
        <path d="M489.081 556.975H523.032V590.913H489.081z"></path>
        <path d="M540.001 319.345H573.952V353.29600000000005H540.001z"></path>
        <path d="M607.903 506.042H641.841V539.9929999999999H607.903z"></path>
        <path d="M624.872 794.592H658.823V828.556H624.872z"></path>
        <path
          fillRule="nonzero"
          d="M498.688 504.353a16.998 16.998 0 0017.979-2.047l84.871-67.901a16.964 16.964 0 006.192-15.734 16.966 16.966 0 00-10.511-13.274l-84.871-33.951a16.951 16.951 0 00-15.826 1.727 16.935 16.935 0 00-7.441 14.059v101.853c.013 6.511 3.734 12.451 9.607 15.268m24.344-92.06l34.536 13.82-34.536 27.639v-41.459z"
        ></path>
      </g>
    </svg>
  );
}

export default IconaNatale;