import { useEffect, useState } from "react";
import { useRecordContext, useGetOne, useGetList, useDelete, useCreate } from 'react-admin';
import { IconButton, Button, Typography } from '@mui/material';
import moment from 'moment';
import { Page, Text, Image, View, Document, StyleSheet, usePDF, Font, pdf } from '@react-pdf/renderer';
import fileDownload from 'js-file-download';
import { PictureAsPdf } from '@mui/icons-material';
import { myTheme, dataProvider, valutaFormat } from "../generale.js";
// import { calcoloPreventivo } from './Preventivo.js'

export const GeneraPDFRichiestaBKP = ({ idViaggio, stile, ...props }) => {
    const datiPdfRichiesta = useRecordContext();
    const nomeFile = `R${datiPdfRichiesta.id} - ${datiPdfRichiesta.cognome} ${datiPdfRichiesta.nome}.pdf`;
    const [eliminaBlobPdf] = useDelete('richiesta_pdf_finale', { id: datiPdfRichiesta.id });
    const [create] = useCreate();
    const [fileURL, setFileURL] = useState(null);
    // const [datiPreventivo, setDatiPreventivo] = useState({});
    const [instance, updateInstance] = usePDF();

    const { data: datiPdfViaggio, isLoading: isLoadingViaggio, error: errorViaggio } = useGetOne('viaggio', { id: datiPdfRichiesta.id_viaggio });
    const { data: datiPdfEmailFinale, isLoading: loadingEmailFinale, error: errorEmailFinale } = useGetOne('richiesta_email_finale', { id: datiPdfRichiesta.id });
    const { data: datiPdfPartecipanti, isLoading: loadingPartecipanti, error: errorPartecipanti } = useGetList(
        'richiesta_dettagli_partecipanti',
        {
            filter: { id_richiesta: datiPdfRichiesta.id },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'alloggio', order: 'ASC' }
        }
    );
    const { data: datiPdfAccompagnatori, isLoading: loadingAccompagnatori, error: errorAccompagnatori } = useGetList(
        'accompagnatore',
        {
            // filter: { id_in: [] },
            filter: { id_in: (datiPdfEmailFinale && datiPdfEmailFinale.accompagnatore ? (datiPdfEmailFinale.accompagnatore).split(",") : []) },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' }
        }
    );
    const { data: datiPdfAgenzia, isLoading: isLoadingAgenzia, error: errorAgenzia } = useGetOne('agenzia', { id: (datiPdfViaggio ? datiPdfViaggio.id_agenzia : 0) }, { enabled: !isLoadingViaggio });

    const { data: configSconti } = useGetList('configurazioni', {
        pagination: { perPage: 99 },
        sort: { field: 'id', order: 'ASC' },
        filter: { nome_in: ['sconto_bambini_1', 'sconto_bambini_3'] }
    });

    // useEffect(() => {
    //     console.log((datiPdfEmailFinale && datiPdfEmailFinale.accompagnatore ? datiPdfEmailFinale.accompagnatore : []));
    // }, [datiPdfEmailFinale])

    function handleClick() {
        // console.log('handleClick');
        if (fileURL) {
            // console.log(fileURL);
            fileDownload(fileURL, nomeFile);
            eliminaBlobPdf();
            const blob = pdf(DocPDF).toBlob();
            console.log(blob);
            // create('richiesta_pdf_finale', { data: {
            //     id: datiPdfRichiesta.id,
            //     blob_pdf: instance.blob
            // } })
        } else {
            // listaPerPdf();
        }
    }

    // PREVENTIVO
    // useEffect(() => {
    //     if (datiPdfViaggio && datiPdfRichiesta && datiPdfPartecipanti && datiPdfEmailFinale  && datiPdfAccompagnatori && configSconti) {
    //         // console.log(datiPdfViaggio);
    //         // console.log(datiPdfRichiesta);
    //         // console.log(datiPdfPartecipanti);
    //         const data = {
    //             prezzo: datiPdfRichiesta.prezzo,
    //             suppl_singola: datiPdfViaggio.suppl_singola,
    //             n_singole: datiPdfRichiesta.cam_singola,
    //             acconto: datiPdfViaggio.acconto,
    //             sconto: {
    //                 perc: datiPdfRichiesta.sconto_perc,
    //                 amm: datiPdfRichiesta.sconto_amm
    //             },
    //             partecipanti: {
    //                 adulti: datiPdfRichiesta.n_adulti,
    //                 bambini_1: datiPdfRichiesta.n_bambini_1,
    //                 bambini_3: datiPdfRichiesta.n_bambini_3,
    //                 bambini_4:  datiPdfRichiesta.n_bambini_4,
    //             },
    //             totPartecipanti: (
    //                 Number((datiPdfRichiesta.n_adulti || 0))
    //                 + Number((datiPdfRichiesta.n_bambini_1 || 0))
    //                 + Number((datiPdfRichiesta.n_bambini_3 || 0))
    //                 + Number((datiPdfRichiesta.n_bambini_4 || 0))
    //             )
    //         };
    //         const dati = calcoloPreventivo(data, false, configSconti);
    //         setDatiPreventivo(dati);
    //     }
    // }, [datiPdfViaggio, datiPdfRichiesta, datiPdfPartecipanti, datiPdfEmailFinale, datiPdfAccompagnatori]);

    useEffect(() => {
        if (nomeFile && datiPdfViaggio && datiPdfRichiesta && datiPdfPartecipanti && datiPdfEmailFinale && datiPdfAccompagnatori && datiPdfAgenzia) {
            // console.log(datiPdfViaggio);
            // console.log(datiPdfRichiesta);
            // console.log(datiPdfPartecipanti);
            // console.log(datiPdfEmailFinale);
            // console.log(datiPdfAccompagnatori);
            // console.log(datiPreventivo);

            updateInstance(
                <DocPDF
                    nomeFile={nomeFile}
                    richiesta={datiPdfRichiesta}
                    viaggio={datiPdfViaggio}
                    partecipanti={datiPdfPartecipanti}
                    emailFinale={datiPdfEmailFinale}
                    accompagnatori={datiPdfAccompagnatori}
                    // preventivo={datiPreventivo}
                    agenzia={datiPdfAgenzia}
                />
            )
        }
    }, [nomeFile, datiPdfViaggio, datiPdfRichiesta, datiPdfPartecipanti, datiPdfEmailFinale, datiPdfAccompagnatori, datiPdfAgenzia]);

    // useEffect(() => {
    //     if (datiPreventivo && datiPdfRichiesta.id === 66)
    //     console.log(datiPreventivo);
    // }, [datiPreventivo]);

    /*
    useEffect(() => {
        if (fileURL) {
            // console.log(fileURL, nomeFile);
            // console.log(link);
            // link.click();
            // fileDownload(fileURL, nomeFile);
        }
    }, [fileURL]);
    */

    useEffect(() => {
        // console.log('update 2');
        // console.log(instance);
        if (instance && instance.url) {
            setFileURL(instance.blob);
            // setFileURL(URL.createObjectURL(instance.blob));
        }
    }, [instance]);

    return (
        <>
            <IconButton {...props} variant="iconaLista" color="primary" onClick={() => handleClick()}><PictureAsPdf /></IconButton>
        </>
    )
}

export function generaPDFRichiesta (rId) {
    const allData = dataProvider.getOne('richiesta', { id: rId }).then(async (r) => {
        const v = await dataProvider.getOne('viaggio', { id: r.data.id_viaggio });
        const ef = await dataProvider.getOne('richiesta_email_finale', { id: r.data.id });
        const rdp = await dataProvider.getList('richiesta_dettagli_partecipanti', {
            filter: { id_richiesta: r.data.id },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'alloggio', order: 'ASC' }
        });
        const acc = await dataProvider.getList('accompagnatore', {
            filter: { id_in: (r && r.data.accompagnatore ? (r.data.accompagnatore).split(",") : []) },
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' }
        });
        const ag = await dataProvider.getOne('agenzia', { id: v.data.id_agenzia });
        return {
            r: r.data,
            v: v.data,
            ef: ef.data,
            rdp: rdp.data,
            acc: acc.data,
            ag: ag.data
        }
    });
    return allData;
}

export const DocPDF = ({ r, v, ef, rdp, acc, ag }) => {
    Font.register({ family: 'Roboto', src: process.env.PUBLIC_URL + "/roboto-medium-webfont.ttf" });
    Font.register({ family: 'Roboto Black', src: process.env.PUBLIC_URL + "/roboto-black-webfont.ttf" });
    Font.register({ family: 'Patrick Hand', src: process.env.PUBLIC_URL + "/PatrickHand-Regular.ttf" });
    Font.registerEmojiSource({ format: 'png', url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/' });
    const nomeFile = `R${r.id} - ${r.cognome} ${r.nome}.pdf`;

    // console.log(v,r,p);
    // console.log(ag);
    // if (r.id === 66)
    // console.log(prev);

    function htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    const style = StyleSheet.create({
        grassetto: {
            fontFamily: 'Roboto Black'
        },
        testoPrimary: {
            color: myTheme.palette.primary.main
        },
        testoSecondary: {
            color: myTheme.palette.secondary.main
        },
        intestazione: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
        },
        evidenziazione: {
            paddingHorizontal: 5,
            backgroundColor: myTheme.palette.secondary.main,
            color: "#fff",
            borderRadius: 2
        },
        cornice: {
            borderColor: myTheme.palette.secondary.main,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 2,
            padding: 3,
            marginBottom: 10,
        },
        intestazione2: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
            col1: {
                flex: 1,
            },
            col2: {
                // flex: 1,
                flexBasis: 'auto',
                padding: 5,
                textAlign: 'right',
                fontWeight: 'normal',
                fontSize: 12,
                rigaTesto: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    textTransform: 'uppercase'
                }
            },
        },
        rigaBg: {
            backgroundColor: '#e3e3e3',
        },
        imgViaggio: {
            borderRadius: 2,
        },
        titoloViaggio: {
            fontFamily: 'Patrick Hand',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: myTheme.palette.primary.main,
            fontSize: 16,
        },
        sottotitoloViaggio: {
            // fontFamily: 'Patrick Hand',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: myTheme.palette.success.main,
            fontSize: 11.5,
        },
        titoloSezione: {
            fontFamily: 'Patrick Hand',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            backgroundColor: myTheme.palette.secondary.main,
            color: '#fff',
            paddingHorizontal: 5,
            borderRadius: 2,
            fontSize: 12,
            // marginTop: 10,
            marginBottom: 5,
        },
        riga: {
            display: 'flex',
            // display: '-webkit-flex',
            flexDirection: 'row',
            flexFlow: 'row wrap',
            // paddingVertical: 2,
            // paddingHorizontal: 2,
            fontSize: 8,
            // textTransform: 'uppercase',
            padding: '1px 5px',
            fontFamily: 'Roboto',
            gap: 10
        },
        testoProgramma: {
            fontSize: 6.5
        },
        sezione1: {
            marginBottom: 25,
            col1: {
                flex: 4
            },
            col2: {
                flex: 6
            },
        },
        sezione2: {
            col1: {
                flex: 7,
                tabella: {
                    riga: {
                        display: 'flex',
                        flexDirection: 'row',
                    },
                    intestazione: {
                        backgroundColor: myTheme.palette.primary.main,
                        color: "#fff",
                    },
                    col1: {
                        flex: 4.7
                    },
                    col2: {
                        flex: 2
                    },
                    col3: {
                        flex: 3
                    },
                    col4: {
                        flex: 3
                    },
                }
            },
            col2: {
                flex: 4
            },
        },
        sezione3: {
            flex: 1,
            width: "100%"
        },
        separatoreRiga: {
            height: 1,
            backgroundColor: myTheme.palette.primary.main,
            marginVertical: '3px',
        },
        rigaChild: {
            flex: 1,
            fontSize: 6.5,
            lineHeight: 1.1,
            // padding: '1px 0px 2px 0px',
            paddingBottom: '2px',
            // borderBottom: '1px solid #bbb',
        },
        footer: {
            position: 'absolute',
            bottom: 20,
            left: 20,
            right: 20,
            color: myTheme.palette.grigio[500],
            // textAlign: 'center',
            fontSize: 8,
            textTransform: 'uppercase',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            pageNumber: {
                fontSize: 7,
            },
            imgAg: {
                marginTop: 5,
                height: 25,
                width: 'auto',
            }
        }
    });
    if (!v || !r || !rdp || !ef) return null;

    const date = (v.data_inizio !== null && v.data_inizio !== '' ? (v.data_inizio !== v.data_fine && v.data_fine !== '0000-00-00' ? `Dal ` : ``) + `${moment(v.data_inizio).format("DD/MM/YYYY")}` : ``) +
        (v.data_fine !== null && v.data_fine !== '' && v.data_fine !== '0000-00-00' ? (v.data_inizio !== v.data_fine && v.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(v.data_fine).format("DD/MM/YYYY")}` : ``);
    const camere = [
        (r.cam_singola ? r.cam_singola + (parseInt(r.cam_singola) === 1 ? ' singola' : ' singole') : null),
        (r.cam_doppia ? r.cam_doppia + (parseInt(r.cam_doppia) === 1 ? ' doppia' : ' doppie') : null),
        (r.cam_matrimoniale ? r.cam_matrimoniale + (parseInt(r.cam_matrimoniale) === 1 ? ' matrimoniale' : ' matrimoniali') : null),
        (r.cam_tripla ? r.cam_tripla + (parseInt(r.cam_tripla) === 1 ? ' tripla' : ' triple') : null),
        (r.cam_quadrupla ? r.cam_quadrupla + (parseInt(r.cam_quadrupla) === 1 ? ' quadrupla' : ' quadruple') : null),
    ].filter(n => n);
    const partecipanti = [
        (r.n_adulti ? r.n_adulti + (parseInt(r.n_adulti) === 1 ? ' adulto' : ' adulti') : null),
        (r.n_bambini_1 ? r.n_bambini_1 + (parseInt(r.n_bambini_1) === 1 ? ' bambino 0-2 anni' : ' bambini 0-2 anni') : null),
        (r.n_bambini_3 ? r.n_bambini_3 + (parseInt(r.n_bambini_3) === 1 ? ' bambino 3-13 anni' : ' bambini 3-13 anni') : null),
        (r.n_bambini_4 ? r.n_bambini_4 + (parseInt(r.n_bambini_4) === 1 ? ' bambino 14-17 anni' : ' bambini 14-17 anni') : null),
    ].filter(n => n);
    const programma = htmlDecode((v.programma).replace(/([\n]?<p>[\xA0]?<\/p>[\n]?)/gi, ''));
    return (
        <Document title={nomeFile} author="Virgilio Viaggia" creator="Prenota con Virgilio Viaggia" producer="Prenota con Virgilio Viaggia">
            <Page size="A4" orientation='portrait' style={{ padding: 20 }}>
                <View>
                    <View style={style.intestazione} fixed>
                        <View style={style.intestazione2.col1}>
                            <Image src={process.env.PUBLIC_URL + '/logo.jpg'} style={{ maxWidth: "120px" }} />
                        </View>
                        <View style={[style.intestazione2.col2, style.cornice]}>
                            <View style={style.intestazione2.col2.rigaTesto}>
                                <Text>Riferimento richiesta:</Text>
                                <Text style={[style.evidenziazione, { marginLeft: 2 }]}>R{r.id}</Text>
                            </View>
                            <View style={[style.intestazione2.col2.rigaTesto, { marginTop: 2 }]}>
                                <Text>Referente:</Text>
                                <Text style={[style.evidenziazione, { marginLeft: 2 }]}>{r.cognome} {r.nome}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[style.riga, style.sezione1]}>
                        <View style={style.sezione1.col1}>
                            <Image style={style.imgViaggio} src={`https://www.${process.env.REACT_APP_DOMAIN}/${v.img}`} />
                            {/* <Image src={`https://www.${process.env.REACT_APP_DOMAIN}/${v.img_intro}`} /> */}
                        </View>
                        <View style={style.sezione1.col2}>
                            <Text style={style.sottotitoloViaggio}>Conferma di prenotazione</Text>
                            <Text style={style.titoloViaggio}>{v.titolo}</Text>
                            <Text>{htmlDecode(v.descr_breve)}</Text>
                            <Text><Text style={style.testoPrimary}>🗓️ Data:</Text> {date}</Text>
                            <Text style={{ marginTop: 10 }}><Text style={style.testoPrimary}>👬 Partecipanti:</Text> {partecipanti.map(p => p).join(", ")}</Text>
                            {camere.length > 0 ?
                                <Text><Text style={style.testoPrimary}>🛌 Camere:</Text> {camere.map(c => c).join(", ")}</Text>
                                : null}
                        </View>
                    </View>
                    <View style={style.riga}>
                        <View style={style.sezione2.col1}>
                            <View style={style.cornice}>
                                <Text style={style.titoloSezione}>Partecipanti</Text>
                                <View style={[style.riga,
                                style.sezione2.col1.tabella.riga,
                                style.sezione2.col1.tabella.intestazione]}>
                                    <Text style={style.sezione2.col1.tabella.col1}>Nominativo</Text>
                                    <Text style={style.sezione2.col1.tabella.col2}>Tel</Text>
                                    <Text style={style.sezione2.col1.tabella.col3}>Fermata</Text>
                                    {v.specifica_tipo === 2 ?
                                        <Text style={style.sezione2.col1.tabella.col4}>Camera</Text>
                                        : null}
                                </View>
                                {rdp.map((p, i) => {
                                    var alloggio = null;
                                    if (p.alloggio) {
                                        const a = (p.alloggio).split('_');
                                        switch (a[1]) {
                                            case 'sin':
                                                alloggio = 'Singola n.' + a[2]
                                                break;
                                            case 'dop':
                                                alloggio = 'Doppia n.' + a[2]
                                                break;
                                            case 'mat':
                                                alloggio = 'Matrimoniale n.' + a[2]
                                                break;
                                            case 'tri':
                                                alloggio = 'Tripla n.' + a[2]
                                                break;
                                            case 'qua':
                                                alloggio = 'Quadrupla n.' + a[2]
                                                break;
                                            default:
                                                break;
                                        }
                                    }

                                    return (
                                        <View style={[style.riga, style.sezione2.col1.tabella.riga, (i % 2 > 0 ? style.rigaBg : '')]} key={`partecipante_${i}`}>
                                            <Text style={style.sezione2.col1.tabella.col1}>{p.nominativo ?? 'N.D.'}</Text>
                                            <Text style={style.sezione2.col1.tabella.col2}>{(p.telefono ? p.telefono : null)}</Text>
                                            <Text style={style.sezione2.col1.tabella.col3}>{p.fermata ? p.fermata : null}</Text>
                                            {v.specifica_tipo === 2 ?
                                                <Text style={style.sezione2.col1.tabella.col4}>{alloggio ? alloggio : null}</Text>
                                                : null}
                                            {/* <Text style={style.rigaChild}>{p.nominativo ?? 'N.D.'}{(p.telefono ? ` (Tel. +39 ${p.telefono})` : null)}{p.fermata ? ` - Fermata: ${p.fermata}` : null}{alloggio ? ` - Camera: ${alloggio}` : null}</Text> */}
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={style.cornice}>
                                <Text style={style.titoloSezione}>Programma</Text>
                                <Text style={(programma.length >= 1000 ? style.testoProgramma : null)}>{programma}</Text>
                            </View>
                        </View>
                        <View style={style.sezione2.col2}>
                            <View style={style.cornice}>
                                <Text style={[style.titoloSezione, { marginTop: 0 }]}>Info utili</Text>
                                <Text style={style.testoPrimary}>📄 Partenza:</Text>
                                <Text>{htmlDecode(ef.dettaglio)}</Text>
                                <View style={style.separatoreRiga} />
                                {ef.mezzo ?
                                    <>
                                        <Text style={style.testoPrimary}>🚌 Mezzo assegnato:</Text>
                                        <Text>{ef.mezzo}</Text>
                                        <View style={style.separatoreRiga} />
                                    </>
                                    : null}
                                {acc.length > 0 ?
                                    <>
                                        <Text style={style.testoPrimary}>👥 Capogruppo:</Text>
                                        <Text style={style.grassetto}>{acc.map(e => `${e.nominativo} (Tel. ${e.prefisso} ${e.telefono})`).join("\n")}</Text>
                                        <View style={style.separatoreRiga} />
                                    </>
                                    : null}
                                <Text><Text style={style.testoPrimary}>📄 Documenti richiesti:</Text> {htmlDecode((v.documenti).replace(/([\n]?<p>[\xA0]?<\/p>[\n]?)/gi, ""))}</Text>
                                <View style={style.separatoreRiga} />
                                <Text><Text style={style.testoPrimary}>✅ Quota comprende:</Text> {htmlDecode((v.quota_incl).replace(/([\n]?<p>[\xA0]?<\/p>[\n]?)/gi, ""))}</Text>
                                <View style={style.separatoreRiga} />
                                <Text><Text style={style.testoPrimary}>🚫 Quota non comprende:</Text> {htmlDecode((v.quota_escl).replace(/([\n]?<p>[\xA0]?<\/p>[\n]?)/gi, ""))}</Text>
                            </View>
                        </View>
                    </View>
                    {/* <View style={style.riga}>
                        <View style={style.sezione3.col1}></View>
                        <View style={style.sezione3.col2}>
                            <Text style={style.titoloSezione}>Prezzo</Text>
                            {Number(prev.percSconto) || Number(prev.totSconto) ? 
                            <>
                                <View style={[ style.riga, style.sezione2.col2.tabella.riga ]}>
                                    <Text style={style.sezione2.col2.tabella.col1}>Tot. parziale</Text>
                                    <Text style={style.sezione2.col2.tabella.col2}>{valutaFormat.format(prev.totParziale)}</Text>
                                </View>
                                <View style={[ style.riga, style.sezione2.col2.tabella.riga ]}>
                                    <Text style={style.sezione2.col2.tabella.col1}>Sconto</Text>
                                    <Text style={style.sezione2.col2.tabella.col2}>{prev.percSconto ? `- ${prev.percSconto} %` : `- ${valutaFormat.format(prev.totSconto)}`}</Text>
                                </View>
                            </>
                            : null }
                            <View style={[ style.riga, style.sezione2.col2.tabella.riga ]}>
                                <Text style={style.sezione2.col2.tabella.col1}>Tot. finale</Text>
                                <Text style={style.sezione2.col2.tabella.col2}>{valutaFormat.format(prev.totFinale)}</Text>
                            </View>
                        </View>
                    </View> */}
                    <View style={style.riga} wrap={false}>
                        <View style={[style.cornice, style.sezione3, { borderColor: myTheme.palette.warning[800] }]}>
                            <Text style={[style.titoloSezione, { backgroundColor: myTheme.palette.warning[800] }]}>NOTA BENE</Text>
                            <Text>Il ritardo agli appuntamenti comporterà la partenza del pullman e la perdita dei diritti dell’utente sul viaggio.</Text>
                            <Text>Le informazioni presenti in questo documento sono valide salvo successive variazioni comunicate ai recapiti forniti durante la prenotazione.</Text>
                        </View>
                    </View>
                </View>
                <View style={[style.footer, style.riga]} fixed>
                    <View style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>Organizzazione tecnica: {ag.nome}</Text>
                        {/* <Image style={style.footer.imgAg} src={`https://www.${process.env.REACT_APP_DOMAIN}/${ag.img}`} /> */}
                    </View>
                    <View>
                        <Text style={style.footer.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} />
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export const DocPDFtest = ({ r, ef, rdp, acc, ag }) => {
    console.log(r);
    const nomeFile = `R${r.id} - ${r.cognome} ${r.nome}.pdf`;
    return (
        <Document title={nomeFile} author="Virgilio Viaggia">
            <Page size="A4" orientation='portrait' style={{ padding: 20 }}>
                <View>
                    <Text>Il ritardo agli appuntamenti comporterà la partenza del pullman e la perdita dei diritti dell’utente sul viaggio.</Text>
                    <Text>{r.id} - {r.cognome} {r.nome}</Text>
                </View>
            </Page>
        </Document>
    )
}