// in src/App.js
import * as React from "react";
import { createElement } from 'react';
import { Admin, Resource, Link, defaultTheme, Login, CustomRoutes, Menu, MenuItemLink, useNotify, Layout, useResourceDefinitions, usePermissions, Authenticated, useGetList, AppBar, UserMenu, Logout, useUserMenu } from 'react-admin';
import { Grid, Card, CardActionArea, CardMedia, CardContent, CardHeader, CircularProgress, Button, Dialog, DialogContent, useMediaQuery, MenuItem, ListItemIcon, ListItemText, Typography, Box, Link as LinkMui } from '@mui/material';
import { dataProvider, i18nProvider, titoloPagina, logger, apiFullUrl, myTheme, admB64, Footer } from "./components/generale.js";
import authProvider from './authProvider';
import { Route, Link as LinkRRD } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import { encode as base64_encode } from 'base-64';
import { clarity } from 'clarity-js';
import * as Sentry from "@sentry/react";

// Viaggi
import { ViaggiList } from './components/Viaggi/ViaggiLista';
import { ViaggiArchivioList } from './components/Viaggi/ViaggiListaArchivio';
import { ViaggiListUtente } from './components/Viaggi/ViaggiListaUtente';
import { ViaggioDetails } from './components/Viaggi/ViaggioDettaglio';
// Richieste
import { RichiestaNuova } from './components/Richieste/RichiestaNuovo';
import { RichiestaList } from './components/Richieste/RichiesteLista';
import { RichiestaListUtente } from './components/Richieste/RichiesteListaUtente.js';
import { RichiestaModifica } from './components/Richieste/RichiesteModifica';
import { RichiestaNuovaAttesa } from './components/Richieste/RichiestaNuovoAttesa.js';
import { RichiestaArchivioList } from './components/Richieste/RichiestaArchivioLista';
import { RichiestaArchivioListUtente } from './components/Richieste/RichiestaArchivioListaUtente.js';
// import Camere from "./components/Richieste/GestionePartecipanti.js";
import { EditPartecipante, ListaPartecipanti } from "./components/Richieste/GestionePartecipanti.js";
// Utenti
import { UtentiList } from './components/Utenti/UtentiLista';
import { UtenteNuovo } from './components/Utenti/UtenteNuovo';
import { UtenteModifica } from './components/Utenti/UtenteModifica';
// Faq
import { FaqListAdmin, FaqListUser } from './components/Faq/FaqLista.js';
import { FaqNuovo } from './components/Faq/FaqNuovo.js';
import { FaqModifica } from './components/Faq/FaqModifica.js';
// Accompagnatori
import { AccompagnatoriList } from './components/Accompagnatori/AccompagnatoriLista.js';
import { AccompagnatoreNuovo } from './components/Accompagnatori/AccompagnatoreNuovo.js';
import { AccompagnatoreModifica } from './components/Accompagnatori/AccompagnatoreModifica.js';
// Messaggi da leggere
import { DaLeggereList } from './components/Messaggi/DaLeggereLista.js';
// Alert
import { Alert } from './components/Alert';
// Privacy
import { Privacy } from './components/Privacy';
// Reset Password
import ResetPassword from './components/ResetPassword';
// Login
import MyLoginPage from './Login';
// Registrazione
import RegisterPage from './Registrazione';
// Icone
import { MarkEmailUnread as DaLeggere, NotificationImportant, People, HelpOutline, Luggage, Assignment, PermIdentity, AdminPanelSettings, RequestQuote, Paid, CheckCircleOutline, PauseCircleOutline, Mail, Support, Inventory } from '@mui/icons-material';
import zIndex from "@mui/material/styles/zIndex.js";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION,
  // debug: true,
  initialScope: {
    user: { id: localStorage.getItem('auth_uid') },
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/prenota\.virgilioviaggia\.it/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Dashboard = () => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const [syncViaggiInCorso, setSyncViaggiInCorso] = React.useState(false);
  const [dialogLogSync, setDialogLogSync] = React.useState(false);
  const [logSyncData, setLogSyncData] = React.useState(false);
  // const versionForCache = moment().format('YYMMDD');
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const userID = localStorage.getItem('auth_uid');

  React.useEffect(() => {
    document.title = titoloPagina;
  }, []);

  function syncViaggi() {
    setSyncViaggiInCorso(true);
    if (!syncViaggiInCorso) {
      // console.log('syncViaggi sta per iniziare');
      // setTimeout(() => {
      // console.log('syncViaggi iniziato!');
      axios('https://www.virgilioviaggia.it/custom_script/custom-functions.php')
        .then(function (response) {
          // console.log(response);
          setSyncViaggiInCorso(false);
          if (response.status === 200) {
            setLogSyncData(response)
            logger(15, 'ok');
            notify('Sincronizzazione conclusa correttamente', { type: 'success' });
          } else {
            logger(15, 'ko');
            notify('E\' stato riscontrato un problema nella sincronizzazione', { type: 'warning' });
          }
        })
        .catch(function (error) {
          setSyncViaggiInCorso(false);
          logger(15, 'ko - ' + error);
          notify(`E' stato riscontrato un problema nella sincronizzazione - Errore: ${error}`, { type: 'warning' });
        });
      // }, 2000);
    } else {
      notify('L\'operazione è già in corso, attendere la conclusione', { type: 'info' });
    }
  }

  return (
    permissions === base64_encode(admB64) ?
      <>
        <Grid container spacing={2} sx={{ mt: "10px", mb: "30px" }} justifyContent='start' gridAutoFlow='column'>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/richiesta">
              <Card sx={{ maxWidth: "unset" }} elevation={isSmall ? 5 : 1}>
                <CardActionArea>
                  <Grid container display='flex' flexDirection='row' alignItems='center'>
                    <Grid item xs={12} lg={6}>
                      <CardMedia
                        component="img"
                        height="auto"
                        src={require('./includes/img/gestione-richieste.jpg')}
                        sx={{ display: (isSmall ? 'none' : 'block') }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardHeader title="Gestione richieste" sx={{ pb: "0" }} variant="dashboardHeader" />
                      <CardContent>Visualizza tutte le richieste</CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/richiesta_messaggio_da_leggere">
              <Card sx={{ maxWidth: "unset" }} elevation={isSmall ? 5 : 1}>
                <CardActionArea>
                  <Grid container display='flex' flexDirection='row' alignItems='center'>
                    <Grid item xs={12} lg={6}>
                      <CardMedia
                        component="img"
                        height="auto"
                        src={require('./includes/img/notifiche-messaggi.jpg')}
                        sx={{ display: (isSmall ? 'none' : 'block') }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardHeader title="Notifiche messaggi" sx={{ pb: "0" }} variant="dashboardHeader" />
                      <CardContent>Leggi i nuovi messaggi ricevuti</CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link onClick={(e) => { e.preventDefault(); syncViaggi() }}>
              <Card sx={{ maxWidth: "unset" }} elevation={isSmall ? 5 : 1}>
                <CardActionArea sx={{ position: 'relative' }}>
                  <Grid container display='flex' flexDirection='row' alignItems='center'>
                    <Grid item xs={12} lg={6}>
                      <CardMedia
                        component="img"
                        height="auto"
                        src={require('./includes/img/sync.jpg')}
                        sx={{ display: (isSmall ? 'none' : 'block') }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardHeader title="Sync" sx={{ pb: "0" }} variant="dashboardHeader" />
                      <CardContent>Sincronizza viaggi ed agenzie</CardContent>
                      {(syncViaggiInCorso ? <CircularProgress sx={{ position: 'absolute', right: 10, bottom: 10 }} /> : null)}
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Link>
            <Grid>
              {(logSyncData ? <Button my={2} variant="outlined" onClick={() => setDialogLogSync(true)} fullWidth size="small" alignItems="center">Log Sincronizzazione</Button> : null)}
            </Grid>
            <Dialog
              selectedValue={logSyncData.data}
              open={dialogLogSync}
              onClose={() => setDialogLogSync(false)}
            >
              <DialogContent>
                <div dangerouslySetInnerHTML={{ __html: logSyncData.data }} style={{ whiteSpace: "pre-wrap" }}></div>
                {/* {logSyncData.data} */}
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/viaggio">
              <Card sx={{ maxWidth: "unset" }} elevation={isSmall ? 5 : 1}>
                <CardActionArea>
                  <Grid container display='flex' flexDirection='row' alignItems='center'>
                    <Grid item xs={12} lg={6}>
                      <CardMedia
                        component="img"
                        height="auto"
                        src={require('./includes/img/gestione-viaggi.jpg')}
                        sx={{ display: (isSmall ? 'none' : 'block') }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardHeader title="Gestione viaggi" sx={{ pb: "0" }} variant="dashboardHeader" />
                      <CardContent>Visualizza tutti i viaggi attivi</CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/viaggio/archivio">
              <Card sx={{ maxWidth: "unset" }} elevation={isSmall ? 5 : 1}>
                <CardActionArea>
                  <Grid container display='flex' flexDirection='row' alignItems='center'>
                    <Grid item xs={12} lg={6}>
                      <CardMedia
                        component="img"
                        height="auto"
                        src={require('./includes/img/viaggi-archiviati.jpg')}
                        sx={{ display: (isSmall ? 'none' : 'block') }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardHeader title="Viaggi archiviati" sx={{ pb: "0" }} variant="dashboardHeader" />
                      <CardContent>Visualizza le richieste per i viaggi archiviati</CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/richiesta/archivio">
              <Card sx={{ maxWidth: "unset" }} elevation={isSmall ? 5 : 1}>
                <CardActionArea>
                  <Grid container display='flex' flexDirection='row' alignItems='center'>
                    <Grid item xs={12} lg={6}>
                      <CardMedia
                        component="img"
                        height="auto"
                        src={require('./includes/img/richieste-archiviate.jpg')}
                        sx={{ display: (isSmall ? 'none' : 'block') }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardHeader title="Richieste archiviate" sx={{ pb: "0" }} variant="dashboardHeader" />
                      <CardContent>Visualizza tutte le richieste dei viaggi passati</CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={2} my={4}>
          <Grid item xs>
            <Typography component='p' variant="p" color={myTheme.palette.secondary.main} sx={{ textAlign: 'center', fontWeight:'bold' }}>Versione software: {process.env.REACT_APP_VERSION}</Typography>
          </Grid>
        </Grid>
      </>
      :
      <>
        <Grid container spacing={2} sx={{ my: "10px" }} justifyContent='center' gridAutoFlow='column'>
          <Grid item>
            <CardMedia
              component="img"
              image={require(`./includes/img/logo.png`)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: "10px" }} justifyContent='center' gridAutoFlow='column'>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={2.5} xxl={2.3} mb={{ xs: 2, md: 0 }}>
            <Link to="/viaggio">
              <Card sx={{ maxWidth: "unset" }} elevation={3}>
                <CardActionArea sx={{ textAlign: 'center', py: { xs: 2, md: 3, lg: 5 }, display: 'flex', flexDirection:'column' }} variant="dashboardCardUtente">
                  <CardHeader title="Scegli il tuo prossimo viaggio" sx={{ textAlign: 'center', mt: 0, pt: 0, mb: { xs: 2, md: 3, lg: 5 } }} component="h6" variant="dashboardHeader" />
                  <CardMedia
                    component="img"
                    // style={{ width: '100%', aspectRatio: `${isSmall ? '5/2' : '10/2'}` }}
                    image={require(`./includes/img/prenota-user.jpg`)}
                  />
                  <CardContent sx={{ textAlign: 'center', mt: {xs: 2, md: 3 } }}>
                    <Typography component='p' variant='p' mb={2}>Scopri tutti i nostri viaggi e scegli quello che fa al caso tuo.</Typography>
                    <Button variant="contained" color="primary">Inizia ora</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={2.5} xxl={2.3} mb={{ xs: 2, md: 0 }}>
            <Link to="/richiesta">
              <Card sx={{ maxWidth: "unset" }} elevation={3}>
                <CardActionArea sx={{ textAlign: 'center', py: { xs: 2, md: 3, lg: 5 }, display: 'flex', flexDirection:'column' }} variant="dashboardCardUtente">
                  <CardHeader title="Le tue richieste" sx={{ textAlign: 'center', mt: 0, pt: 0, mb: { xs: 2, md: 3, lg: 5 } }} component="h6" variant="dashboardHeader" />
                  <CardMedia
                    component="img"
                    image={require(`./includes/img/richieste-user.jpg`)}
                  />
                  <CardContent sx={{ textAlign: 'center', mt: {xs: 2, md: 3 } }}>
                  <Typography component='p' variant='p' mb={2}>Consulta le tue richieste e visualizzane lo stato e tutti i dettagli.</Typography>
                    <Button variant="contained" color="primary">Consulta ora</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={2.5} xxl={2.3} mb={{ xs: 2, md: 0 }}>
            <Link to="/faq">
              <Card sx={{ maxWidth: "unset" }} elevation={3}>
                <CardActionArea sx={{ textAlign: 'center', py: { xs: 2, md: 3, lg: 5 }, display: 'flex', flexDirection:'column' }} variant="dashboardCardUtente">
                  <CardHeader title="Dubbi o perplessità?" sx={{ textAlign: 'center', mt: 0, pt: 0, mb: { xs: 2, md: 3, lg: 5 } }} component="h6" variant="dashboardHeader" />
                  <CardMedia
                    component="img"
                    image={require(`./includes/img/faq-user.jpg`)}
                  />
                  <CardContent sx={{ textAlign: 'center', mt: {xs: 2, md: 3 } }}>
                    <Typography component='p' variant='p' mb={2}>Hai dubbi su come utilizzare la nostra piattaforma? Consulta la sezione delle domande frequenti</Typography>
                    <Button variant="contained" color="primary">Leggi ora</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={2.5} xxl={2.3} mb={{ xs: 2, md: 0 }}>
            <Link to={`/utente/${userID}`}>
              <Card sx={{ maxWidth: "unset" }} elevation={3}>
                <CardActionArea sx={{ textAlign: 'center', py: { xs: 2, md: 3, lg: 5 }, display: 'flex', flexDirection:'column' }} variant="dashboardCardUtente">
                  <CardHeader title="Profilo personale" sx={{ textAlign: 'center', mt: 0, pt: 0, mb: { xs: 2, md: 3, lg: 5 } }} component="h6" variant="dashboardHeader" />
                  <CardMedia
                    component="img"
                    image={require(`./includes/img/profilo-user.jpg`)}
                  />
                  <CardContent sx={{ textAlign: 'center', mt: {xs: 2, md: 3 } }}>
                    <Typography component='p' variant='p' mb={2}>Consulta le tue informazioni personali e tienile sempre aggiornate.</Typography>
                    <Button variant="contained" color="primary">Vai al profilo</Button>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </>
  )
};

export const MyMenu = (props) => (
  <Menu {...props}>
  </Menu>
);

const IconaMessaggiDaLeggere = () => {
  return (
    <DaLeggere color="error" />
  )
}

const UserMenuItem = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const userID = localStorage.getItem('auth_uid');
  return (
    <>
      <MenuItem
        onClick={onClose}
        ref={ref}
        component={LinkRRD}
        // It's important to pass the props to allow Material UI to manage the keyboard navigation
        {...props}
        to={`/utente/${userID}`}
      >
        <ListItemIcon>
          <PermIdentity />
        </ListItemIcon>
        <ListItemText>Profilo personale</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={onClose}
        ref={ref}
        component={LinkRRD}
        // It's important to pass the props to allow Material UI to manage the keyboard navigation
        {...props}
        to={`/centro-privacy/`}
      >
        <ListItemIcon>
          <AdminPanelSettings />
        </ListItemIcon>
        <ListItemText>Centro Privacy</ListItemText>
      </MenuItem>
    </>
  );
});

const App = () => {
  const [iconaDaLeggere, setIconaDaLeggere] = React.useState(false);

  if (process.env.NODE_ENV === 'production') {
    // https://github.com/microsoft/clarity/issues/165
    clarity.start({
      projectId: "l299nyh7i3",
      upload: 'https://m.clarity.ms/collect',
      track: false,
      content: false,
    });
  } else {
    clarity.start({
      projectId: "l299nyh7i3",
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
    });
  }

  const CustomMenu = () => {
    const userID = localStorage.getItem('auth_uid');
    const resources = useResourceDefinitions();
    const { permissions } = usePermissions();
    // if ((resources).length <= 0) return null;

    return (
      <Menu>
        <Menu.DashboardItem primaryText='Home' />
        {/* {permissions !== base64_encode(admB64)
          ?
          <div>
            <Menu.Item key="utente" to={`/utente/${userID}`} primaryText="Profilo personale" leftIcon={<PermIdentity />} />
          </div>
          : null
        } */}

        {Object.keys(resources).map(name => (
          (resources[name].icon !== undefined ?
            <Menu.Item
              key={name}
              to={`/${name}`}
              primaryText={resources[name].options.label || name}
              leftIcon={createElement(resources[name].icon)}
            />
            : null
          )
        ))}

        {permissions === base64_encode(admB64)
          ?
          <div>
            <Menu.Item key="alert" to={`/alert`} primaryText="Alert" leftIcon={<NotificationImportant />} />
          </div>
          : null
        }
      </Menu>
    );
  }

  const CustomLayout = (props) => {
    return (
      <>
        <Layout {...props} menu={CustomMenu} appBar={() => <AppBar userMenu={<UserMenu><UserMenuItem /><Logout /></UserMenu>} />} />
        <Footer />
      </>
    );
  }

  const leggiMessaggiDaLeggere = async function () {
    try {
      axios(`${apiFullUrl}/records/richiesta_messaggio_da_leggere`, {
        headers: {
          'X-API-Key': localStorage.getItem('auth_api_key')
        }
      })
        .then(function (response) {
          // console.log(response);
          if ((response.data.records).length > 0)
            setIconaDaLeggere(true)
        })
    } catch (error) {
      console.error('Si è verificato un errore durante la richiesta:', error);
    }
  };

  React.useEffect(() => {
    document.title = titoloPagina;
    // console.log(localStorage.getItem('permissions') === base64_encode(admB64));
    if (localStorage.getItem('auth_api_key') && localStorage.getItem('permissions') === base64_encode(admB64)) {
      leggiMessaggiDaLeggere();
      const intervalId = setInterval(leggiMessaggiDaLeggere, 60000);
    }
  });

  return (
    <Admin dataProvider={dataProvider} dashboard={Dashboard} authProvider={authProvider} loginPage={MyLoginPage} i18nProvider={i18nProvider} layout={CustomLayout} theme={myTheme}>
      {/* <Admin dataProvider={dataProvider} dashboard={Dashboard} authProvider={authProvider} theme={myTheme} loginPage={LoginPage} requireAuth> */}
      <CustomRoutes noLayout>
        <Route path="/registrazione" element={<RegisterPage />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/reset-password" element={<ResetPassword />} />
      </CustomRoutes>
      {permissions => (
        <>
          <Resource name="viaggio" options={{ label: 'Viaggi' }} icon={Luggage} list={(permissions === base64_encode(admB64) ? ViaggiList : ViaggiListUtente)} show={ViaggioDetails}>
            {permissions === base64_encode(admB64) ?
              <Route path="archivio" element={<ViaggiArchivioList />} />
              : null}
          </Resource>
          <Resource name="richiesta_dettagli" options={{ label: 'Partecipanti' }} edit={EditPartecipante} />
          <Resource
            name="richiesta"
            options={{ label: 'Richieste' }}
            icon={Assignment}
            list={(permissions === base64_encode(admB64) ? RichiestaList : RichiestaListUtente)}
            create={RichiestaNuova}
            edit={RichiestaModifica}
          // edit={(permissions === base64_encode(admB64) ? RichiestaModifica : null)}
          // show={(permissions === '2' ? RichiestaDettaglio : null)}
          >
            <Route path=":idRichiesta/partecipanti" element={<ListaPartecipanti />} />
            <Route path=":idRichiesta/partecipante/:idPartecipante" element={<EditPartecipante />} />
            <Route path="create/:idViaggio" element={<RichiestaNuova />} />
            {/* <Route path="create/:idViaggio" element={ permissions ? <RichiestaNuova /> : <RichiestaNuovaAttesa /> } /> */}
            <Route path="viaggio/:idViaggio" element={<RichiestaList />} />
            <Route path="archivio" element={(permissions === base64_encode(admB64) ? <RichiestaArchivioList /> : <RichiestaArchivioListUtente />)} />
          </Resource>
          <Resource name="faq" options={{ label: 'FAQ' }} icon={HelpOutline} list={permissions === base64_encode(admB64) ? FaqListAdmin : FaqListUser} create={permissions === base64_encode(admB64) ? FaqNuovo : null} edit={permissions === base64_encode(admB64) ? FaqModifica : null} />
          {permissions === base64_encode(admB64) ?
            <>
              <Resource name="utente" options={{ label: 'Utenti' }} icon={People} list={UtentiList} create={UtenteNuovo} edit={UtenteModifica} />
              <Resource name="richiesta_messaggio_da_leggere" options={{ label: 'Notifiche' }} icon={(iconaDaLeggere > 0 ? IconaMessaggiDaLeggere : Mail)} list={DaLeggereList} />
              <CustomRoutes>
                <Route path="/alert" element={<Alert />} />
              </CustomRoutes>
              <Resource name="accompagnatore" options={{ label: 'Accompagnatori' }} icon={Support} list={AccompagnatoriList} create={AccompagnatoreNuovo} edit={AccompagnatoreModifica} />
            </>
            :
            <>
              <Resource
                name="utente"
                edit={UtenteModifica}
              />
              <CustomRoutes>
                <Route path="/centro-privacy" element={<Privacy />} />
              </CustomRoutes>
            </>
          }
        </>
      )}
    </Admin >
  )
};

export default App;
