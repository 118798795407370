import * as React from "react";
import { List, ListBase, Datagrid, TextField, ReferenceField, ShowBase, FunctionField, usePermissions, useShowController, useListContext, TextInput, ReferenceInput, AutocompleteInput, SimpleList, SelectInput } from 'react-admin';
import { Box, Grid, Paper, Card, CardContent, Typography, Divider, useMediaQuery } from '@mui/material';
import { Phone, LocationOn } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { publicUrl, admB64 } from '../generale';
import moment from 'moment';
import { encode as base64_encode } from 'base-64';

export const ViaggioDetails = () => {
    const { record } = useShowController();
    const { permissions } = usePermissions();
    const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: localStorage.getItem('auth_uid') } : false);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const filtersAdmin = [
        <TextInput source="titolo_cs" label="Cerca per titolo" alwaysOn />,
        <ReferenceInput label="Stato" source="id_stato_viaggio" reference="stato_viaggio" alwaysOn>
            <SelectInput label="Stato" optionText="nome" optionValue="id" />
        </ReferenceInput>
    ];
    if (!record) return null;

    return (
        <Card>
            <CardContent>
                <ShowBase>
                    <Box sx={{ my: 0, pb: 2, pt: 0 }}>
                        {/* <Typography variant="h4" sx={{ my: 2 }}>Il tuo profilo</Typography> */}
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={3} xl={2}>
                                <Paper elevation={3}>
                                    <Box
                                        component="img"
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                                        src={`${publicUrl}/${record.img_intro}`}
                                    />
                                </Paper>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{record.titolo}</Typography>
                                    <Typography>Data: {(record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ``) + `${moment(record.data_inizio).format("DD/MM/YYYY")}` : ``) +
                                        (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YYYY")}` : ``)}</Typography>
                                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false}>
                                        <Typography>Stato: <TextField source="nome" /></Typography>
                                    </ReferenceField>
                                    <Typography>Partecipanti: XXX</Typography>
                                    <Typography sx={{mt:3}}>Programma:<br />{record.descr_breve}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9} xl={10}>
                            </Grid>
                        </Grid>
                    </Box>
                </ShowBase>
            </CardContent>
        </Card>
    )
};