import * as React from "react";
import { useNotify, useLogout } from 'react-admin';
import { Paper, Box, Grid, Tooltip, Typography, Button } from '@mui/material';
import { Shield, CloudDownload, DeleteOutline } from '@mui/icons-material';
import { titoloPagina, HeaderSezione, apiFullUrl } from '../generale';
import axios from "axios";
import { LoadingComponent } from "../LoadingComponent";
import moment from 'moment';

const PrivacyPortabilita = () => {
    const notify = useNotify();
    const [loading, setLoading] = React.useState(false)

    function download() {
        setLoading(true)
        axios.get(apiFullUrl + '/data-export/', {
            headers: { 'X-API-Key': localStorage.getItem('auth_api_key'), 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                if (response.status === 200) {
                    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                        JSON.stringify(response.data.records)
                      )}`;
                      const link = document.createElement("a");
                      link.href = jsonString;
                      link.download = response.data.records.utente[0]['E-Mail'] + "-" + moment().format('YY_MM_DD') + "-data.json";
                      link.click();
                } else {
                    notify(`Impossibile scaricare i dati richiesti. Se il problema persiste contatta la nostra assistenza`, { type: 'error' });
                }
                setLoading(false)
            })
            .catch(function (error) {
                notify(`Impossibile scaricare i dati richiesti. Se il problema persiste contatta la nostra assistenza`, { type: 'error' });
                setLoading(false)
            });
    }

    return (
        !loading ?
        <Button size="large" startIcon={<CloudDownload sx={{ mr: 1 }} />} onClick={download}>Scarica dati</Button>
        :
        <LoadingComponent testo='Stiamo elaborando la tua richiesta' />
    )
};

const PrivacyEliminazione = () => {
    const notify = useNotify();
    const logout = useLogout();
    const [loading, setLoading] = React.useState(false);

    function elimina() {
        setLoading(true)
        axios.delete(apiFullUrl + '/data-remove/', {
            headers: { 'X-API-Key': localStorage.getItem('auth_api_key'), 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                // console.log(response);
                if (response.status === 200 && response.data.records.utente === 'ok') {
                    notify(`Abbiamo provveduto a cancellare tutti i tuoi dati dal nostro sistema.`, { type: 'success' });
                    logout();
                } else {
                    notify(`Si è verificato un problema con la cancellazione dei dati. Contatta la nostra assistenza per verificare la corretta eliminazione.`, { type: 'error' });
                }
                setLoading(false)
            })
            .catch(function (error) {
                notify(`Impossibile procedere con la cancellazione dei dati. Se il problema persiste contatta la nostra assistenza.`, { type: 'error' });
                setLoading(false)
            });
    }

    return (
        !loading ?
        <Button size="large" startIcon={<DeleteOutline sx={{ mr: 1 }} />} onClick={elimina}>Elimina account</Button>
        :
        <LoadingComponent testo='Stiamo elaborando la tua richiesta' />
    )
};

export const Privacy = () => {
    React.useEffect(() => {
        document.title = titoloPagina;
    });

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} lg={6}>
                <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box>
                        <HeaderSezione icona={Shield} titolo="Richiedi i tuoi dati" dettaglio="Puoi richiedere i tuoi dati personali inseriti nel tuo account e nelle richieste che hai inviato" />
                    </Box>
                    <PrivacyPortabilita />
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box>
                        <HeaderSezione icona={Shield} titolo="Elimina account" dettaglio="Puoi cancellare il tuo account e tutti i dati che hai inserito. In questo modo non avrai più accesso alla nostra piattaforma" />
                    </Box>
                    <PrivacyEliminazione />
                </Paper>
            </Grid>
        </Grid>
    )
}