import { useEffect, useState } from "react";
import { RecordContextProvider, TextField, Button, ReferenceField, WithRecord, useRecordContext, useGetOne, useStore, usePermissions, useNotify } from 'react-admin';
import { Box, Grid, Link, Card, CardContent, Typography, Modal, Button as ButtonMui, Divider, useMediaQuery, Alert, IconButton, AlertTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, CardMedia } from '@mui/material';
import { Info, TravelExplore, Description, ThumbUp, ThumbDown, Close, Share } from '@mui/icons-material';
import { LoadingComponent } from '../LoadingComponent';
import { IconaCategoriaViaggio } from '../IconaCategoriaViaggio';
import { publicUrl, localeITmoment, valutaFormat, CalcoloPartecipanti, HeaderSezione, admB64, apiGuestFullUrl, myTheme, apiBaseUrl, apiFullUrl, copyToClipboard } from '../generale';
import { ListaPDF } from './ViaggiListaPdf';
import moment from 'moment';
import parse from 'html-react-parser';
import { encode as base64_encode } from 'base-64';
import axios from 'axios';
import "./GoogleFonts.css";

// const RiepilogoPartecipantiV1 = ({ daValidare, validi, confermati, incasso, idViaggio }) => {
//     return (
//         <Alert severity="info" icon={false} sx={{ mt: 2 }}>
//             <AlertTitle>Partecipanti</AlertTitle>
//             <Typography variant="p" component="p">Da validare: <b>{daValidare}</b></Typography>
//             <Typography variant="p" component="p">Validati: <b>{validi}</b></Typography>
//             <Typography variant="p" component="p">Confermati: <b>{confermati}</b></Typography>
//             {incasso ?
//             <Typography variant="p">Incasso attuale: <b>{valutaFormat.format(incasso)}</b></Typography>
//             : null }
//             <Link href={`/#/richiesta/viaggio/${idViaggio}`}><Button sx={{ mt:2 }}>Richieste del viaggio</Button></Link>
//         </Alert>
//     )
// }

export const RiepilogoPartecipanti = ({ daValidare, validi, confermati, contDaValidare, contValidi, contConfermati, incasso, idViaggio, variante = null }) => {

    return (
        <Box mt={!variante ? 3 : null}>
            <TableContainer component={Paper} sx={{ backgroundColor:'white' }} display='block' width='100%'>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow sx={{ '& th': { backgroundColor: myTheme.palette.table.evidenza, color: myTheme.palette.secondary.main, fontWeight: 'bold' } }}>
                            <TableCell>Stato</TableCell>
                            <TableCell align="center">Pax</TableCell>
                            {variante ? <TableCell align="right">Prezzo</TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key='daValidare' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Da validare</TableCell>
                            <TableCell component="td" scope="row" align="center"><Typography variant="p" component="p">{daValidare}</Typography></TableCell>
                            {variante ? 
                            <TableCell component="td" scope="row" align="right">
                                <Typography variant="p">{valutaFormat.format(contDaValidare)}</Typography>
                            </TableCell>
                            :null}
                        </TableRow>
                        <TableRow key='validi' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Validati</TableCell>
                            <TableCell component="td" scope="row" align="center"><Typography variant="p" component="p">{validi}</Typography></TableCell>
                            {variante ?
                            <TableCell component="td" scope="row" align="right">
                                <Typography variant="p">{valutaFormat.format(contValidi)}</Typography>
                            </TableCell>
                            : null}
                        </TableRow>
                        <TableRow key='confermati' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Confermati</TableCell>
                            <TableCell component="td" scope="row" align="center"><Typography variant="p" component="p">{confermati}</Typography></TableCell>
                            {variante ?
                            <TableCell component="td" scope="row" align="right">
                                <Typography variant="p">{valutaFormat.format(contConfermati)}</Typography>
                            </TableCell>
                            : null}
                        </TableRow>
                        <TableRow key='totale' sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td': { color: myTheme.palette.table.evidenza, backgroundColor: myTheme.palette.secondary.main, fontWeight: 'bold'} }} >
                            <TableCell component="td" scope="row">Totale</TableCell>
                            <TableCell component="td" scope="row" align="center">{(daValidare + validi + confermati)}</TableCell>
                            {variante ?
                            <TableCell component="td" scope="row" align="right">{valutaFormat.format(contDaValidare + contValidi + contConfermati)}</TableCell>
                            : null}
                        </TableRow>
                        {variante ?
                        <TableRow key='incassati' sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { backgroundColor: myTheme.palette.table.evidenza, color: myTheme.palette.secondary.main, fontWeight: 'bold' } }} >
                            <TableCell component="td" scope="row" colSpan={2}>Incassi attuali</TableCell>
                            <TableCell component="td" scope="row" align="right">
                                {incasso ?
                                <Typography variant="p">{valutaFormat.format(incasso)}</Typography>
                                : null }
                            </TableCell>
                        </TableRow>
                        : null}
                    </TableBody>
                </Table>
            </TableContainer>
            {!variante ?
            <Link href={`/#/richiesta/viaggio/${idViaggio}`}><Button sx={{ mt:2 }}>Richieste del viaggio</Button></Link>
            :null}
        </Box>
    )
}

export const RiepilogoFermateRichieste = ({idViaggio}) => {
    const { data: fermate, isLoading, error } = useGetOne('richiesta_dettagli_fermate', { id: idViaggio });
    const label = 'Fermate confermate:';
    if (isLoading) return <Box display='flex' flexDirection='row'><Typography variant="p">{label}</Typography> <Typography variant="p" marginLeft={1} color={myTheme.palette.primary.main} sx={{ fontWeight: 'bold'}}>In caricamento</Typography></Box>
    if (error) return <Box display='flex' flexDirection='row'><Typography variant="p">{label}</Typography> <Typography variant="p" marginLeft={1} color={myTheme.palette.error[800]} sx={{ fontWeight: 'bold'}}>Errore</Typography></Box>
    return (
        <Typography variant="p">{label} <b>{fermate.fermate ?? 'N.D.'}</b></Typography>
    )
}

export const ViaggioPreview = ({ id, variante, salva_dettagli = null }) => {
    const notify = useNotify();
    const viaggio = useRecordContext();
    const { isLoading, permissions } = usePermissions();
    const [statoModal, setStatoModal] = useState(false);
    const [datiViaggio, setDatiViaggio] = useState(null);
    // const [n_partecipanti, setN_partecipanti] = useState(null);
    let n_partecipanti = permissions && permissions === base64_encode(admB64) ? CalcoloPartecipanti((id || viaggio.id_viaggio)) : null;
    // if (viaggio)
    //     console.log('id_viaggio:' + viaggio.id_viaggio);
    // if (id)
    //     console.log('id:' + id);

    useEffect(() => {
        if (!datiViaggio) {
            axios.get(apiGuestFullUrl + '/records/viaggio/' + (id || viaggio.id_viaggio) + '?join=stato_viaggio&join=agenzia')
            .then(response => {
                // console.log(response);
                setDatiViaggio(response.data)
            });
        }
    },[])

    useEffect(() => {
        if (datiViaggio && salva_dettagli)
            salva_dettagli(datiViaggio);
    }, [datiViaggio])

    useEffect(() => {
        // if (permissions)
        // setN_partecipanti(CalcoloPartecipanti((id || viaggio.id_viaggio)));
    }, [permissions])

    moment.updateLocale('it', localeITmoment);

    // const { data: record, isLoading, error } = useGetOne('viaggio', { id: (id || viaggio.id_viaggio) });
    
    // if (isLoading) return <LoadingComponent />;
    // if (error) return <p>Errore di caricamento</p>;
    // if (!record) return null;

    if (!datiViaggio) return <LoadingComponent />;

    return (
        <RecordContextProvider record={datiViaggio}>
            <Card elevation={0} variant="outlined">
                {variante === 'orizzontale' ?
                <CardContent variant="boxViaggioUtenteV2">
                    <Grid container spacing={1.5} display='flex' alignItems='center'>
                        <Grid item xs lg={3} display={{ xs: 'none', lg: 'block' }}>
                            {/* <Box
                                component="img"
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: '100%', aspectRatio: "12/5", mb: 0 }}
                                src={`${publicUrl}/${datiViaggio.img}`}
                            /> */}
                            <CardMedia
                                component="img"
                                style={{ height: '100%', aspectRatio: '991 / 661' }}
                                src={`${publicUrl}/${datiViaggio.img_intro}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={5}>
                            <Box sx={{ textAlign: 'left', px: 1, ml: 0 }}>
                                <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2 }} variant="h4" color='primary'>{datiViaggio.titolo}</Typography>
                                <Typography variant="p">Località: <b>{datiViaggio.descr_breve}</b></Typography>

                                {/* Se il viaggio dura almeno 2 giorni */}
                                {datiViaggio.data_inizio !== null && datiViaggio.data_inizio !== '' && datiViaggio.data_fine !== null && datiViaggio.data_fine !== '' && datiViaggio.data_fine !== '0000-00-00' ?
                                    <Typography variant="p">Data: <b>Dal {(moment(datiViaggio.data_inizio).format("DD MMMM YYYY"))} al {(moment(datiViaggio.data_fine).format("DD MMMM YYYY"))}</b></Typography>
                                    :
                                    null
                                }

                                {/* Se il viaggio dura solo 1 giorno */}
                                {datiViaggio.data_inizio !== null && datiViaggio.data_inizio !== '' && datiViaggio.data_fine !== null && (datiViaggio.data_fine === '' || datiViaggio.data_fine === '0000-00-00') ?
                                    <Typography variant="p">Data: <b>{(moment(datiViaggio.data_inizio).format("dddd DD MMMM YYYY"))}</b></Typography>
                                    :
                                    null
                                }

                                <Typography variant="p">Stato: <b>{datiViaggio.id_stato_viaggio.nome}</b></Typography>
                                <Typography variant="p">N. minimo partecipanti: <b>{datiViaggio.partecipanti_min}</b></Typography>

                                <Typography variant="p" sx={{ mt: 0 }}>
                                    Prezzo a persona:
                                    <b>
                                        <span style={{ marginLeft: '5px' }}>{valutaFormat.format(datiViaggio.prezzo)}</span>
                                        <span style={{ marginLeft: '10px', color: '#999', textDecoration: 'line-through' }}> {(parseInt(datiViaggio.prezzo_prec) !== 0 ? valutaFormat.format(datiViaggio.prezzo_prec) : null)} </span>
                                    </b>
                                </Typography>
                                <Typography variant="p" sx={{ mt: 0 }}>
                                    Acconto:
                                    <b><span style={{ marginLeft: '5px' }}>{parseInt(datiViaggio.acconto) !== 0 ? valutaFormat.format(datiViaggio.acconto) : 'Non richiesto'}</span></b>
                                </Typography>
                                {(parseInt(datiViaggio.suppl_singola) > 0 ?
                                    <Typography variant="p" sx={{ mt: 0 }}>
                                        Suppl. singola:
                                        <b><span style={{ marginLeft: '5px' }}>{valutaFormat.format(datiViaggio.suppl_singola)}</span></b>
                                    </Typography>
                                : null)}
                                {(parseInt(datiViaggio.prezzo_bambini_2) > 0 ?
                                    <Typography variant="p" sx={{ mt: 0 }}>
                                        Prezzo bambini sotto 1 mt:
                                        <b><span style={{ marginLeft: '5px' }}>{valutaFormat.format(datiViaggio.prezzo_bambini_2)}</span></b>
                                    </Typography>
                                : null)}
                                {permissions && permissions === base64_encode(admB64)  ?
                                <RiepilogoFermateRichieste idViaggio={(id || viaggio.id_viaggio)} />
                                : null}
                            </Box>
                            <Box sx={{ textAlign: 'left', px: 1, ml: 0, mt:1 }}>
                                <ButtonMui onClick={() => setStatoModal(!statoModal)} variant="contained" startIcon={<Info />}><Typography variant="p">Maggiori dettagli</Typography></ButtonMui>
                                <ButtonMui onClick={() => copyToClipboard(`${apiBaseUrl}/#/richiesta/create/${id}`).then(res => notify('Il link è stato copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))} variant="outlined" startIcon={<Share />} sx={{ ml: 1 }}><Typography variant="p" ml={1}>Link</Typography></ButtonMui>
                                {Number(n_partecipanti.tot_confermati) > 0 ?
                                <ListaPDF viaggio={datiViaggio} stile='esteso' sx={{ ml: 1 }} color='primary' variant='outlined' />
                                :null}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            {permissions && permissions === base64_encode(admB64) 
                            ?
                            <RiepilogoPartecipanti
                                daValidare={Number(n_partecipanti.n_da_validare)}
                                validi={Number(n_partecipanti.n_validi)}
                                confermati={Number(n_partecipanti.n_confermati)}
                                contDaValidare={Number(n_partecipanti.tot_da_validare)}
                                contValidi={Number(n_partecipanti.tot_validi)}
                                contConfermati={Number(n_partecipanti.tot_confermati)}
                                incasso={n_partecipanti.incasso}
                                idViaggio={datiViaggio.id}
                                variante={variante}
                            />
                            : null}
                        </Grid>
                    </Grid>
                </CardContent>
                :
                <>
                <CardContent sx={{ px: 0, py: 0 }} px>
                    <Box
                        component="img"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: '100%', aspectRatio: "12/5", mb: 0 }}
                        src={`${publicUrl}/${datiViaggio.img}`}
                    />
                </CardContent>
                <CardContent sx={{ pt: 4 }} variant="boxViaggioUtenteV2">
                    <IconaCategoriaViaggio categoria={datiViaggio.tipo} />
                    <Box sx={{ my: 0, pt: 0, mx: 0 }} pb={{ xs: 0, md: 2 }} px={{ xs: 2, md: 0 }}>
                        <Grid container spacing={2} pb={{ xs: 1, md: 2.2 }}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ textAlign: 'left', px: 1, ml: 0 }}>
                                    <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2 }} variant="h4" color='primary'>{datiViaggio.titolo}</Typography>
                                    <Typography variant="p">Località: <b>{datiViaggio.descr_breve}</b></Typography>

                                    {/* Se il viaggio dura almeno 2 giorni */}
                                    {datiViaggio.data_inizio !== null && datiViaggio.data_inizio !== '' && datiViaggio.data_fine !== null && datiViaggio.data_fine !== '' && datiViaggio.data_fine !== '0000-00-00' ?
                                        <Typography variant="p">Data: <b>Dal {(moment(datiViaggio.data_inizio).format("DD MMMM YYYY"))} al {(moment(datiViaggio.data_fine).format("DD MMMM YYYY"))}</b></Typography>
                                        :
                                        null
                                    }

                                    {/* Se il viaggio dura solo 1 giorno */}
                                    {datiViaggio.data_inizio !== null && datiViaggio.data_inizio !== '' && datiViaggio.data_fine !== null && (datiViaggio.data_fine === '' || datiViaggio.data_fine === '0000-00-00') ?
                                        <Typography variant="p">Data: <b>{(moment(datiViaggio.data_inizio).format("dddd DD MMMM YYYY"))}</b></Typography>
                                        :
                                        null
                                    }

                                    <Typography variant="p">Stato: <b>{datiViaggio.id_stato_viaggio.nome}</b></Typography>
                                    {permissions && permissions === base64_encode(admB64)  ?
                                    <Typography variant="p">N. minimo partecipanti: <b>{datiViaggio.partecipanti_min}</b></Typography>
                                    : null}

                                    <Typography variant="p" sx={{ mt: 0 }}>
                                        Prezzo a persona:
                                        <b>
                                            <span style={{ marginLeft: '5px' }}>{valutaFormat.format(datiViaggio.prezzo)}</span>
                                            <span style={{ marginLeft: '10px', color: '#999', textDecoration: 'line-through' }}> {(parseInt(datiViaggio.prezzo_prec) !== 0 ? valutaFormat.format(datiViaggio.prezzo_prec) : null)} </span>
                                        </b>
                                    </Typography>
                                    <Typography variant="p" sx={{ mt: 0 }}>
                                        Acconto:
                                        <b><span style={{ marginLeft: '5px' }}>{parseInt(datiViaggio.acconto) !== 0 ? valutaFormat.format(datiViaggio.acconto) : 'Non richiesto'}</span></b>
                                    </Typography>
                                    {(parseInt(datiViaggio.suppl_singola) > 0 ?
                                        <Typography variant="p" sx={{ mt: 0 }}>
                                            Suppl. singola:
                                            <b><span style={{ marginLeft: '5px' }}>{valutaFormat.format(datiViaggio.suppl_singola)}</span></b>
                                        </Typography>
                                        : null)}
                                    {(parseInt(datiViaggio.prezzo_bambini_2) > 0 ?
                                        <Typography variant="p" sx={{ mt: 0 }}>
                                            Prezzo bambini sotto 1 mt:
                                            <b><span style={{ marginLeft: '5px' }}>{valutaFormat.format(datiViaggio.prezzo_bambini_2)}</span></b>
                                        </Typography>
                                        : null)}

                                    <Box sx={{ mt:1, display:'flex', flexDirection:'row', columnGap:1 }}>
                                        <ButtonMui onClick={() => setStatoModal(!statoModal)} variant="contained" startIcon={<Info />}><Typography variant="p">Maggiori dettagli</Typography></ButtonMui>
                                        {permissions && permissions === base64_encode(admB64) ?
                                        <ButtonMui onClick={() => copyToClipboard(`${apiBaseUrl}/#/richiesta/create/${id}`).then(res => notify('Il link è stato copiato', { type: 'info' })).catch(err => notify(err, { type: 'error' }))} variant="outlined" startIcon={<Share />} sx={{ ml: 1 }}><Typography variant="p">Link</Typography></ButtonMui>
                                        :null}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} pb={{ xs: 1, md: 4 }}>
                            <Grid item xs={12} md={12}>
                                {permissions && permissions === base64_encode(admB64) 
                                // & (datiViaggio.id_stato_viaggio.id === 2 || datiViaggio.id_stato_viaggio.id === 3)
                                ?
                                <RiepilogoPartecipanti
                                    daValidare={Number(n_partecipanti.n_da_validare)}
                                    validi={Number(n_partecipanti.n_validi)}
                                    confermati={Number(n_partecipanti.n_confermati)}
                                    contDaValidare={Number(n_partecipanti.tot_da_validare)}
                                    contValidi={Number(n_partecipanti.tot_validi)}
                                    contConfermati={Number(n_partecipanti.tot_confermati)}
                                    incasso={n_partecipanti.incasso}
                                    idViaggio={datiViaggio.id}
                                    variante={variante ?? null}
                                />
                                : null}
                            </Grid>
                        </Grid>

                        {(datiViaggio.id_agenzia !== 1 ?
                        <Box sx={{ textAlign: 'left', px: 1, ml: 0 }} mt={{ xs: 1, md: 2 }} py={{ xs: 0, md: 1 }}>
                            <Typography variant="p" mt={0} mb={{ xs: 1, md: 3 }}>Organizzato da:</Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={12} lg xl order={{ xs: 2, sm: 1, md:2, lg:2 }}>
                                    <Typography variant="p" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}><TextField source="nome" sx={{ fontSize: 'inherit', fontWeight: 'inherit' }} /></Typography>
                                    <Link href={datiViaggio.id_agenzia.link_condizioni} target='_blank' variant="small" sx={{ lineHeight: "1rem" }}>{datiViaggio.id_agenzia.dettagli}</Link>
                                </Grid>
                                <Grid item xs={6} sm={6} md={12} lg xl order={{ xs: 1, sm: 2, md:1, lg:1 }}>
                                    <Box
                                        component="img"
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: '100%', maxWidth: "150px" }}
                                        src={`${publicUrl}/${datiViaggio.id_agenzia.img}`}
                                    />
                                </Grid>
                            </Grid>

                        </Box>
                        : null)}
                    </Box>
                </CardContent>
                </>
                }
            </Card>

            <Modal open={statoModal} onClose={() => setStatoModal(!statoModal)} keepMounted variant='customModalBox'>
                <Box sx={{ overflowY: "auto !important" }}>
                    <IconButton
                        aria-label="toggle visibility"
                        onClick={() => setStatoModal(!statoModal)}
                        edge="end"
                        size="small"
                        variant="contained"
                        sx={{ position: 'absolute', right: 10, top: 10, outline: "0 !important", borderWidth: 1, borderStyle: 'solid' }}
                    >
                        <Close />
                    </IconButton>
                    <Grid container spacing={2} sx={{ pb: 0, mt: {xs: 2, sm: 0} }}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'left', px: 1, borderLeft: {md: "5px solid #dcdcdc"} }}>
                                <HeaderSezione icona={TravelExplore} titolo="Dettagli del programma" sx={{ mb:0 }} />
                                {/* <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Dettagli del programma</Typography> */}
                                {parse(datiViaggio.programma)}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'left', px: 1, borderLeft: {md: "5px solid #dcdcdc"} }}>
                                <HeaderSezione icona={Description} titolo="Documenti richiesti" sx={{ mb:0 }} />
                                {/* <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Documenti richiesti</Typography> */}
                                {parse(datiViaggio.documenti)}
                                <HeaderSezione icona={ThumbUp} titolo="La quota comprende" sx={{ mb:0, mt: 4 }} />
                                {/* <Typography sx={{ fontWeight: 'bold', mb: 1 }}>La quota comprende</Typography> */}
                                {parse(datiViaggio.quota_incl)}
                                <HeaderSezione icona={ThumbDown} titolo="La quota NON comprende" sx={{ mb:0, mt: 4 }} />
                                {/* <Typography sx={{ fontWeight: 'bold', mb: 1 }}>La quota NON comprende</Typography> */}
                                {parse(datiViaggio.quota_escl)}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </RecordContextProvider>
    )
};