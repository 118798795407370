import { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { myTheme, valutaFormat, dataProvider } from '../generale';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';

export const calcoloPreventivo = function (data, sconto1mt, configSconti = null) {
    // console.log(data);
    var totBambini1 = 0;
    var totBambini3 = 0;
    var totParziale = 0;
    const sconto_bambini_1 = parseFloat(configSconti.find(c => c.nome === "sconto_bambini_1").valore ?? 100);
    const sconto_bambini_3 = parseFloat(configSconti.find(c => c.nome === "sconto_bambini_3").valore ?? 20);

    if (sconto1mt) {
        if (parseInt(data.partecipanti.bambini_2) > 0 && parseInt(data.prezzo_bambini_2 ?? 0) > 0)
            totParziale = ((data.totPartecipanti + (data.partecipanti.bambini_1 ?? 0) - (data.partecipanti.bambini_2 ?? 0)) * data.prezzo) + (data.partecipanti.bambini_2 * data.prezzo_bambini_2);
        else
            totParziale = ((data.totPartecipanti + (data.partecipanti.bambini_1 ?? 0)) * data.prezzo);
    } else {
        totBambini1 = ((data.partecipanti.bambini_1 ?? 0) * (data.prezzo - data.prezzo * sconto_bambini_1 / 100) ?? 0);
        totBambini3 = (data.partecipanti.bambini_3 * (data.prezzo - data.prezzo * sconto_bambini_3 / 100) ?? 0);
        totParziale = data.partecipanti.adulti * data.prezzo + totBambini1 + totBambini3 + data.partecipanti.bambini_4 * data.prezzo + (data.n_singole ? data.suppl_singola * data.n_singole : 0);
    }
    const totAcconto = data.totPartecipanti * data.acconto;
    const totSconto = (data.sconto && data.sconto.perc ? 
        Number((Number(totParziale) * Number(data.sconto.perc) / 100).toFixed(2))
        :
        Number(data.sconto && data.sconto.amm ? Number(data.sconto.amm) : 0).toFixed(2));
    // const totSconto = Number((data.sconto && data.sconto.perc ? (totParziale * data.sconto.perc / 100) : (data.sconto && data.sconto.amm ? data.sconto.amm : 0))).toFixed(2);
    const totFinale = Number((totParziale - totSconto).toFixed(2));
    return { totAcconto, totParziale, totFinale, totSconto, percSconto: (data.sconto.perc ?? null), totBambini1, totBambini3 }
}

const Preventivo = ({ data, totali = null, tipoViaggio }) => {
    const [datiPreventivo, setDatiPreventivo] = useState(null);
    const [attivaSconto1mt, setAttivaSconto1mt] = useState(parseInt(tipoViaggio) === 0 ? true : false);
    const { data: configSconti, isLoading, error } = useGetList('configurazioni',{ 
        pagination: { perPage: 99 },
        sort: { field: 'id', order: 'ASC' },
        filter: { nome_in: ['sconto_bambini_1','sconto_bambini_3'] }
    });

    useEffect(() => {
        if (Object.keys(data).length > 0 && configSconti) {
            const dati = calcoloPreventivo(data, attivaSconto1mt, configSconti);
            setDatiPreventivo(dati);
        }
    }, [data, configSconti, attivaSconto1mt]);

    useEffect(() => {
        if (datiPreventivo && totali) {
            totali(datiPreventivo);
        }
    }, [datiPreventivo]);
    
    if (!data || !datiPreventivo) return null;
    
    // console.log(datiPreventivo);
    
    return (
        <>
        {/* <p>Int totSconto: {parseFloat(datiPreventivo.totSconto) > 0 ? 'sconto applicato' : ''}</p>
        <p>totSconto: {datiPreventivo.totSconto}</p>
        <p>totFinale: {datiPreventivo.totFinale}</p> */}

        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Q.ta</TableCell>
                        <TableCell align="right">Prezzo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.partecipanti.adulti > 0 && !attivaSconto1mt ?
                        <TableRow key='adulti' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Adulti</TableCell>
                            <TableCell align="center">{data.partecipanti.adulti}</TableCell>
                            <TableCell align="right">{valutaFormat.format(data.prezzo * data.partecipanti.adulti)}</TableCell>
                        </TableRow>
                    :null}
                    {data.partecipanti.bambini_1 > 0 && !attivaSconto1mt ?
                        <TableRow key='0_2_anni' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Bimbi 0-2 anni</TableCell>
                            <TableCell align="center">{data.partecipanti.bambini_1}</TableCell>
                            <TableCell align="right"><span className="line_through">{valutaFormat.format(data.prezzo * data.partecipanti.bambini_1)}</span> {valutaFormat.format(datiPreventivo.totBambini1)}</TableCell>
                        </TableRow>
                    :null}
                    {data.partecipanti.bambini_3 > 0 && !attivaSconto1mt ?
                        <TableRow key='3_13_anni' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Bimbi 3-13 anni</TableCell>
                            <TableCell align="center">{data.partecipanti.bambini_3}</TableCell>
                            <TableCell align="right"><span className="line_through">{valutaFormat.format(data.prezzo * data.partecipanti.bambini_3)}</span> {valutaFormat.format(datiPreventivo.totBambini3)}</TableCell>
                        </TableRow>
                    :null}
                    {data.partecipanti.bambini_4 > 0 && !attivaSconto1mt ?
                        <TableRow key='14_17_anni' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Ragazzi 14-17 anni</TableCell>
                            <TableCell align="center">{data.partecipanti.bambini_4}</TableCell>
                            <TableCell align="right">{valutaFormat.format(data.prezzo * data.partecipanti.bambini_4)}</TableCell>
                        </TableRow>
                    :null}
                    {data.n_singole > 0 && data.suppl_singola > 0 ?
                        <TableRow key='suppl_singola' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Suppl. Camera singola</TableCell>
                            <TableCell align="center">{data.n_singole}</TableCell>
                            <TableCell align="right">{valutaFormat.format(data.suppl_singola * data.n_singole)}</TableCell>
                        </TableRow>
                    :null}
                    {attivaSconto1mt ?
                    <>
                        <TableRow key='partecipanti' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Partecipanti</TableCell>
                            <TableCell align="center">{((data.partecipanti.adulti ?? 0) + (data.partecipanti.bambini_1 ?? 0) + (data.partecipanti.bambini_3 ?? 0) + (data.partecipanti.bambini_4 ?? 0) - (parseInt(data.partecipanti.bambini_2) > 0 && parseInt(data.prezzo_bambini_2) > 0 ? data.partecipanti.bambini_2 : 0))}</TableCell>
                            <TableCell align="right">{valutaFormat.format(data.prezzo * (data.totPartecipanti + (data.partecipanti.bambini_1 ?? 0) - (data.partecipanti.bambini_2 ?? 0)))}</TableCell>
                        </TableRow>
                        {/* <TableRow key='partecipanti' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="td" scope="row">Partecipanti</TableCell>
                            <TableCell align="center">{((data.partecipanti.adulti ?? 0) + (data.partecipanti.bambini_1 ?? 0) + (data.partecipanti.bambini_3 ?? 0) + (data.partecipanti.bambini_4 ?? 0) - (parseInt(data.partecipanti.bambini_2) > 0 && parseInt(data.prezzo_bambini_2) > 0 ? data.partecipanti.bambini_2 : 0))}</TableCell>
                            <TableCell align="right">{valutaFormat.format(data.prezzo)}</TableCell>
                        </TableRow> */}
                        {parseInt(data.partecipanti.bambini_2) > 0 && parseInt(data.prezzo_bambini_2) > 0 ?
                            <TableRow key='1_mt' sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell component="td" scope="row">Tariffa "Bambino sotto 1 mt"</TableCell>
                                <TableCell align="center">{data.partecipanti.bambini_2}</TableCell>
                                <TableCell align="right">{valutaFormat.format(data.prezzo_bambini_2 * data.partecipanti.bambini_2)}</TableCell>
                            </TableRow>
                        :null}
                    </>
                    :null}
                    {parseFloat(datiPreventivo.totSconto) > 0 ?
                    <>
                        <TableRow key='totale_parziale' sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td': {backgroundColor:'#f3f3f3'}}} >
                            <TableCell component="td" scope="row" colSpan={2}>Totale parziale</TableCell>
                            <TableCell align="right">{valutaFormat.format(datiPreventivo.totParziale)}</TableCell>
                        </TableRow>
                        <TableRow key='sconto' sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td': {backgroundColor:'#f3f3f3'}}} >
                            <TableCell component="td" scope="row" colSpan={2}>Sconto{(datiPreventivo.percSconto ? ` (${datiPreventivo.percSconto} %)` : null)}</TableCell>
                            <TableCell align="right">- {valutaFormat.format(datiPreventivo.totSconto)}</TableCell>
                        </TableRow>
                    </>
                    :null}
                    <TableRow key='totale' sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td': {backgroundColor: myTheme.palette.table.evidenza, color: myTheme.palette.secondary.main, fontWeight: 'bold' } }} >
                        <TableCell component="td" scope="row">Totale</TableCell>
                        <TableCell component="td" scope="row" align="center">{parseInt(data.partecipanti.adulti ?? 0) + parseInt(data.partecipanti.bambini_1 ?? 0) + parseInt(data.partecipanti.bambini_3 ?? 0) + parseInt(data.partecipanti.bambini_4 ?? 0)}</TableCell>
                        <TableCell align="right">{valutaFormat.format(datiPreventivo.totFinale)}</TableCell>
                    </TableRow>
                    {parseInt(datiPreventivo.totAcconto) > 0 ?
                    <TableRow key='acconto' sx={{ '&:last-child td, &:last-child th': { border: 0 } , '& td': {backgroundColor: myTheme.palette.table.evidenza, color: myTheme.palette.secondary.main, fontWeight: 'bold'} }} >
                        <TableCell component="td" scope="row" colSpan={2}>di cui in acconto</TableCell>
                        <TableCell align="right">{valutaFormat.format(datiPreventivo.totAcconto)}</TableCell>
                    </TableRow>
                    :null}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
};

export default Preventivo;