import React from "react";

function IconaInverno() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g fillRule="nonzero">
        <path d="M575.911 1013.29V66.712c0-19.836-16.077-35.912-35.913-35.912-19.836 0-35.912 16.076-35.912 35.912v946.578c0 19.836 16.076 35.912 35.912 35.912s35.913-16.076 35.913-35.912"></path>
        <path d="M692.449 125.834c-14.015-14.032-36.762-14.032-50.794 0L539.998 227.491 438.342 125.834c-14.033-14.032-36.762-14.032-50.794 0-14.015 14.032-14.015 36.761 0 50.794l125.667 125.65c7.363 7.38 17.134 10.827 26.783 10.464 9.65.363 19.42-3.084 26.783-10.464l125.668-125.65c14.032-14.033 14.032-36.762 0-50.794M387.55 954.166c14.032 14.032 36.761 14.032 50.793 0L540 852.509l101.657 101.657c14.032 14.032 36.761 14.032 50.793 0 14.015-14.032 14.015-36.761 0-50.794l-125.667-125.65c-7.363-7.38-17.134-10.827-26.783-10.464-9.649-.363-19.42 3.084-26.783 10.464L387.55 903.372c-14.033 14.033-14.033 36.762 0 50.794M967.836 745.545L148.069 272.256c-17.168-9.91-39.134-4.037-49.044 13.148-9.926 17.168-4.036 39.135 13.132 49.044l819.767 473.289c17.185 9.927 39.135 4.037 49.061-13.131 9.909-17.185 4.019-39.152-13.149-49.061"></path>
        <path d="M257.546 200.892c-19.16 5.127-30.525 24.825-25.397 43.985l37.212 138.868-138.869 37.212c-19.16 5.128-30.524 24.825-25.396 43.985 5.127 19.161 24.825 30.525 43.985 25.397l171.662-45.995c10.066-2.702 17.931-9.441 22.435-17.982 5.145-8.159 7.051-18.363 4.348-28.428l-45.995-171.646c-5.145-19.16-24.825-30.524-43.985-25.396M822.454 879.108c19.16-5.127 30.525-24.825 25.397-43.985l-37.212-138.868 138.869-37.212c19.16-5.128 30.524-24.825 25.396-43.985-5.127-19.161-24.825-30.525-43.985-25.397l-171.663 45.995c-10.065 2.702-17.93 9.441-22.434 17.982-5.145 8.159-7.051 18.363-4.348 28.428l45.995 171.646c5.145 19.16 24.825 30.524 43.985 25.396M931.926 272.256L112.159 745.545c-17.168 9.909-23.059 31.876-13.132 49.061 9.909 17.168 31.876 23.058 49.044 13.131l819.767-473.289c17.168-9.909 23.058-31.876 13.149-49.044-9.927-17.185-31.893-23.058-49.061-13.148"></path>
        <path d="M105.097 615.058c-5.128 19.16 6.237 38.84 25.397 43.985l138.869 37.212-37.212 138.868c-5.128 19.16 6.236 38.858 25.397 43.985 19.16 5.128 38.84-6.236 43.985-25.396l45.995-171.663c2.685-10.048.797-20.252-4.348-28.411-4.505-8.541-12.387-15.28-22.435-17.982l-171.662-45.995c-19.161-5.128-38.858 6.236-43.986 25.397M974.903 464.942c5.128-19.16-6.237-38.84-25.397-43.985l-138.869-37.212 37.212-138.868c5.128-19.16-6.236-38.858-25.397-43.985-19.16-5.128-38.84 6.236-43.985 25.396l-45.995 171.663c-2.685 10.048-.797 20.252 4.348 28.411 4.505 8.541 12.387 15.28 22.435 17.982l171.662 45.995c19.161 5.128 38.858-6.236 43.986-25.397"></path>
      </g>
    </svg>
  );
}

export default IconaInverno;