import { useEffect, useState } from "react";
import { useGetList, useReference } from 'react-admin';
import { Card, CardContent, Box, Grid, Modal, Button, Typography, IconButton } from '@mui/material';
import { dataProvider } from '../generale';
import { LoadingComponent } from '../LoadingComponent';
import {
    FolderOpen,
    PostAdd,
    ModeEdit,
    UploadFile,
    Chat,
    NextPlan,
    RequestQuote,
    Bookmark,
    Euro,
    ForwardToInbox,
    Inventory,
    FamilyRestroom,
    VisibilityOff,
    Sms,
    History,
    Close
} from '@mui/icons-material';
import moment from 'moment';

const StoricoModal = ({ dataLog, status, update }) => {
    if (!dataLog) return <LoadingComponent/>
    // console.log(dataLog);
    return (
        <>
            <Modal
                open={status}
                onClose={() => update(!status)}
                keepMounted
                variant='customModalBox' sx={{ overflowY: 'hidden !important', '& > .MuiBox-root': { maxWidth: '300px !important'} }}
            >
                <Box>
                    <IconButton
                        aria-label="toggle visibility"
                        onClick={() => update(false)}
                        edge="end"
                        size="small"
                        variant="contained"
                        sx={{ position: 'absolute', right: 10, top: 10, outline: "0 !important", borderWidth: 1, borderStyle: 'solid' }}
                    >
                        <Close />
                    </IconButton>
                    {
                    dataLog.map((v,i) => {
                    const Icona = ({...props}) => {
                        var tmp;
                        switch (v.opDettagli.operazione) {
                            case 'inserimento':
                                tmp = <PostAdd {...props} />;
                                break;
                            case 'modifica':
                                tmp = <ModeEdit {...props} />;
                                break;
                            case 'upload_file':
                                tmp = <UploadFile {...props} />;
                                break;
                            case 'messaggio':
                                tmp = <Chat {...props} />;
                                break;
                            case 'cambio_stato':
                                tmp = <NextPlan {...props} />;
                                break;
                            case 'cambio_stato_pagamento':
                                tmp = <RequestQuote {...props} />;
                                break;
                            case 'cambio_stato_dati':
                                tmp = <Bookmark {...props} />;
                                break;
                            case 'pagamento':
                                tmp = <Euro {...props} />;
                                break;
                            case 'messaggio_custom':
                                tmp = <ForwardToInbox {...props} />;
                                break;
                            case 'email_finale':
                                tmp = <ForwardToInbox {...props} />;
                                break;
                            case 'archiviazione':
                                tmp = <Inventory {...props} />;
                                break;
                            case 'partecipanti':
                                tmp = <FamilyRestroom {...props} />;
                                break;
                            case 'anonimizzazione':
                                tmp = <VisibilityOff {...props} />;
                                break;
                            case 'sms_finale':
                                tmp = <Sms {...props} />;
                                break;
                            // case 'viaggio':
                            //     tmp = <Luggage />;
                            //     break;
                            // case 'utente':
                            //     tmp = <People />;
                            //     break;
                            // case 'richiesta':
                            //     tmp = <Assignment />;
                            //     break;
                            default:
                                tmp = <FolderOpen {...props} />;
                                break;
                        }
                        return tmp;
                    }
                    return (
                        <Card sx={{ mb: 1, p: 0.5 }} key={i} variant="outlined">
                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs='auto'>
                                    {<Icona color="primary" fontSize="large" />}
                                </Grid>
                                <Grid item xs>
                                    {/* Mostrare altri contenuti come i dettagli e gli ID delle mail inviate */}
                                    <Typography component='p' variant="p" sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: 13 }}>{v.opDettagli.operazioneIt}</Typography>
                                    <Typography component='p' variant="p" sx={{ fontSize: 11 }}>{moment(v.data_ora).format("dddd DD MMMM YYYY")}, ore {moment(v.data_ora).format("HH:mm:ss")}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    )
                })
                }
                </Box>
            </Modal>
            </>
    )
}

export const RichiesteStatiStorico = ({ idRichiesta, ...props }) => {
    const [statusModal, setStatusModal] = useState(false);
    // const [dataLog, setDataLog] = useState(null);
    const { data: dataOperazioni, isLoading: isLoadingOperazioni, error: errorOperazioni } = useGetList('logs_operazioni', {
        pagination: { page: 1, perPage: 99 },
        sort: { field: 'id', order: 'ASC' },
    });

    // Trovare il modo per filtrare solo determinati campi in caso la richiesta proviene da un utente normale
    const { data: dataLog, isLoading: isLoadingLog, error: errorLog } = useGetList('logs', {
        filter: { id_richiesta: idRichiesta },
        pagination: { page: 1, perPage: 999 },
        sort: { field: 'id', order: 'DESC' },
        meta: {
            include:['risposta']
        }
    });
    // dataProvider.getList('logs', {
    //     filter: { id_richiesta: idRichiesta },
    //     pagination: { page: 1, perPage: 999 },
    //     sort: { field: 'id', order: 'DESC' },
    //     meta: {
    //         include:'risposta'
    //     }
    // })
    // .then(({ data }) => {
    //     setDataLog(data);
    // })
    // .catch(error => {
    //     // setDettagliUtente(null);
    // })
    if (isLoadingOperazioni) return <LoadingComponent />
    if (errorOperazioni || !dataLog) return <p>Errore</p>
    // console.log(dataOperazioni);
    // console.log(dataLog);
    dataLog.map((log) => {
        const x = dataOperazioni.filter((op) => op.id === log.id_logs_operazioni);
        log.opDettagli = {risorsa: x[0].risorsa, operazione: x[0].operazione, operazioneIt: (x[0].operazione).replace('_', ' ') };
        return x;
    });
    
    return (
        <Grid container>
            <Grid item xs>
                <Button onClick={() => setStatusModal(!statusModal)} sx={props.sx.button}><History sx={{ mr: 1 }} />Storico attività</Button>
                <StoricoModal dataLog={dataLog} status={statusModal} update={setStatusModal} />
            </Grid>
        </Grid>
    )
}