import { useState, useEffect } from "react";
import { Edit, TextInput, BooleanInput, required, FunctionField, email, SaveButton, useRecordContext, Toolbar, Button, useUpdate, useRefresh, useNotify, usePermissions, SimpleForm, useEditContext, CheckboxGroupInput, DateInput } from 'react-admin';
import { Grid, Divider, Alert, AlertTitle, Typography, TextField, InputAdornment, Box, CardMedia, Button as ButtonMui } from '@mui/material';
import { ListaRuoliUtenti, verificaCF, logger, admB64, HeaderSezione, verificaPWDpattern, AlertWhatsapp, localeITmoment, apiBaseUrl } from '../generale';
import { Google, Facebook, PermIdentity, Login, Event, Share, Password, Edit as EditIcon, Cancel, WhatsApp, MailOutline, Sms } from '@mui/icons-material';
import moment from 'moment';
import { encode as base64_encode } from 'base-64';
import { LoadingComponent } from "../LoadingComponent";
import bcrypt from 'bcryptjs';

const TitoloSezione = () => {
    const record = useRecordContext();
    return <span>Modifica utente</span>;
    // return <span>Modifica Utente: <b>{record ? `"${record.nominativo} (ID: ${record.id})"` : ''}</b></span>;
};

const FormPassword = ({mostraForm}) => {
    const notify = useNotify();
    const [update] = useUpdate();
    const { record, isLoading } = useEditContext();
    const idUtente = localStorage.getItem('auth_uid');
    const [nuovaPwd1, setNuovaPwd1] = useState('');
    const [nuovaPwd2, setNuovaPwd2] = useState('');
    const [passwordValida, setPasswordValida] = useState(null);

    const validazionePWD = (pwd) => {
        const reg = new RegExp(verificaPWDpattern);
        return reg.test(pwd)
    }

    useEffect(() => {
        if (nuovaPwd1 === '' && nuovaPwd2 === '')
        setPasswordValida(null)
        else if (nuovaPwd1 === nuovaPwd2 && validazionePWD(nuovaPwd1))
        setPasswordValida(true)
        else
        setPasswordValida(false)
    }, [nuovaPwd1, nuovaPwd2, passwordValida]);

    const modificaPassword = () => {
        update( 'utente', {
            id: record.id,
            data: {
                hashed_pwd: bcrypt.hashSync(nuovaPwd1)
            },
            previousData: record
        }, {
            onSuccess: () => {
                notify(`La password è stata modificata con successo.`, { type: 'success' });
                logger(5, null, idUtente, null, null, idUtente);
                setPasswordValida(null);
                mostraForm(false);
            },
            onError: () => {
                notify(`Non è stato possibile procedere con la modifica della password. Riprova più tardi o ricontrolla i dati inseriti.`, { type: 'error' });
            }
        });

    };

    if (isLoading) return <LoadingComponent />
    if (!record.g_id && record.g_id !== '' && !record.fb_id && record.fb_id !== '')
    return (
        <>
            <Grid item xs={12} lg>
                <TextField
                    id="password_nuova_1"
                    label="Nuova password"
                    type="password"
                    fullWidth
                    defaultValue={nuovaPwd1}
                    error={!passwordValida}
                    onChange={e => setNuovaPwd1(e.target.value) }
                />
            </Grid>
            <Grid item xs={12} lg>
                <TextField
                    id="password_nuova_2"
                    label="Ripeti la nuova password"
                    type="password"
                    fullWidth
                    defaultValue={nuovaPwd2}
                    error={!passwordValida}
                    onChange={e => setNuovaPwd2(e.target.value) }
                />
            </Grid>
            <Grid item xs={12} lg py='0 !important'>
                {passwordValida === false ? 
                    <Alert severity="warning">Inserisci una password valida:
                        <ul>
                            <li>almeno 8 caratteri</li>
                            <li>caratteri maiuscoli</li>
                            <li>caratteri minuscoli</li>
                            <li>numeri</li>
                            <li>caratteri speciali ammessi (#@?!$_)</li>
                        </ul>
                    </Alert>
                : 
                <>
                <Grid display='flex' flexDirection='row' justifyContent='stretch'>
                {passwordValida === true ? 
                    <ButtonMui endIcon={<EditIcon />} fullWidth sx={{ maxWidth: {sm: '300px'}}} size="medium" disabled={!passwordValida} variant="contained" onClick={modificaPassword}>Modifica</ButtonMui>
                    :
                    <ButtonMui endIcon={<Cancel />} fullWidth sx={{ maxWidth: {sm: '300px'}, height: '100%'}} size="medium" onClick={() => mostraForm(false)}>Annulla</ButtonMui>
                }
                </Grid>
                {passwordValida === true ? 
                <Alert severity="success" sx={{ mt: 2 }}>La password inserita è formalmente corretta.</Alert>
                : null }
                </>
                }
            </Grid>
        </>
    )
};

export const UtenteModifica = () => {
    const idUtente = localStorage.getItem('auth_uid');
    const notify = useNotify();
    const refresh = useRefresh();
    const { permissions } = usePermissions();
    const [mostraFormPwd, setMostraFormPwd] = useState(false);

    const onSuccess = ({id}) => {
        logger(5, null, id, null, null, idUtente);
        notify(`Modifiche salvate`, { type: 'success' });
        refresh();
    };
    moment.updateLocale('it', localeITmoment);

    return (
        <Grid container>
            <Grid item xs md={4} xl={3}
                sx={{ 
                    display:'flex', 
                    alignItems:'center', 
                    flexDirection: 'column' 
                }}
            >
                <Box sx={{ position: 'sticky', top: 100 }} p={2} display='flex' flexDirection='column' alignItems='center'>
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/logo.png`)}
                        sx={{ maxWidth: { xs: "130px", md: "200px"} }}
                    />
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/profilo-user.jpg`)}
                        sx={{ maxWidth: { xs: "200px", md: "100%"} }}
                    />
                    <Typography component="h3" variant="titoloSezione" color="primary" textAlign='center'>Profilo personale</Typography>
                    <Typography component="p" variant="p" textAlign='center' mt={2}>In questa sezione trovi le tue informazioni personali. Tienile sempre aggiornate.</Typography>
                </Box>
            </Grid>
            <Grid item md={8} xl={9}>
                <Edit resource="utente" title={<TitoloSezione />} sx={{ pb: 0 }} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
                    <SimpleForm toolbar={<Toolbar><SaveButton /></Toolbar>}>
                        
                        <HeaderSezione icona={PermIdentity} titolo="Dati personali" />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} xl={4}>
                                <TextInput source="nome" label="Nome" type="text" validate={[required()]} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <TextInput source="cognome" label="Cognome" type="text" validate={[required()]} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <TextInput source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <TextInput source="telefono" label="Cellulare" type="text" validate={[required()]} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">+39</InputAdornment> }} />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <TextInput source="comune" label="Comune di residenza" type="text" validate={[required()]} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <TextInput source="indirizzo" label="Indirizzo di residenza" type="text" validate={[required()]} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <TextInput source="comune_nascita" label="Comune di nascita" type="text" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <DateInput source="data_nascita" label="Data di nascita" sx={{ width: '100%' }} />
                            </Grid>
                            {permissions !== base64_encode(admB64) ?
                            <Grid item xs={12}>
                                <AlertWhatsapp icona={Sms} />
                            </Grid>
                            :null}
                        </Grid>

                        <Grid container spacing={2} mb={3}>
                            <Grid item xs={12}>
                                <HeaderSezione icona={MailOutline} titolo="Newsletter" />
                                <CheckboxGroupInput
                                    fullWidth
                                    source="optin"
                                    choices={[{ id: 1, testo: 'Voglio restare sempre aggiornato sui prossimi viaggi' }]}
                                    optionText='testo'
                                    sx={{ mb: 0 }}
                                    helperText={false}
                                    parse={(v) => {
                                        return (v[0] === 1 || v[0] === true ? 1 : 0)
                                    }}
                                    format={(v) => {
                                        return (v === true || v === 1 ? [1] : [])
                                    }}
                                />
                            </Grid>
                        </Grid>
                        
                        <HeaderSezione icona={Login} titolo="Dati di accesso" />
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6} xl={4}>
                                <TextInput source="email" label="E-Mail" type="email" disabled={true} validate={[required(), email()]} fullWidth />
                            </Grid>

                            {permissions !== base64_encode(admB64) ?
                            <Grid item xs={12} lg pt='0 !important' mb={{ xs: 2, lg: 0 }}>
                                <FunctionField label="g_id" render={record => (
                                    record.g_id && record.g_id !== '' ?
                                    <Grid container display='flex' alignItems='center'>
                                        <Grid item><Google color="success" fontSize="large" /></Grid>
                                        <Grid item ml={1.5}><Typography variant="p">Hai effettuato l'accesso tramite Google</Typography></Grid>
                                    </Grid>
                                    : null
                                )} />
                                <FunctionField label="fb_id" render={record => (
                                    record.fb_id && record.fb_id !== '' ?
                                    <Grid container display='flex' alignItems='center'>
                                        <Grid item><Facebook color="success" fontSize="large" /></Grid>
                                        <Grid item ml={1.5}><Typography variant="p">Hai effettuato l'accesso tramite Facebook</Typography></Grid>
                                    </Grid>
                                    : null
                                )} />
                            </Grid>
                            : null }

                            {permissions === base64_encode(admB64) ?
                            <Grid item xs={12} sm={6} md={4}>
                                <ListaRuoliUtenti richiesto={true} fullWidth />
                            </Grid>
                            : null}

                            {permissions === base64_encode(admB64) ?
                            <Grid item xs={12}>
                                <BooleanInput source="stato" fullWidth />
                            </Grid>
                            : null}

                        </Grid>

                        {permissions !== base64_encode(admB64) ?
                        <FunctionField render={record => {
                            if ((record.g_id === null || record.g_id === '') && (record.fb_id === null || record.fb_id === '')) {
                                return <>
                                    <HeaderSezione icona={Password} titolo="Modifica password" />
                                    <Grid container spacing={2}>
                                            {mostraFormPwd ? 
                                            <FormPassword mostraForm={setMostraFormPwd} /> 
                                            : 
                                            <Grid item mt={-2}>
                                                <ButtonMui endIcon={<EditIcon />} size="medium" onClick={() => setMostraFormPwd(!mostraFormPwd)}>Modifica</ButtonMui>
                                            </Grid>
                                            }
                                    </Grid>
                                </>
                            }
                        }}
                        />
                        : null }

                        {permissions === base64_encode(admB64) ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} mt={2}>
                                    <HeaderSezione icona={Event} titolo="Data di iscrizione" />
                                    <FunctionField label="Name" render={record => <Typography variant="p">{moment(record.creazione).format("DD MMMM YYYY, HH:mm:ss")}</Typography>} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} mt={2}>
                                    <HeaderSezione icona={Share} titolo="Accessi esterni" />
                                    <Grid container columnSpacing={2} rowSpacing={0}>
                                        <Grid item xs={12} sm="auto">
                                            <FunctionField label="Name" render={record => (
                                                record.g_id && record.g_id !== ''
                                                ? <Google color="success" fontSize="large" />
                                                : <Google color="error" fontSize="large" label="Google non attivo" />
                                            )} />
                                        </Grid>
                                        <Grid item xs={12} sm="auto">
                                            <FunctionField label="Name" render={record => (
                                                record.fb_id && record.fb_id !== ''
                                                ? <Facebook color="success" fontSize="large" />
                                                : <Facebook color="error" fontSize="large" label="Facebook non attivo" />
                                            )} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        : null }

                    </SimpleForm>
                </Edit>
            </Grid>
        </Grid>
    )
};