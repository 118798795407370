import * as React from "react";
import { List, Datagrid, TextField, FunctionField, useTheme } from 'react-admin';
import { Paper, Box, Grid, Tooltip, useMediaQuery, Typography } from '@mui/material';
import { ArrowCircleRight, PauseCircleOutline, TaskAlt, Verified, WarningAmber, DoNotDisturb, CheckCircleOutline } from '@mui/icons-material';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';
import { titoloPagina, HeaderSezione } from '../generale';

const AlertRichiesteIncomplete = () => {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));

    return (
        <List resource="alert_richieste_incomplete" exporter={false} empty={<Box pb={2} px={2}><Typography component='p'>Non ci sono richieste incomplete</Typography></Box>} actions={false} pagination={false} title=" ">
            <Datagrid bulkActionButtons={false} rowClick={(id, res, record) => { return `/richiesta/${record.id}` }}>
                {isDesktop ?
                <TextField source="id" sortable={false} />
                : null}
                <TextField source="nominativo" label='Nominativo' sortable={false} />
                <FunctionField label="Viaggio" sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} />
                <TextField source="giorni" label='Giorni mancanti' textAlign="center" />
                <FunctionField label="Dati" sortable={false} textAlign="center" render={record => {
                    switch (parseInt(record.id_stato_dati)) {
                        case 1:
                            return <Tooltip title="In attesa"><WarningAmber color="primary" /></Tooltip>
                        case 3:
                            return <Tooltip title="Incompleti"><DoNotDisturb color="error" /></Tooltip>
                        default:
                            return <Tooltip title="Completi"><CheckCircleOutline color="success" /></Tooltip>
                    }
                }} />
                <FunctionField label="Pagamenti" sortable={false} textAlign="center" render={record => {
                    switch (parseInt(record.id_stato_pagamenti)) {
                        case 1:
                            return <Tooltip title="In attesa"><DoNotDisturb color="error" /></Tooltip>
                        case 3:
                            return <Tooltip title="Pagamento alla partenza"><WarningAmber color="primary" /></Tooltip>
                        default:
                            return <Tooltip title="Accettato"><CheckCircleOutline color="success" /></Tooltip>
                    }
                }} />

            </Datagrid>
        </List>
    )
};

const AlertViaggiPendenti = () => {
    const [theme, setTheme] = useTheme();
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));

    return (
        <List resource="alert_viaggi_pendenti" exporter={false} empty={<Box pb={2} px={2}><Typography component='p'>Non ci sono viaggi pendenti</Typography></Box>} actions={false} pagination={false} title=" " sort={{ field: 'giorni', order: 'ASC' }}>
            <Datagrid bulkActionButtons={false}>
                {isDesktop ?
                <TextField source="id" sortable={false} />
                : null }
                <FunctionField label="Viaggio" sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} />
                <TextField source="giorni" label='Giorni mancanti' textAlign="center" sortable={false} />
                <TextField
                    source="n_da_validare"
                    sortable={false}
                    color={theme.palette.warning[900]}
                    textAlign="center"
                    fontWeight="bold"
                    label={<Tooltip title="Partecipanti da validare"><PauseCircleOutline color="warning" /></Tooltip>}
                />
                <TextField
                    source="n_validi"
                    color="primary"
                    sortable={false}
                    textAlign="center"
                    fontWeight="bold"
                    label={<Tooltip title="Partecipanti validi"><TaskAlt color="primary" /></Tooltip>}
                />
                <TextField
                    source="n_confermati"
                    color={theme.palette.success[700]}
                    sortable={false}
                    textAlign="center"
                    fontWeight="bold"
                    label={<Tooltip title="Partecipanti confermati"><Verified color="success" /></Tooltip>}
                />
            </Datagrid>
        </List>
    )
};

export const Alert = () => {
    React.useEffect(() => {
        document.title = titoloPagina;
    });

    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} lg={6}>
                <Paper elevation={3}>
                    <Box pt={2} px={2}>
                        <HeaderSezione icona={ArrowCircleRight} titolo="Richieste incomplete" dettaglio="Richieste validate ma non ancora confermate a 10 giorni dalla partenza" />
                    </Box>
                    <AlertRichiesteIncomplete />
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={3}>
                    <Box pt={2} px={2}>
                        <HeaderSezione icona={ArrowCircleRight} titolo="Viaggi pendenti" dettaglio="Viaggi non ancora confermati a 20 giorni dalla partenza" />
                    </Box>
                    <AlertViaggiPendenti />
                </Paper>
            </Grid>
        </Grid>
    )
}