import React from "react";

function IconaFerragosto() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g fillRule="nonzero">
        <path d="M563.413 1049.2a16.444 16.444 0 01-15.582-11.245L426.065 672.74c-2.869-8.607 1.781-17.907 10.371-20.792 8.69-2.836 17.906 1.814 20.775 10.388l121.783 365.232c2.869 8.607-1.781 17.906-10.371 20.775a16.504 16.504 0 01-5.21.857"></path>
        <path d="M873.083 1048.75H267.797c-9.068 0-16.406-7.337-16.406-16.422s7.338-16.422 16.406-16.422h605.286c9.068 0 16.405 7.337 16.405 16.422s-7.337 16.422-16.405 16.422M847.288 300.723c-43.991 0-79.787-35.779-79.787-79.77 0-43.974 35.796-79.77 79.787-79.77 43.975 0 79.771 35.796 79.771 79.77 0 43.991-35.796 79.77-79.771 79.77m0-126.696c-25.87 0-46.942 21.056-46.942 46.943 0 25.837 21.072 46.909 46.942 46.909s46.926-21.072 46.926-46.909c0-25.887-21.056-46.943-46.926-46.943M632.673 617.317a16.45 16.45 0 01-15.119-10.008c-47.437-111.593-151.132-192.435-270.639-210.935-7.503-1.187-13.224-7.321-13.834-14.889-.643-7.535 3.974-14.559 11.163-16.934 42.045-12.91 84.617-19.258 127.668-19.258 143.613 0 277.432 71.114 357.928 190.308 2.902 4.303 3.611 9.761 1.864 14.658a16.454 16.454 0 01-10.685 10.239l-183.548 56.093a16.655 16.655 0 01-4.798.726M420.914 381.369c96.11 34.214 176.755 106.218 220.811 199.591l147.834-45.194c-75.318-99.095-192.451-157.628-317.663-157.628a404.369 404.369 0 00-50.982 3.231"></path>
        <path d="M56.655 793.376a16.407 16.407 0 01-9.761-3.216c-4.188-3.116-6.662-7.996-6.662-13.207 0-170.703 100.942-325.677 257.185-394.828 15.565-6.893 31.443-12.845 47.189-17.692a16.414 16.414 0 017.371-.544c.23.049 1.698.329 1.912.362 5.837 1.171 11.097 5.491 12.927 11.113 1.863 5.639 1.039 11.938-2.951 16.307-59.919 65.294-92.912 150.06-92.912 238.668 0 26.514 3.067 53.274 9.102 79.54 1.929 8.392-2.952 16.851-11.212 19.374l-207.39 63.414a16.589 16.589 0 01-4.798.709M303.419 415.48C170.012 477.757 81.915 608.459 73.704 754.562l171.198-52.317c-4.518-23.826-6.81-47.948-6.81-71.906 0-77.165 22.886-151.659 65.327-214.859"></path>
        <path d="M264.054 729.981c-7.04 0-13.537-4.534-15.713-11.624-2.655-8.656 2.242-17.873 10.915-20.495l368.629-112.681c8.59-2.654 17.84 2.226 20.495 10.899 2.655 8.673-2.226 17.857-10.899 20.495L268.852 729.256a16.203 16.203 0 01-4.798.725M842.667 112.91c-9.085 0-16.423-7.337-16.423-16.406V47.221c0-9.069 7.338-16.422 16.423-16.422 9.085 0 16.422 7.353 16.422 16.422v49.283c0 9.069-7.337 16.406-16.422 16.406M842.667 408.573c-9.085 0-16.423-7.337-16.423-16.405v-49.284c0-9.052 7.338-16.422 16.423-16.422 9.085 0 16.422 7.37 16.422 16.422v49.284c0 9.068-7.337 16.405-16.422 16.405M720.51 357.869a16.343 16.343 0 01-11.625-4.831c-6.413-6.431-6.397-16.818.017-23.216l34.922-34.807c6.431-6.414 16.818-6.381 23.232.033 6.398 6.431 6.365 16.851-.049 23.249l-34.889 34.757c-3.232 3.215-7.42 4.815-11.608 4.815M929.906 149.156a16.379 16.379 0 01-11.624-4.831c-6.414-6.447-6.398-16.834.033-23.232l34.906-34.806c6.43-6.431 16.834-6.365 23.215.033 6.414 6.43 6.381 16.851-.033 23.248l-34.906 34.774c-3.215 3.199-7.403 4.814-11.591 4.814M964.815 357.869c-4.221 0-8.376-1.6-11.591-4.815l-34.906-34.757c-6.414-6.431-6.447-16.818-.033-23.249 6.381-6.397 16.802-6.414 23.216-.033l34.905 34.807c6.431 6.398 6.431 16.785.033 23.216a16.34 16.34 0 01-11.624 4.831M755.427 149.156c-4.221 0-8.393-1.615-11.608-4.814l-34.905-34.774c-6.414-6.414-6.431-16.818-.017-23.248 6.397-6.398 16.802-6.431 23.216-.033l34.905 34.806c6.414 6.398 6.431 16.785.033 23.232a16.433 16.433 0 01-11.624 4.831M727.684 227.911h-65.706c-9.085 0-16.422-7.354-16.422-16.439 0-9.052 7.337-16.422 16.422-16.422h65.706c9.085 0 16.422 7.37 16.422 16.422 0 9.085-7.337 16.439-16.422 16.439M1023.36 227.911h-65.723c-9.085 0-16.405-7.354-16.405-16.439 0-9.052 7.32-16.422 16.405-16.422h65.723c9.052 0 16.406 7.37 16.406 16.422 0 9.085-7.354 16.439-16.406 16.439"></path>
      </g>
    </svg>
  );
}

export default IconaFerragosto;