import React from "react";

function IconaCarnevale() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g fillRule="nonzero">
        <path d="M1049.86 126.925l-18.441-7.625C894.54 62.67 720.117 31.262 540.253 30.799h-3.936c-178.31 0-351.327 30.432-487.544 85.855l-18.623 7.558v924.989h59.723V457.858c49.387 61.179 124.954 100.36 209.503 100.36 105.09 0 196.304-60.517 240.629-148.555 44.325 88.038 135.556 148.555 240.629 148.555 148.456 0 269.226-120.786 269.226-269.209V126.925zM780.634 498.511c-115.51 0-209.502-94.009-209.502-209.502h-62.254c0 115.493-93.976 209.502-209.502 209.502-115.527 0-209.503-94.009-209.503-209.502V164.634C216.167 116.736 373.604 90.522 536.3 90.522h3.804c164.202.397 323.077 27.521 450.033 76.576l.016 121.911c-.016 115.493-93.992 209.502-209.519 209.502"></path>
        <path d="M389.174 271.676c-24.429-26.116-51.983-41.547-79.703-44.64-27.736-3.092-58.019 5.905-87.591 26-20.377 13.86-33.641 28.266-35.113 29.853l-14.505 15.994 10.635 18.772c1.058 1.869 10.866 18.838 27.686 36.849 24.412 26.116 51.983 41.547 79.703 44.64 3.572.397 7.178.595 10.816.595 24.677 0 50.991-9.08 76.776-26.595 20.376-13.86 33.673-28.266 35.112-29.853l14.505-15.977-10.635-18.789c-1.058-1.869-10.833-18.854-27.686-36.849m-44.871 52.082c-12.305 8.352-30.515 17.928-47.402 16.043-16.555-1.852-31.92-14.737-41.86-25.222a143.319 143.319 0 01-6.434-7.195 127.014 127.014 0 017.856-5.623c12.024-8.038 29.837-17.218 46.393-15.382 16.555 1.853 31.92 14.737 41.877 25.223a152.322 152.322 0 016.434 7.211c-2.15 1.62-4.416 3.291-6.864 4.945M859.735 253.036c-29.556-20.095-59.856-29.092-87.576-26-27.736 3.093-55.29 18.541-79.702 44.656-16.837 17.995-26.645 34.948-27.687 36.833l-10.651 18.772 14.521 15.977c1.456 1.621 14.72 16.027 35.097 29.87 25.801 17.515 52.115 26.579 76.791 26.579 3.639 0 7.228-.199 10.8-.596 27.737-3.076 55.291-18.507 79.72-44.606 16.836-18.011 26.628-34.981 27.67-36.883l10.634-18.772-14.488-15.96c-1.455-1.621-14.753-15.993-35.129-29.87m-33.128 61.559c-9.957 10.469-25.322 23.37-41.878 25.189-16.539 1.853-34.385-7.31-46.409-15.365a148.352 148.352 0 01-7.839-5.606 142.908 142.908 0 016.417-7.228c9.956-10.469 25.321-23.337 41.861-25.189 16.555-1.869 34.385 7.343 46.425 15.365 2.795 1.869 5.392 3.771 7.84 5.607a143.887 143.887 0 01-6.417 7.227M539.997 177.382c-15.613 0-30.581 13.728-29.854 29.87.712 16.159 13.116 29.837 29.854 29.837 15.629 0 30.597-13.728 29.87-29.837-.712-16.192-13.116-29.87-29.87-29.87M450.129 133.127c-15.613 0-30.581 13.727-29.853 29.853.711 16.159 13.115 29.837 29.853 29.837 15.613 0 30.598-13.728 29.87-29.837-.711-16.192-13.116-29.853-29.87-29.853M663.121 162.975c-.744-16.175-13.132-29.853-29.87-29.853-15.613 0-30.564 13.727-29.836 29.853.727 16.192 13.115 29.853 29.836 29.853 15.63 0 30.581-13.727 29.87-29.853"></path>
      </g>
    </svg>
  );
}

export default IconaCarnevale;