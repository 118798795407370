import { useEffect, useState, useRef, forwardRef } from 'react';
import { fetchUtils, useGetList, SelectInput, useGetOne, useNotify, required, regex, defaultTheme } from 'react-admin';
import treeqlProvider from 'ra-data-treeql';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import it, { ra } from 'ra-language-italian';
import logoImageUrl from '../includes/img/logo.png';
import loginBgUrl from '../includes/img/login.jpg';
import { Typography, Divider, Grid, useMediaQuery, Alert, Button, Box, Link } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { orange, yellow, red, green, grey, amber } from '@mui/material/colors';
import { encode as base64_encode } from 'base-64';
import { Warning } from '@mui/icons-material/';

export const LogoImg = logoImageUrl;
export const LoginBg = loginBgUrl;
export const titoloPagina = 'Prenota con Virgilio Viaggia';
export const valutaFormat = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
});

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    // options.headers.set('X-Custom-Header', 'foobar');
    // options.headers.set('Access-Control-Expose-Headers', 'Content-Range');
    // options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count');
    options.headers.set('Content-Type', 'text/plain');
    // options.headers.set('X-API-Key', '83ba807e-d8af-44d2-869c-415a716d5d6a');
    options.headers.set('X-API-Key', localStorage.getItem('auth_api_key'));
    return fetchUtils.fetchJson(url, options);
}
export const publicUrl = 'https://www.' + process.env.REACT_APP_DOMAIN;
export const apiBaseUrl = 'https://prenota.' + process.env.REACT_APP_DOMAIN;
export const apiFullUrl = apiBaseUrl + process.env.REACT_APP_API_ENDPOINT;
// export const apiFullUrl = apiBaseUrl + '/api' + (window.location.hostname === "localhost" ? "-test" : "") + '.php';
export const apiGuestFullUrl = apiBaseUrl + process.env.REACT_APP_API_ENDPOINT_GUEST;
export const payPalPaymentUrl = process.env.REACT_APP_PAYPAL_PAYMENT_URL;
// export const payPalPaymentUrl = 'https://www.' + (window.location.hostname === "localhost" ? "sandbox." : "") + 'paypal.com/activity/payment/';
export const hCaptcha_sitekey = process.env.REACT_APP_HCAPTCHA_SITEKEY;
export const admB64 = process.env.REACT_APP_ADMB64;
export const apiUsersFilesUrl = apiBaseUrl + '/users_uploads/';
export const verificaCFpattern = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
export const verificaPWDpattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;
// export const verificaPWDpattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const verificaEmailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const verificaCF = regex(verificaCFpattern, "Inserire un codice fiscale valido");
export const verificaPWD = regex(verificaPWDpattern, 'La password deve contenere almeno 8 caratteri, di cui almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)');
export const dataProvider = treeqlProvider(apiFullUrl, fetchJson);

export const overrideIT = {
    ...it,
    ra: {
        ...it.ra,
        page: {
            ...it.ra.page,
            dashboard: 'Dashboard',
        }
    }
};

export const localeITmoment = {
    months: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
    ],
    monthsShort: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
    ],
    weekdays: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
    ]
};

export const i18nProvider = polyglotI18nProvider(() => overrideIT, 'it', { allowMissing: true });

export const ListaProvince = [{
    id: 1,
    name: 'Agrigento',
    sigla: 'AG'
},
{
    id: 2,
    name: 'Alessandria',
    sigla: 'AL'
},
{
    id: 3,
    name: 'Ancona',
    sigla: 'AN'
},
{
    id: 4,
    name: 'Aosta',
    sigla: 'AO'
},
{
    id: 5,
    name: 'Arezzo',
    sigla: 'AR'
},
{
    id: 6,
    name: 'Ascoli Piceno',
    sigla: 'AP'
},
{
    id: 7,
    name: 'Asti',
    sigla: 'AT'
},
{
    id: 8,
    name: 'Avellino',
    sigla: 'AV'
},
{
    id: 9,
    name: 'Bari',
    sigla: 'BA'
},
{
    id: 10,
    name: 'Barletta - Andria - Trani',
    sigla: 'BT'
},
{
    id: 11,
    name: 'Belluno',
    sigla: 'BL'
},
{
    id: 12,
    name: 'Benevento',
    sigla: 'BN'
},
{
    id: 13,
    name: 'Bergamo',
    sigla: 'BG'
},
{
    id: 14,
    name: 'Biella',
    sigla: 'BI'
},
{
    id: 15,
    name: 'Bologna',
    sigla: 'BO'
},
{
    id: 16,
    name: 'Bolzano',
    sigla: 'BZ'
},
{
    id: 17,
    name: 'Brescia',
    sigla: 'BS'
},
{
    id: 18,
    name: 'Brindisi',
    sigla: 'BR'
},
{
    id: 19,
    name: 'Cagliari',
    sigla: 'CA'
},
{
    id: 20,
    name: 'Caltanissetta',
    sigla: 'CL'
},
{
    id: 21,
    name: 'Campobasso',
    sigla: 'CB'
},
{
    id: 22,
    name: 'Carbonia - Iglesias',
    sigla: 'CI'
},
{
    id: 23,
    name: 'Caserta',
    sigla: 'CE'
},
{
    id: 24,
    name: 'Catania',
    sigla: 'CT'
},
{
    id: 25,
    name: 'Catanzaro',
    sigla: 'CZ'
},
{
    id: 26,
    name: 'Chieti',
    sigla: 'CH'
},
{
    id: 27,
    name: 'Como',
    sigla: 'CO'
},
{
    id: 28,
    name: 'Cosenza',
    sigla: 'CS'
},
{
    id: 29,
    name: 'Cremona',
    sigla: 'CR'
},
{
    id: 30,
    name: 'Crotone',
    sigla: 'KR'
},
{
    id: 31,
    name: 'Cuneo',
    sigla: 'CN'
},
{
    id: 32,
    name: 'Enna',
    sigla: 'EN'
},
{
    id: 33,
    name: 'Fermo',
    sigla: 'FM'
},
{
    id: 34,
    name: 'Ferrara',
    sigla: 'FE'
},
{
    id: 35,
    name: 'Firenze',
    sigla: 'FI'
},
{
    id: 36,
    name: 'Foggia',
    sigla: 'FG'
},
{
    id: 37,
    name: 'Forlì- Cesena',
    sigla: 'FC'
},
{
    id: 38,
    name: 'Frosinone',
    sigla: 'FR'
},
{
    id: 39,
    name: 'Genova',
    sigla: 'GE'
},
{
    id: 40,
    name: 'Gorizia',
    sigla: 'GO'
},
{
    id: 41,
    name: 'Grosseto',
    sigla: 'GR'
},
{
    id: 42,
    name: 'Imperia',
    sigla: 'IM'
},
{
    id: 43,
    name: 'Isernia',
    sigla: 'IS'
},
{
    id: 44,
    name: 'La Spezia',
    sigla: 'SP'
},
{
    id: 45,
    name: 'L’aquila',
    sigla: 'AQ'
},
{
    id: 46,
    name: 'Latina',
    sigla: 'LT'
},
{
    id: 47,
    name: 'Lecce',
    sigla: 'LE'
},
{
    id: 48,
    name: 'Lecco',
    sigla: 'LC'
},
{
    id: 49,
    name: 'Livorno',
    sigla: 'LI'
},
{
    id: 50,
    name: 'Lodi',
    sigla: 'LO'
},
{
    id: 51,
    name: 'Lucca',
    sigla: 'LU'
},
{
    id: 52,
    name: 'Macerata',
    sigla: 'MC'
},
{
    id: 53,
    name: 'Mantova',
    sigla: 'MN'
},
{
    id: 54,
    name: 'Massa - Carrara',
    sigla: 'MS'
},
{
    id: 55,
    name: 'Matera',
    sigla: 'MT'
},
{
    id: 56,
    name: 'Medio Campidano',
    sigla: 'VS'
},
{
    id: 57,
    name: 'Messina',
    sigla: 'ME'
},
{
    id: 58,
    name: 'Milano',
    sigla: 'MI'
},
{
    id: 59,
    name: 'Modena',
    sigla: 'MO'
},
{
    id: 60,
    name: 'Monza E Brianza',
    sigla: 'MB'
},
{
    id: 61,
    name: 'Napoli',
    sigla: 'NA'
},
{
    id: 62,
    name: 'Novara',
    sigla: 'NO'
},
{
    id: 63,
    name: 'Nuoro',
    sigla: 'NU'
},
{
    id: 64,
    name: 'Ogliastra',
    sigla: 'OG'
},
{
    id: 65,
    name: 'Olbia - Tempio',
    sigla: 'OT'
},
{
    id: 66,
    name: 'Oristano',
    sigla: 'OR'
},
{
    id: 67,
    name: 'Padova',
    sigla: 'PD'
},
{
    id: 68,
    name: 'Palermo',
    sigla: 'PA'
},
{
    id: 69,
    name: 'Parma',
    sigla: 'PR'
},
{
    id: 70,
    name: 'Pavia',
    sigla: 'PV'
},
{
    id: 71,
    name: 'Perugia',
    sigla: 'PG'
},
{
    id: 72,
    name: 'Pesaro E Urbino',
    sigla: 'PU'
},
{
    id: 73,
    name: 'Pescara',
    sigla: 'PE'
},
{
    id: 74,
    name: 'Piacenza',
    sigla: 'PC'
},
{
    id: 75,
    name: 'Pisa',
    sigla: 'PI'
},
{
    id: 76,
    name: 'Pistoia',
    sigla: 'PT'
},
{
    id: 77,
    name: 'Pordenone',
    sigla: 'PN'
},
{
    id: 78,
    name: 'Potenza',
    sigla: 'PZ'
},
{
    id: 79,
    name: 'Prato',
    sigla: 'PO'
},
{
    id: 80,
    name: 'Ragusa',
    sigla: 'RG'
},
{
    id: 81,
    name: 'Ravenna',
    sigla: 'RA'
},
{
    id: 82,
    name: 'Reggio Calabria',
    sigla: 'RC'
},
{
    id: 83,
    name: 'Reggio Emilia',
    sigla: 'RE'
},
{
    id: 84,
    name: 'Rieti',
    sigla: 'RI'
},
{
    id: 85,
    name: 'Rimini',
    sigla: 'RN'
},
{
    id: 86,
    name: 'Roma',
    sigla: 'RM'
},
{
    id: 87,
    name: 'Rovigo',
    sigla: 'RO'
},
{
    id: 88,
    name: 'Salerno',
    sigla: 'SA'
},
{
    id: 89,
    name: 'Sassari',
    sigla: 'SS'
},
{
    id: 90,
    name: 'Savona',
    sigla: 'SV'
},
{
    id: 91,
    name: 'Siena',
    sigla: 'SI'
},
{
    id: 92,
    name: 'Siracusa',
    sigla: 'SR'
},
{
    id: 93,
    name: 'Sondrio',
    sigla: 'SO'
},
{
    id: 94,
    name: 'Taranto',
    sigla: 'TA'
},
{
    id: 95,
    name: 'Teramo',
    sigla: 'TE'
},
{
    id: 96,
    name: 'Terni',
    sigla: 'TR'
},
{
    id: 97,
    name: 'Torino',
    sigla: 'TO'
},
{
    id: 98,
    name: 'Trapani',
    sigla: 'TP'
},
{
    id: 99,
    name: 'Trento',
    sigla: 'TN'
},
{
    id: 100,
    name: 'Treviso',
    sigla: 'TV'
},
{
    id: 101,
    name: 'Trieste',
    sigla: 'TS'
},
{
    id: 102,
    name: 'Udine',
    sigla: 'UD'
},
{
    id: 103,
    name: 'Varese',
    sigla: 'VA'
},
{
    id: 104,
    name: 'Venezia',
    sigla: 'VE'
},
{
    id: 105,
    name: 'Verbano - Cusio - Ossola',
    sigla: 'VB'
},
{
    id: 106,
    name: 'Vercelli',
    sigla: 'VC'
},
{
    id: 107,
    name: 'Verona',
    sigla: 'VR'
},
{
    id: 108,
    name: 'Vibo Valentia',
    sigla: 'VV'
},
{
    id: 109,
    name: 'Vicenza',
    sigla: 'VI'
},
{
    id: 110,
    name: 'Viterbo',
    sigla: 'VT'
}
];

export const listaStati = [
    {
        id: 0,
        name: "Italia",
        prefix: "+39"
    },
    {
        id: 1,
        name: "Albania",
        prefix: "+355"
    },
    {
        id: 2,
        name: "Andorra",
        prefix: "+376"
    },
    {
        id: 3,
        name: "Armenia",
        prefix: "+374"
    },
    {
        id: 4,
        name: "Austria",
        prefix: "+43"
    },
    {
        id: 5,
        name: "Azerbaigian",
        prefix: "+994"
    },
    {
        id: 6,
        name: "Bielorussia",
        prefix: "+375"
    },
    {
        id: 7,
        name: "Belgio",
        prefix: "+32"
    },
    {
        id: 8,
        name: "Bosnia ed Erzegovina",
        prefix: "+387"
    },
    {
        id: 9,
        name: "Bulgaria",
        prefix: "+359"
    },
    {
        id: 10,
        name: "Cipro",
        prefix: "+357"
    },
    {
        id: 11,
        name: "Croazia",
        prefix: "+385"
    },
    {
        id: 12,
        name: "Danimarca",
        prefix: "+45"
    },
    {
        id: 13,
        name: "Estonia",
        prefix: "+372"
    },
    {
        id: 14,
        name: "Finlandia",
        prefix: "+358"
    },
    {
        id: 15,
        name: "Francia",
        prefix: "+33"
    },
    {
        id: 16,
        name: "Georgia",
        prefix: "+995"
    },
    {
        id: 17,
        name: "Germania",
        prefix: "+49"
    },
    {
        id: 18,
        name: "Grecia",
        prefix: "+30"
    },
    {
        id: 19,
        name: "Ungheria",
        prefix: "+36"
    },
    {
        id: 20,
        name: "Islanda",
        prefix: "+354"
    },
    {
        id: 21,
        name: "Irlanda",
        prefix: "+353"
    },
    {
        id: 23,
        name: "Kazakistan",
        prefix: "+7"
    },
    {
        id: 24,
        name: "Kosovo",
        prefix: "+383"
    },
    {
        id: 25,
        name: "Lettonia",
        prefix: "+371"
    },
    {
        id: 26,
        name: "Liechtenstein",
        prefix: "+423"
    },
    {
        id: 27,
        name: "Lituania",
        prefix: "+370"
    },
    {
        id: 28,
        name: "Lussemburgo",
        prefix: "+352"
    },
    {
        id: 29,
        name: "Macedonia del Nord",
        prefix: "+389"
    },
    {
        id: 30,
        name: "Malta",
        prefix: "+356"
    },
    {
        id: 31,
        name: "Moldavia",
        prefix: "+373"
    },
    {
        id: 32,
        name: "Monaco",
        prefix: "+377"
    },
    {
        id: 33,
        name: "Montenegro",
        prefix: "+382"
    },
    {
        id: 34,
        name: "Paesi Bassi",
        prefix: "+31"
    },
    {
        id: 35,
        name: "Norvegia",
        prefix: "+47"
    },
    {
        id: 36,
        name: "Polonia",
        prefix: "+48"
    },
    {
        id: 37,
        name: "Portogallo",
        prefix: "+351"
    },
    {
        id: 38,
        name: "Romania",
        prefix: "+40"
    },
    {
        id: 39,
        name: "Russia",
        prefix: "+7"
    },
    {
        id: 40,
        name: "San Marino",
        prefix: "+378"
    },
    {
        id: 41,
        name: "Serbia",
        prefix: "+381"
    },
    {
        id: 42,
        name: "Slovacchia",
        prefix: "+421"
    },
    {
        id: 43,
        name: "Slovenia",
        prefix: "+386"
    },
    {
        id: 44,
        name: "Spagna",
        prefix: "+34"
    },
    {
        id: 45,
        name: "Svezia",
        prefix: "+46"
    },
    {
        id: 46,
        name: "Svizzera",
        prefix: "+41"
    },
    {
        id: 47,
        name: "Turchia",
        prefix: "+90"
    },
    {
        id: 48,
        name: "Ucraina",
        prefix: "+380"
    },
    {
        id: 49,
        name: "Regno Unito",
        prefix: "+44"
    },
    {
        id: 50,
        name: "Città del Vaticano",
        prefix: "+39"
    }
];

export const listaFermate = [
    { id: 'Tolentino', name: <div style={{ display: 'flex', alignItems: 'center' }}>Tolentino <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /></div>, opzionale: true, ordine: 0, codice: 'TOL' },
    { id: 'Macerata', name: 'Macerata' , opzionale: false, ordine: 1, codice: 'MAC' },
    { id: 'Piediripa', name: 'Piediripa' , opzionale: false, ordine: 2, codice: 'PIE' },
    { id: 'Trodica di Morrovalle', name: 'Trodica di Morrovalle' , opzionale: false, ordine: 3, codice: 'TRO' },
    { id: 'Civitanova Marche', name: 'Civitanova Marche', opzionale: false, ordine: 4, codice: 'CVT' },
    { id: 'Loreto', name: <div style={{ display: 'flex', alignItems: 'center' }}>Loreto <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /></div>, opzionale: true, ordine: 5, codice: 'LOR' },
    { id: 'Ancona Sud', name: <div style={{ display: 'flex', alignItems: 'center' }}>Ancona Sud <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /></div>, opzionale: true, ordine: 6, codice: 'ANN' },
    { id: 'Ancona Nord', name: <div style={{ display: 'flex', alignItems: 'center' }}>Ancona Nord <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /></div>, opzionale: true, ordine: 7, codice: 'ANS' },
    { id: 'Porto Sant\'Elpidio', name: <div style={{ display: 'flex', alignItems: 'center' }}>Porto Sant'Elpidio <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /></div>, opzionale: true, ordine: 8, codice: 'PSE' },
    { id: 'Porto San Giorgio', name: <div style={{ display: 'flex', alignItems: 'center' }}>Porto San Giorgio <Warning color='warning' sx={{ ml: 1.3 }} fontSize='10' /></div>, opzionale: true, ordine: 9, codice: 'PSG' },
];

export const listaFermateOrdinate = listaFermate.sort((a, b) => {
    if (a.order > b.order)
      return -1;
      else
      return 1;
});

export const ListaRuoliUtenti = (props) => {
    const { data, isLoading, error } = useGetList(
        "utente_ruolo",
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' }
        },
    );
    if (isLoading) { return <p>Loading</p>; }
    if (error) { return <p>ERROR</p>; }
    if (props.richiesto === true) {
        return <SelectInput source="id_ruolo" label="Ruolo utente" choices={data} optionText="descr" optionValue="id" validate={[required()]} {...props} />;
    } else {
        return <SelectInput source="id_ruolo" label="Ruolo utente" choices={data} optionText="descr" optionValue="id" {...props} />;
    }
}

export const StatiRichiesta = ({ stato, className = null, sx = null }) => {
    const { data, isLoading, error } = useGetList(
        "stato_richiesta",
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' }
        },
    );
    if (isLoading) { return <p>Loading</p>; }
    if (error) { return <p>ERROR</p>; }
    // console.log(data);
    return <SelectInput source="id" label="Cambia stato richiesta" optionText="nome" optionValue="id" choices={data} onChange={(e) => { stato(e.target.value) }} sx={[{ minWidth: '200px' }, sx]} className={className} />;
}

export const MezziAssegnabili = ({ className = null, sx = null, updateVal = null, forceVal = null }) => {
    const [mezzo, setMezzo] = useState(null);
    const { data, isLoading, error } = useGetList(
        "mezzi_assegnabili",
        {
            pagination: { page: 1, perPage: 50 },
            sort: { field: 'id', order: 'ASC' }
        },
    );

    useEffect(() => {
        if (forceVal)
        setMezzo(forceVal);
    }, [forceVal]);

    useEffect(() => {
        // console.log(mezzo);
        updateVal(mezzo);
    }, [mezzo]);

    if (isLoading) { return <p>Loading</p>; }
    if (error) { return <p>ERROR</p>; }
    // console.log(data);
    return (
        <>
            <Typography component="p" variant="b" mb={3}>Seleziona il mezzo da assegnare</Typography>
            <SelectInput
                source="id"
                label="Seleziona mezzo"
                optionText="nome"
                optionValue="id"
                defaultValue={mezzo}
                choices={data}
                onChange={(e) => { setMezzo(e.target.value) }}
                sx={[{ minWidth: '200px' }, sx]}
                className={className}
            />
        </>
    );
}

export const HeaderSezione = ({ icona: Icona, titolo, sottotitolo = null, dettaglio = null, ...props }) => {

    switch (titolo) {
        case "Modifica camere":
        case "Camere":
            if (Number(dettaglio) === 1)
                dettaglio = `(${dettaglio} posto letto selezionato)`;
            else if (Number(dettaglio) > 1)
                dettaglio = `(${dettaglio} posti letto selezionati)`;
            else if (Number(dettaglio) === 0)
                dettaglio = `(Nessun posto letto selezionato)`;
            break;

        default:
            break;
    }
    return (
        <Grid container alignItems='center' mb={{ xs: 3, md: 4 }} {...props}>
            {Icona ?
                <Grid item sx={{ mr: 1 }} display='flex' alignItems='center'>
                    <Icona
                        sx={{ p: 0, m: 0 }}
                        color='primary'
                    />
                </Grid>
                : null}
            {props.tasto ?
                <Grid item sx={{ mr: 1 }} display='flex' alignItems='center'>
                    {props.tasto}
                </Grid>
                : null}
            <Grid item >
                <Typography component="h3" variant="h3" color={props.colore || 'secondary'}>{titolo}</Typography>
            </Grid>
            <Grid item sx={{ ml: { xs: 1, md: 2 } }}>
                <Typography variant="small">{(dettaglio ? dettaglio : null)}</Typography>
            </Grid>
        </Grid>
    )
}

export const logger = function (id_logs_operazioni, dettaglio, id_utente = null, id_viaggio = null, id_richiesta = null, id_operatore) {
    return dataProvider.create('logs', {
        data: {
            id_logs_operazioni,
            dettaglio,
            id_utente,
            id_viaggio,
            id_richiesta,
            id_operatore,
        }
    });
}

export const CalcoloPartecipanti = (id_viaggio) => {
    const { data: partecipanti, isLoading, error } = useGetOne('viaggio_n_partecipanti', { id: id_viaggio });
    if (isLoading)
        return 'Loading';
    if (error)
        return 'Errore';
    // console.log(id_viaggio, partecipanti);
    return partecipanti;
}

const themeBp = createTheme({
    ...defaultTheme,
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400,
        },
    },
    palette: {
        primary: {
            light: '#3B8EC6',
            main: '#0071bb',
            dark: '#122A3B',
            contrastText: '#fff',
        },
        secondary: {
            light: '#5b6ab1',
            main: '#38499b',
            dark: '#1c256d',
            contrastText: '#fff',
        },
        table: {
            evidenza: '#ececec'
        },
        error: red,
        warning: orange,
        black: grey[900],
        grigio: grey,
        giallo: yellow,
        success: green,
        contrastThreshold: 2,
        tonalOffset: 0.2,
    },
});

export const myTheme = {
    ...themeBp,
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        p: {
            display: 'block',
            lineHeight: 1.4,
            [themeBp.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
            [themeBp.breakpoints.up('sm')]: {
                fontSize: '0.875rem',
            }
        },
        h3: {
            fontWeight: 'bold',
            fontSize: '20px',
            [themeBp.breakpoints.down('sm')]: {
                textTransform: 'uppercase',
                fontSize: '18px',
            }
        },
        titoloSezione: {
            fontFamily: 'Patrick Hand',
            fontSize: 30,
            lineHeight: 1,
        },
        titoloSezione2: {
            fontFamily: 'Patrick Hand',
            fontSize: 25,
            lineHeight: 1,
        },
        Button: {
            fontSize: '22px'
        },
        messaggisticaTesto: {
            fontSize: '14px',
            marginBottom: 4,
            overflowWrap: 'anywhere',
        },
        messaggisticaData: {
            fontSize: '10px'
        },
        small: {
            fontSize: '80%',
            lineHeight: 0.8,
        },
        smallFooter: {
            fontSize: ".7rem",
            lineHeight: 0.7,
        },
        voceMenuMobile: {
            color: themeBp.palette.primary.main,
            display: 'block',
            fontSize: '120%',
            lineHeight: 2,
        },
    },
    components: {
        // ...defaultTheme.components,
        // RaLayout: {
        //     styleOverrides: {
        //         root: {
        //             backgroundImage: `url(${process.env.PUBLIC_URL}'/bg-pattern-grey.png')`,
        //             paddingLeft: 0,
        //             paddingRight: 0,
        //         }
        //     },
        //     "& .RaLayout-content": {
        //         paddingLeft: 0,
        //         paddingRight: 0,
        //     }
        // },
        RaTextInput: {
            defaultProps: {
                helperText: false,
            },
            // styleOverrides: {
            //     root: {
            //         backgroundColor: red[50]
            //     },
            // }
        },
        // SelectInput: {
        //     defaultProps: {
        //         helperText: false,
        //         variant: 'contained'
        //     },
        // },
        MuiModal: {
            variants: [
                {
                    props: { variant: 'customModalBox' },
                    style: {
                        '& > .MuiBox-root': {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            maxWidth: '75vw',
                            maxHeight: '65vh',
                            backgroundColor: '#fff',
                            overflowY: 'auto',
                            boxShadow: '14px',
                            padding: '2rem',
                            border: '2px solid #000',
                        }
                    },
                },
                {
                    props: { variant: 'customModalBoxSmall' },
                    style: {
                        '& > .MuiBox-root': {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '60vw',
                            maxWidth: '400px',
                            maxHeight: '65vh',
                            backgroundColor: '#fff',
                            overflowY: 'auto',
                            boxShadow: '14px',
                            padding: '2rem',
                            border: '2px solid #000',
                        }
                    },
                },
            ],
        },
        MuiSnackbar: {
            variants: [
                {
                    props: { variant: 'alertPostiLetto' },
                    style: {
                        zIndex: 99999,
                    },
                },
            ],
        },
        MuiGrid: {
            variants: [
                {
                    props: { variant: 'selectToolbar' },
                    style: {
                        '& .MuiSelect-select': {
                            padding: '3px',
                            fontSize: 13
                        },
                        '& .MuiFormControl-root': {
                            marginTop: '0 !important',
                            marginBottom: '0 !important',
                        }
                    },
                },
                {
                    props: { variant: 'bloccoSingoloRichiesta' },
                    style: {
                        [themeBp.breakpoints.only('xs')]: {
                            paddingTop: '50px !important',
                        },
                    },
                },
            ],
        },
        MuiButtonBase: {
            variants: [
                {
                    props: { variant: 'iconaLista' },
                    style: {
                        padding: '0 5px !important',
                        [themeBp.breakpoints.down('lg')]: {
                            padding: '3px !important',
                            "& svg": {
                                fontSize: '1.5rem'
                            }
                        }
                    },
                },
            ],
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none !important',
                    '&.loginSocial img': {
                        width: '30px'
                    },
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    // fontSize: "35px",
                    [themeBp.breakpoints.down('md')]: {
                        fontSize: '30px'
                    }
                }
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                size: 'small',
                variant: 'filled',
            }
        },
        MuiSelect: {
            defaultProps: {
                helperText: false,
                variant: 'outlined',
                size: 'small',
            },
        },
        RaAutocompleteInput: {
            size: 'small',
            styleOverrides: {
                root: {
                    minWidth: 'unset',
                    "& label:not(.MuiFormLabel-filled)": {
                        marginTop: '-5.5px',
                    },
                },
                textField: {
                    minWidth: 'unset'
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                helperText: false
            },
            styleOverrides: {
                root: {
                    "& .MuiFormHelperText-root:not(.Mui-error)": {
                        display: 'none'
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                        marginBottom: '20px'
                    },
                },
            }
        },
        MuiInputBase: {
            defaultProps: {
                size: 'small',
                helperText: false,
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '20px !important',
                    marginTop: '-16px',
                    // marginTop: '0px !important',
                    [themeBp.breakpoints.up('sm')]: {
                    },
                    [themeBp.breakpoints.down('sm')]: {
                    },
                    "& label:not(.MuiFormLabel-filled)": {
                        fontSize: "13px",
                        // lineHeight: 1.5
                    }
                }
            }
        },
        RaCheckboxGroupInput: {
            styleOverrides: {
                root: {
                    marginBottom: '0 !important',
                    "& .RaCheckboxGroupInput-label": {
                        display: 'none'
                    }
                }
            }
        },
        RaFilterFormInput: {
            styleOverrides: {
                root: {
                    [themeBp.breakpoints.down('sm')]: {
                        width: '100%',
                        "& .MuiTextField-root": {
                            width: '100%',
                        },
                        "& .RaFilterFormInput-spacer": {
                            display: 'none'
                        },
                        "& .MuiAutocomplete": {
                            width: '100%',
                        }
                    },
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        minWidth: 'unset !important',
                        '& .MuiButton-startIcon': {
                            marginLeft: '0 !important',
                            marginRight: '0 !important',
                        }
                    },
                },
            ],
            defaultProps: {
                variant: 'outlined',
                px: 20,
                size: 'small',
                helperText: false,
            },
            styleOverrides: {
                root: {
                    "& span": {
                        xs: { marginRight: '0 !important' },
                        md: { marginRight: '0 !important' },
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    // borderColor: myTheme.palette.secondary,
                }
            }
        },
        MuiCardContent: {
            variants: [
                {
                    props: { variant: 'boxViaggioUtente' },
                    style: {
                        position: 'relative',
                        overflow: 'hidden',
                        '& > svg': {
                            width: "50%",
                            aspectRatio: "1/1",
                            position: "absolute",
                            top: "-10%",
                            opacity: 0.05,
                            left: "25%",
                        }
                    },
                },
                {
                    props: { variant: 'boxViaggioUtenteV2' },
                    style: {
                        position: 'relative',
                        overflow: 'hidden',
                        '& h4': {
                            fontFamily: 'Patrick Hand',
                            fontSize: 30,
                            lineHeight: 1
                        },
                        '& > svg': {
                            width: "80%",
                            aspectRatio: "1/1",
                            position: "absolute",
                            top: 0,
                            opacity: 0.05,
                            left: "10%",
                        }
                    },
                },
            ],
        },
        MuiCardActionArea: {
            variants: [
                {
                    props: { variant: 'dashboardCardUtente' },
                    style: {
                        '& h6.MuiCardHeader-root .MuiTypography-root': {
                            [themeBp.breakpoints.down('sm')]: {
                                fontSize: '20px',
                                lineHeight: '20px',
                            }
                        },
                        '& .MuiCardContent-root p': {
                            fontSize: 13,
                            lineHeight: 1.2
                        },
                        '& .MuiCardMedia-img': {
                            [themeBp.breakpoints.down('sm')]: {
                                width: '70%'
                            }
                        },
                        '& .MuiButtonBase-root': {
                            [themeBp.breakpoints.down('sm')]: {
                                width: '100%'
                            }
                        },
                    },
                },
            ]
        },
        MuiCardHeader: {
            variants: [
                {
                    props: { variant: 'dashboardHeader' },
                    style: {
                        '& .MuiCardHeader-content .MuiTypography-root': {
                            color: `${themeBp.palette.primary.main} !important`,
                            lineHeight: 1,
                            fontFamily: 'Patrick Hand',
                            fontSize: "2rem",
                            [themeBp.breakpoints.up('md')]: {
                                fontWeight: 'bold'
                            }
                        }
                    }
                }
            ]
        },
        RaButton: {
            defaultProps: {
                variant: 'outlined',
                color: 'error',
                px: 20,
                helperText: false,
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: themeBp.palette.primary.dark,
                    opacity: '0.7 !important',
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    alignItems: 'center !important',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 9999,
                    "& .MuiFormControl-root": {
                        marginTop: '8px !important',
                        marginBottom: '4px !important',
                    },
                    [themeBp.breakpoints.down('sm')]: {
                        "&.RaToolbar-mobileToolbar": {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            position: 'sticky !important',
                            gap: '10px',
                            '& button': {
                                width: '100%',
                            }
                        },
                        "&.RaBulkActionsToolbar-topToolbar": {
                            backgroundColor: 'unset'
                        }
                    },
                },
            },
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    "& .MuiToolbar-root": {
                        top: 0,
                        zIndex:'9999 !important',
                    }
                }
            }
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    "& .RaLayout-content": {
                        paddingRight: "8px",
                        paddingLeft: "8px"
                    }
                }
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // invisible border when not active, to avoid position flashs
                    borderLeft: '3px solid transparent', 
                    '&.RaMenuItemLink-active': {
                        backgroundColor: themeBp.palette.secondary.main,
                        color: themeBp.palette.secondary.contrastText,
                        fontWeight: 'bold',
                        '& .RaMenuItemLink-icon': {
                            color: themeBp.palette.secondary.contrastText,
                        },
                    },
                },
            },
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'faq' },
                    style: {
                        '&.MuiPaper-root::before': {
                            opacity: 0
                        },
                        '&.MuiPaper-root': {
                            borderRadius: "10px",
                            backgroundColor: themeBp.palette.grigio[100],
                            color: themeBp.palette.black,
                            borderWidth: 1,
                            borderColor: themeBp.palette.grigio[300],
                            borderStyle: "solid",
                            '& .RaMenuItemLink-icon': {
                                color: themeBp.palette.secondary.contrastText,
                            },
                            "& .MuiAccordionSummary-content": {
                                display: 'flex',
                                alignItems: 'center'
                            }
                        },
                    },
                },
            ],
        }
    },
};

export const themeAttesa = {
    ...myTheme,
    typography: {
        ...myTheme.typography,
    },
    components: {
        ...myTheme.components,
        MuiDrawer: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },
        RaLayout: {
            styleOverrides: {
                root: {
                    marginTop: 0
                }
            }
        },
    },
};

export const ResponsiveBreakpoint = () => {
    // const test = useMediaQuery(window.innerWidth);
    // console.log(test)
    // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const xl = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const md = useMediaQuery(theme => theme.breakpoints.up('md'));
    const sm = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const xs = useMediaQuery(theme => theme.breakpoints.up('xs'));
    var bp;
    if (xs)
        bp = 'xs';
    else if (sm)
        bp = 'sm';
    else if (md)
        bp = 'md';
    else if (lg)
        bp = 'lg';
    else
        bp = 'xl';
    // console.log(bp);
    return bp;
}

export const checkRedirectAlViaggio = (idViaggio = null) => {
    // console.log(idViaggio);
    return new Promise((resolve, reject) => {
        if (sessionStorage.getItem("vid")
            // && idViaggio
        ) {
            var action = null;
            if (sessionStorage.getItem("vid") !== idViaggio) {
                //     console.log('fare redirect');
                action = 'redirect';
                //     // window.location.href = '/#/richiesta/create/' + sessionStorage.getItem("vid");
            } else {
                //     // redirect('/richiesta/create/' + sessionStorage.getItem("vid"))
                //     // console.log(window.location.href);
                //     // window.location.reload();
                //     console.log('NON fare redirect');
                action = 'refresh';
            }
            resolve({action: action})
        } else {
            reject({action:'rejected'})
        }
    });
}

export const AlertWhatsapp = ({ icona: Icona, variante }) => {
    if (variante === 'testo') return 'Chiediamo il tuo numero di cellulare solo per inviarti le indicazioni utili alla partenza o variazioni importanti da comunicare tempestivamente.';
    return (
        <Alert severity="info" sx={{ mb: { xs: 3, md: 4 } }} icon={false}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item display='flex' flexDirection='row' alignItems='center' mb={2}>
                    {Icona ?
                    <Icona sx={{ p: 0, mr: 1.5 }} color='primary' fontSize='large' />
                    : null}
                    <Typography component="h3" variant="h3" color='secondary'>Perché chiediamo il numero di Cellulare?</Typography>
                </Grid>
            </Grid>
            <Grid container alignItems='center' spacing={2}>
                <Grid item>
                    <Typography component="p" variant="p">Useremo il tuo numero solo per inviarti le indicazioni utili alla partenza o variazioni importanti da comunicare tempestivamente.<br /><u>Non lo useremo</u> per inviarti messaggi pubblicitari.</Typography>
                </Grid>
            </Grid>
        </Alert>
    )
}

export const AlertFermata = ({ icona: Icona, variante }) => {
    if (variante === 'testo') return 'Le fermate che sono segnalate dall\'icona del warning sono da definire, in fase di conferma del viaggio, e potrebbero NON essere confermate. Riceverete tempestive comunicazioni in caso la fermata principale selezionata sia una di quelle.';
    return (
        <Alert severity="info" sx={{ mb: { xs: 3, md: 4 } }} icon={false}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item display='flex' flexDirection='row' alignItems='center' mb={2}>
                    {Icona ?
                    <Icona sx={{ p: 0, mr: 1.5 }} color='primary' fontSize='large' />
                    : null}
                    <Typography component="h3" variant="h3" color='secondary'>Perché alcune fermate sono segnalate diversamente?</Typography>
                </Grid>
            </Grid>
            <Grid container alignItems='center' spacing={2}>
                <Grid item>
                    <Typography component="p" variant="p">Le fermate che sono segnalate con questa icona <Warning sx={{ mx: 0.5 }} fontSize='10px' /> sono da definire, in fase di conferma del viaggio, e <u>potrebbero NON essere confermate</u>. Riceverete tempestive comunicazioni in caso la fermata principale selezionata sia una di quelle.</Typography>
                </Grid>
            </Grid>
        </Alert>
    )
}

export const AlertModuloMinorenni = ({versione = 1}) => {
    const titolo = "Compilare il modulo per minorenni NON accompagnati";
    const testo = "Dalle informazioni inserite risultano partecipanti minorenni. Per ciascuno di loro sarà necessario compilare, firmare e caricare il modulo allegato (solo nel caso non siano accompagnati da un genitore o di chi ne fa le veci).";
    const linkPdf = `${process.env.PUBLIC_URL}/docs/autorizzazione-minorenni.pdf`;
    const titoloFile = "Modulo Autorizzazione minorenni";
    const titoloBottone = "Scarica il modulo";
    
    return (
        (versione === 2 ?
        <Alert severity="error" sx={{ mb: 2 }} icon={false}>
            <Grid container>
                <Grid item xs={12} flexDirection='row' display='flex' justifyContent='center' alignItems='center'>
                    <Typography component="h3" variant="p">{titolo}</Typography>
                    <Button
                        href={linkPdf}
                        sx={{ mt: 0, ml: 2, textAlign: 'center' }}
                        color="secondary"
                        download={titoloFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {titoloBottone}
                    </Button>
                </Grid>
            </Grid>
        </Alert>
        :
        <Alert severity="error" sx={{ mb: 2 }} icon={false}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                {/* flexDirection='row' display='flex' justifyContent='center' */}
                    <Typography component="h3" variant="p">{titolo}</Typography>
                    <Typography variant="p">{testo}</Typography>
                    <Button
                        href={linkPdf}
                        sx={{ mt: 1 }}
                        color="secondary"
                        download={titoloFile}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {titoloBottone}
                    </Button>
                </Grid>
            </Grid>
        </Alert>
        )
    )
}

export const Footer = ({...props}) => {
    return (
        <Box sx={{ mt: 3, position: "relative" }}>
            <Box sx={[{ textAlign: "center", px: 2, py:2, backgroundColor: themeBp.palette.grigio[200], borderTop: `3px solid ${themeBp.palette.secondary.main}` }, props.sx]}>
                <Typography variant='smallFooter'>Il sistema "Prenota con Virgilio Viaggia" utilizza solo cookies (e similari) di tipo tecnico, essenziali al corretto funzionamento del sito.<br />Leggi la nostra "<Link href={`${process.env.PUBLIC_URL}/docs/informativa-privacy.pdf`} target="_blank" sx={{ color: themeBp.palette.grigio[900], fontWeight: "bold", textDecorationColor: themeBp.palette.grigio[900] }}>Informativa Privacy e Cookies</Link>" completa.</Typography>
            </Box>
        </Box>
    )
}

export function divideString(str, maxLength = 30) {
    var words = str.split(' ');
    var currentLine = '';

    for (var i = 0; i < words.length; i++) {
        var word = words[i];
        if ((currentLine + word).length <= maxLength) {
            currentLine += (currentLine === '' ? '' : ' ') + word;
        } else {
            break;
        }
    }

    return currentLine;
}

export function copyToClipboard(text = null) {
    return new Promise( (resolve, reject) => {
        // text = null;
        if (text) {
            navigator.clipboard.writeText(text);
            resolve(text);
        } else {
            reject("Non è stato possibile copiare");
        }
    });
}

export function pasteFromClipboard() {
    return new Promise( (resolve, reject) => {
        resolve(navigator.clipboard.readText());
    });
}