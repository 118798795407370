import React from "react";

function IconaPrimavera() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g fillRule="nonzero">
        <path d="M722.3 871.532c64.785-52.895 93.127-115.485 103.216-143.306l-126.332 29.147c-117.696 27.158-127.375 100.185-128.007 117.064 14.148 11.716 77.67 57.064 151.123-2.905M529.179 621.698c-9.679 8.952-17.085 13.563-17.464 13.8a9.091 9.091 0 01-4.737 1.326h-.442a646.419 646.419 0 01-4.942-.284v237.27c.095 2.606.032 4.358 0 5.069v170.322h48.743V636.682c-1.279-.158-2.527-.505-3.632-1.168-.379-.237-7.832-4.848-17.526-13.816M355.417 757.378L229.116 728.23c10.137 27.885 38.574 90.569 103.169 143.307 73.422 59.937 136.928 14.653 151.107 2.905v-.284c-.805-20.827-12.458-90.143-127.975-116.78M458.664 92.926c6.126-5.574 15.726-13.342 28.026-20.258-19.595-15.995-45.774-14.211-63.821-10.09 16.484 9.853 28.784 22.264 35.795 30.348M570.626 61.182c.016 0 .032 0 .032.016h.032c13.421 3.995 26.589 11.069 39.268 21.032 7.974-7.611 18.79-16.437 31.848-23.242.016-.016.032-.016.047-.016l.032-.032c.916-.458 1.721-.821 2.574-1.247-5.1-13.974-12.664-22.374-23.022-25.485-19.61-5.873-48.316 7.406-73.563 25.011 7.642.426 15.252 1.705 22.752 3.963zM529.175 229.615c14.985-47.464 36.569-100.327 64.327-129.017.648-.9 2.021-2.684 3.964-5.021-10.485-8.037-21.206-13.753-32.006-16.958h-.032c-14.873-4.469-30.221-4.532-45.616-.205h-.047c-.016.015-.032.015-.048.031-4.452 1.2-9.079 2.858-14.115 5.069-1.8.789-3.585 1.626-5.274 2.463-13.406 6.789-23.842 15.3-29.874 20.826 25.058 29.637 44.748 78.554 58.721 122.812M650.189 75.136c-14.448 7.547-25.927 18.095-33.016 25.626h-.016v.016c-5.89 6.205-9.206 10.879-9.237 10.926-.284.395-.6.774-.932 1.121-24.489 24.995-48.095 76.454-68.369 148.849 26.716 97.185 41.337 207.839 39.537 255.255-1.61 41.7-19.484 72.064-36.363 91.674 5.242 4.816 9.663 8.148 12.158 9.885 1.61-.095 3.205-.206 4.784-.316 113.938-8.274 153.38-81.048 155.007-84.143 93.537-166.249-5.811-429.367-21.411-468.241-11.021-.837-22.216.963-33.411 5.369h-.031c-2.606 1.026-5.369 2.289-8.7 3.979"></path>
        <path d="M520.411 264.061v-.015c-20.448-73.738-44.306-126.017-69.033-151.217-.047-.048-.063-.111-.11-.158-.095-.111-.19-.237-.3-.348-.142-.173-.3-.331-.442-.521a.673.673 0 01-.095-.11c-.221-.3-22.09-30.869-55.074-42.032-9.663-3.332-19.484-4.627-29.337-3.869-15.6 38.906-114.901 301.977-21.553 467.989 1.705 3.236 39.695 73.421 148.959 83.874 3.616.379 7.263.632 11.006.837 3.41-2.384 10.373-7.674 18.189-15.79v-.016c16.169-16.8 35.779-45.426 37.358-86.448 1.769-46.642-13.721-159.112-39.568-252.176"></path>
      </g>
    </svg>
  );
}

export default IconaPrimavera;