import * as React from "react";
import { Create, SimpleForm, TextInput, NumberInput, required, AutocompleteInput } from 'react-admin';
import { Grid } from '@mui/material';
import { HeaderSezione, listaStati } from '../generale';
import { Support } from '@mui/icons-material';

export const AccompagnatoreNuovo = () => {
    const optionRenderer = listaStati => `${listaStati.name} ${listaStati.prefix}`;
    const TitoloSezione = () => {
        return <span>Crea accompagnatore</span>;
    };

    const transform = data => ({
        ...data,
        telefono: `${data.prefisso} ${data.telefono}`
    });

    return (
        <Create
            resource="accompagnatore"
            title={<TitoloSezione />}
            redirect="list"
            // transform={transform}
        >
            <SimpleForm>
                <HeaderSezione icona={Support} titolo="Crea un nuovo accompagnatore" />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextInput source="nominativo" fullWidth validate={[required()]} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <AutocompleteInput
                            source="prefisso"
                            fullWidth
                            validate={[required()]}
                            choices={listaStati}
                            optionText={optionRenderer}
                            optionValue="prefix"
                            defaultValue="+39"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <NumberInput source="telefono" fullWidth validate={[required()]} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};