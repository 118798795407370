import React from "react";

function IconaMontagna() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g fillRule="nonzero">
        <path d="M594.883 956.472a18.04 18.04 0 01-7.584-1.686l-141.305-66.51a17.75 17.75 0 01-9.445-10.939c-1.494-4.786-.842-10.001 1.749-14.326l68.466-114.116-203.633-250.427c-4.261-5.279-6.217-12.164-5.342-18.905.906-6.742 4.563-12.911 10.065-16.902L688.471 187.35c7.95-5.804 19.081-3.991 24.868 3.975 5.74 7.966 3.943 19.096-4.007 24.852L337.953 484.842l196.478 241.809c9.365 11.544 10.494 27.873 2.862 40.593l-58.322 97.198 107.755 50.705 85.591-171.085c5.835-11.703 4.309-25.79-3.912-35.935L547.819 559.175c-5.74-7.108-5.2-17.363 1.24-23.803l91.458-91.441c3.847-3.88 9.269-5.725 14.739-5.104 5.422.651 10.24 3.8 13.086 8.49l31.832 52.725 148.968 77.482c9.906 5.119 21.179 5.994 31.737 2.321 10.542-3.657 18.905-11.273 23.516-21.433 8.268-18.365 1.59-40.212-15.55-50.801l-112.096-69.198a17.608 17.608 0 01-7.33-8.968l-53.901-145.502-9.143-19.923c-4.086-8.936-.159-19.477 8.777-23.564 8.92-4.102 19.509-.159 23.596 8.793l9.635 21.115 52.264 140.955 106.913 65.986c32.325 19.986 44.902 61.152 29.272 95.767-8.682 19.191-24.391 33.533-44.282 40.418-19.86 6.869-41.102 5.326-59.801-4.389L679.313 529.33c-2.91-1.51-5.343-3.816-7.06-6.598l-22.657-37.572-64.03 64.045 110.49 136.519c16.981 20.972 20.177 50.117 8.093 74.27l-93.334 186.651c-3.116 6.202-9.397 9.827-15.932 9.827"></path>
        <path d="M776.571 1049.2c-2.64 0-5.215-.54-7.616-1.685L96.84 730.323c-8.889-4.182-12.689-14.803-8.507-23.707 4.198-8.872 14.803-12.688 23.707-8.491l667.266 314.903 88.516-12.959c9.699-1.447 18.778 5.311 20.177 15.026 1.431 9.746-5.279 18.778-15.041 20.193l-93.827 13.738c-.843.111-1.717.174-2.56.174M834.783 608.266a17.72 17.72 0 01-7.934-1.876L186.025 285.986c-8.793-4.404-12.338-15.089-7.966-23.882 4.436-8.777 15.089-12.37 23.882-7.95l640.824 320.388c8.793 4.405 12.338 15.105 7.966 23.882a17.836 17.836 0 01-15.948 9.842"></path>
        <path d="M230.283 388.833c-2.751 0-5.533-.652-8.157-1.987-8.713-4.516-12.147-15.248-7.648-23.978l70.486-136.582c4.515-8.713 15.2-12.148 23.993-7.632 8.729 4.5 12.148 15.232 7.648 23.977l-70.469 136.567a17.845 17.845 0 01-15.853 9.635M838.96 339.548c-85.129 0-154.375-69.246-154.375-154.375S753.831 30.799 838.96 30.799s154.406 69.245 154.406 154.374c0 85.129-69.277 154.375-154.406 154.375m0-273.165c-65.509 0-118.79 53.297-118.79 118.79 0 65.493 53.297 118.79 118.79 118.79 65.493 0 118.79-53.297 118.79-118.79 0-65.493-53.297-118.79-118.79-118.79"></path>
      </g>
    </svg>
  );
}

export default IconaMontagna;