import React from "react";

function IconaPubblicato({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 2250 1257"
      preserveAspectRatio="xMidYMin slice"
      {...props}
    >
      <path fill="none" d="M0 0H2250V1256.25H0z"></path>
      <path
        fill="gray"
        d="M2083.36 909.693h-187.337c-16.64-82.047-88.786-140.99-172.483-140.99-83.725 0-155.843 58.943-172.539 140.99H699.008c-16.668-82.047-88.785-140.99-172.511-140.99-83.725 0-155.842 58.943-172.483 140.99H183.263c-24.286-.027-44.008-19.721-44.035-44.035V343.944c.082-76.436 61.969-138.295 138.377-138.405h1560.66c40.982.11 80.479 14.99 111.312 41.945l-228.235 28.495H350.492c-19.446 0-35.233 15.733-35.233 35.179v211.266c0 19.446 15.787 35.178 35.233 35.178h1371.53l388.782 32.429v292.212c-.027 15.155-12.322 27.45-27.449 27.45z"
      ></path>
      <path fill="gray" d="M335.781 275.979H688.598V557.575H335.781z"></path>
      <path
        fillRule="nonzero"
        d="M2165.37 515.355l-108.837-239.898c-39.194-85.266-124.377-140.028-218.279-140.303H277.594c-115.245.138-208.68 93.489-208.79 208.79v521.714c.083 63.178 51.297 114.365 114.448 114.42h170.751c16.641 82.102 88.758 141.018 172.483 141.018 83.726 0 155.843-58.916 172.511-141.018h851.993c16.696 82.102 88.814 141.018 172.539 141.018 83.697 0 155.843-58.916 172.483-141.018h187.337c53.992-.027 97.78-43.788 97.835-97.835v-294c.027-25.14-5.391-50.004-15.816-72.888m-76.298 2.338l-330.362-27.67V342.156l237.395-29.678 92.967 205.215zM526.49 1050.491c-58.337 0-105.618-47.281-105.618-105.619 0-58.311 47.281-105.619 105.618-105.619 58.338 0 105.619 47.308 105.619 105.619 0 58.338-47.281 105.619-105.619 105.619m1197.04 0c-58.366 0-105.619-47.281-105.619-105.619 0-58.311 47.253-105.619 105.619-105.619 58.338 0 105.591 47.308 105.591 105.619 0 58.338-47.253 105.619-105.591 105.619m359.82-140.798h-187.337c-16.64-82.047-88.786-140.99-172.483-140.99-83.725 0-155.843 58.943-172.539 140.99H698.998c-16.668-82.047-88.785-140.99-172.511-140.99-83.725 0-155.842 58.943-172.483 140.99H183.253c-24.286-.027-44.008-19.721-44.035-44.035V343.944c.082-76.436 61.969-138.295 138.377-138.405h1560.66c40.982.11 80.479 14.99 111.312 41.945l-228.235 28.495H350.482c-19.446 0-35.233 15.733-35.233 35.179v211.266c0 19.446 15.787 35.178 35.233 35.178h1371.53l388.782 32.429v292.212c-.027 15.155-12.322 27.45-27.449 27.45M385.655 346.364h253.046V487.19H385.655V346.364zm323.458 0h275.05V487.19h-275.05V346.364zm345.462 0h266.248V487.19h-266.248V346.364zm336.661 0h297.081V487.19h-297.081V346.364z"
      ></path>
    </svg>
  );
}

export default IconaPubblicato;