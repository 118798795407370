import { useEffect, useState, memo } from 'react';
import { useLogin, useNotify, useRedirect, useRefresh, useUpdate } from 'react-admin';
import { Grid, Button, Typography, Link } from '@mui/material';
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
// import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { generatePassword } from './components/PasswordGenerator';
import { LoadingComponent } from "./components/LoadingComponent";
// import GoogleIcon from '@mui/icons-material/Google';
import { apiFullUrl, apiGuestFullUrl, checkRedirectAlViaggio } from "./components/generale";
import axios from 'axios';
import bcrypt from 'bcryptjs';
import moment from 'moment';

const GoogleButton = () => {
    const settings = {
        colore1: "#ffffff",
        colore2: "#4285F4",
    }

    return (
        <Button sx={{ py: 0.7, px: 2.5, borderColor:settings.colore2, backgroundColor: '#fff', mb: {xs: 1, sm: 0}, '&:hover': {backgroundColor: `${settings.colore1}88 !important`} }} color='secondary' fullWidth={{xs: true, sm: false}} variant='outlined' className='loginSocial'>
            <img src={process.env.PUBLIC_URL + '/icon-google.svg'} />
            <Typography variant='p' ml={2}>Accedi con Google</Typography>
        </Button>
    )
}

const FacebookButton = () => {
    const settings = {
        colore1: "#1877F2"
    }

    return (
        <Button sx={{ py: 0.7, px: 2.5, borderColor:settings.colore1, backgroundColor: settings.colore1, '&:hover': {backgroundColor: `${settings.colore1}cc !important`} }} color='secondary' fullWidth={{xs: true, sm: false}} variant='outlined' className='loginSocial'>
            <img src={process.env.PUBLIC_URL + '/icon-facebook.svg'} />
            <Typography variant='p' color='#fff' ml={2}>Accedi con Facebook</Typography>
        </Button>
    )
}

const LoginSocialGoogleMemo = memo(LoginSocialGoogle);

const LoginSocial = ({ tipo, idViaggio, statoLogin }) => {
    const G_CLIENT_ID = "866327779349-j4b25jgjdcbfuceag6atl78jqfhd0r0n.apps.googleusercontent.com";
    const FB_APP_ID = "860460088540017";
    const login = useLogin();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [update] = useUpdate();

    useEffect(() => {
        statoLogin(loadingLogin);
    }, [loadingLogin]);

    useEffect(() => {
        // if (profile && password) {
        if (profile) {
            const pwd = generatePassword();
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('username', profile.email);
            bodyFormData.append('password', pwd);
            axios.post(apiFullUrl + '/register', bodyFormData)
            .then(async response2 => {
                // console.log(response);
                bodyFormData.delete('username');
                bodyFormData.delete('password');
                bodyFormData.append('nome', profile.given_name);
                bodyFormData.append('cognome', profile.family_name);
                bodyFormData.append('g_id', profile.sub);
                bodyFormData.append('privacy_1', 1);
                bodyFormData.append('ultimo_login', moment().format('YYYY-MM-DD H:m:s'));
                await axios.put(apiFullUrl + '/records/utente/' + response2.data.id, bodyFormData, { headers: { 'X-API-Key': response2.data.api_key } })
                    .then(async () => {
                        const logData = {
                            id_logs_operazioni: 4,
                            id_utente: response2.data.id,
                            dettaglio: 'Google'
                        };
                        await axios.post(apiFullUrl + '/records/logs/', logData, {
                            headers: { 'X-API-Key': response2.data.api_key, 'Content-Type': 'application/json' }
                        });
                        login({ username: profile.email, password: pwd })
                            .then(() => {
                                checkRedirectAlViaggio(idViaggio)
                                .then((res) => {
                                    if (res.action === 'redirect')
                                        redirect('/richiesta/create/' + sessionStorage.getItem("vid"))
                                    else if (res.action === 'refresh')
                                        refresh();
                                    else
                                        redirect('/utente/' + response2.data.id)
                                });
                            })
                            .catch(() =>
                                notify('Errore nel login')
                            );
                    })
            }).catch(function (error) {
                // console.log(error);
                if (error.response.data.code === 1020) {
                    // se l'indirizzo email esiste già verificare che coincida anche g_id e quindi procedere al login piuttosto che alla registrazione di un nuovo utente
                    bodyFormData.delete('username');
                    bodyFormData.delete('password');
                    bodyFormData.append('nome', profile.given_name);
                    bodyFormData.append('cognome', profile.family_name);
                    bodyFormData.append('password', bcrypt.hashSync(pwd));
                    bodyFormData.append('g_id', profile.sub);
                    bodyFormData.append('ultimo_login', moment().format('YYYY-MM-DD H:m:s'));
                    axios.put(apiGuestFullUrl + '/updategoogleuser', bodyFormData)
                        .then(() => {
                            login({ username: profile.email, password: pwd })
                                .then(() => {
                                    checkRedirectAlViaggio(idViaggio)
                                    .then((res) => {
                                        // console.log(res);
                                        if (res.action === 'redirect')
                                            redirect('/richiesta/create/' + sessionStorage.getItem("vid"))
                                        else if (res.action === 'refresh')
                                            refresh();
                                        else
                                            redirect('/dashboard');
                                    })
                                    // .catch(() =>
                                    // );
                                })
                                .catch(() =>
                                    notify('Impossibile effettuare il login', { type: 'error' })
                                );
                        });

                } else {
                    notify('Errore nella procedura di registrazione. Verificare i dati inseriti e riprovare. Se il problema persiste contattaci.', { type: 'warning' });
                }
            });
        }
    }, [profile])

    return (
        (profile 
            // || loadingLogin
        ) ? <LoadingComponent testo='Stiamo verificando le credenziali, attendere...' />
            :
            <>
                <Grid display='flex' flexDirection={{ xs: 'column', sm: 'row' }} justifyContent='space-around'>

                    <LoginSocialGoogleMemo
                        // isOnlyGetToken
                        // isOnlyGetCode={true}
                        // onLoginStart={() => console.log('Google login start')}
                        // scope="https://www.googleapis.com/auth/userinfo.email"
                        scope="email profile"
                        client_id={G_CLIENT_ID || ''}
                        onResolve={({ provider, data }) => {
                            // console.log('Accesso ok con ' + provider);
                            // console.log(data);
                            setProvider(provider);
                            setProfile(data);
                            setLoadingLogin(true);
                        }}
                        onReject={(err) => {
                            console.log(err);
                            setLoadingLogin(false);
                        }}
                    >
                        {/* <GoogleLoginButton style={{ height: 'unset', fontSize: '15px', padding: '7px 15px' }} /> */}
                        <GoogleButton />
                    </LoginSocialGoogleMemo>

                    {/*
                    <LoginSocialFacebook
                        isOnlyGetToken
                        appId={FB_APP_ID || ''}
                        // onLoginStart={() => console.log('Facebook login start')}
                        onResolve={({ provider, data }) => {
                            // console.log(provider);
                            // console.log(data);
                            setProvider(provider);
                            setProfile(data);
                            setLoadingLogin(true);
                        }}
                        onReject={(err) => {
                            console.log(err);
                            setLoadingLogin(false);
                        }}
                    >
                        <FacebookButton />
                    </LoginSocialFacebook>
                    */}

                </Grid>
                <Grid container mt={4}>
                    <Grid item>
                        <Typography component='p' variant='small' sx={{ lineHeight: 1.5}}>Utilizzando la funzionalità "Accedi con Google" dichiari di accettare i <Link href={`${process.env.PUBLIC_URL}/docs/termini-e-condizioni.pdf`} target="_blank">termini di utilizzo del servizio</Link>, di aver letto la nostra <Link href={`${process.env.PUBLIC_URL}/docs/informativa-privacy.pdf`} target="_blank">informativa sul trattamento dei dati personali</Link> e di accettarla.</Typography>
                    </Grid>
                </Grid>
            </>
    )
}

export default LoginSocial;