import { useState, useEffect, forwardRef, useRef } from "react";
import { ListBase, useRecordContext, TextField, ReferenceField, Datagrid, WithRecord, Button, useListContext, ListContextProvider, useUpdateMany, useRefresh, usePermissions } from 'react-admin';
import { admB64 } from "../generale";
import { Card, Grid, Typography, Box } from '@mui/material';
import moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { encode as base64_encode } from 'base-64';

const CustomDatagrid = () => {
    const { data, isLoading, total } = useListContext();
    const { permissions } = usePermissions();
    const refUltimoElemento = useRef(null);
    const [fineLista, setFineLista] = useState(false);
    
    // useEffect(() => {
    //     console.log(total);
    //     console.log(refUltimoElemento);
    // }, [total]);
    
    // useEffect(() => {
    //     if (data)
    //     console.log(data);
    // }, [data]);

    useEffect(() => {
        if (refUltimoElemento && refUltimoElemento.current && fineLista) {
            refUltimoElemento.current?.scrollIntoView()
            setFineLista(false)
        }
    }, [refUltimoElemento, fineLista]);

    if (isLoading) return null;
    // console.log(`Risultati totali: ${total}`);

    return (
        total === 0 && permissions !== base64_encode(admB64) ? 
        <Grid container>
            <Grid item px={1}>
                <Typography variant="p" component="small" sx={{ mt: 0, mb: 1, lineHeight: 1.2, textAlign: 'center' }} width='100%'>Segnalaci eventuali variazioni rispetto ai dati precedentemente inseriti</Typography>
            </Grid>
        </Grid>
        :
        <>
        {data.map((v,i) => {
            return (i === (total-1) ? 
            <BloccoSingoloMessaggio key={`message_${i}`} record={v} fineLista={setFineLista} ref={refUltimoElemento} />
            :
            <BloccoSingoloMessaggio key={`message_${i}`} record={v} />
            )
        })}
        {/* <Grid ref={refUltimoElemento}></Grid> */}
        </>
    )
}

// const BloccoSingoloMessaggio = () => {
//     const idUtente = localStorage.getItem('auth_uid');
//     const record = useRecordContext();
//     const { permissions } = usePermissions();
//     const mostraNotifica = (parseInt(idUtente) !== record.id_utente && record.stato === false ? true : false);
//     const disposizione = {
//         allineamento: (parseInt(idUtente) === record.id_utente ? 'right' : 'left'),
//         ordine: (parseInt(idUtente) === record.id_utente ? 1 : 0)
//     };

//     return (
//         <Card sx={{ px: 2, py: 0.5, float: disposizione.allineamento, maxWidth: '80%', textAlign: disposizione.allineamento }} elevation={4}>
//             <Grid container>
//                 {mostraNotifica ?
//                 <Grid item order={disposizione.ordine}>
//                     <CircleIcon color="warning" fontSize="5px" sx={(disposizione.allineamento === 'left' ? {mr: 1} : {ml:1} )} />
//                 </Grid>
//                 :null }
//                 <Grid item order={!disposizione.ordine}>
//                     <TextField source="messaggio" sx={{ overflowWrap: 'anywhere' }} />
//                     <div>
//                         <Typography variant="p" component="small" color="text.secondary">Inviato il {moment(record.creazione).format("DD/MM/YYYY, HH:mm:ss")}</Typography>
//                         {permissions === base64_encode(admB64) ?
//                         <ReferenceField source="id_utente" reference="utente" link={false}>
//                             <WithRecord render={record =>
//                                 <Typography variant="p" component="small" color="text.secondary"> da {record.nominativo}</Typography>
//                             } />
//                         </ReferenceField>
//                         :null}
//                     </div>
//                 </Grid>
//             </Grid>
//         </Card>
//     )
// }

const BloccoSingoloMessaggio = forwardRef(({record, fineLista = null}, ref) => {
    const idUtente = localStorage.getItem('auth_uid');
    const { permissions } = usePermissions();
    const mostraNotifica = (permissions === base64_encode(admB64) && parseInt(idUtente) !== record.id_utente && record.stato === false ? true : false);
    const disposizione = {
        allineamentoFlex: (parseInt(idUtente) === record.id_utente ? 'end' : 'start'),
        allineamentoTesto: (parseInt(idUtente) === record.id_utente ? 'right' : 'left'),
        ordine: (parseInt(idUtente) === record.id_utente ? 1 : 0)
    };

    if (fineLista)
    fineLista(true)

    return (
        <Grid container justifyContent={disposizione.allineamentoFlex} px={2} ref={ref}>
            <Grid item justifyContent={disposizione.allineamentoFlex} display='flex'>
                <Card sx={{ mb: 1, px: 1, py: 0.5, maxWidth: '80%', minWidth: '170px', textAlign: disposizione.allineamentoTesto }} elevation={2}>
                    <Grid container justifyContent={disposizione.allineamentoFlex} flexDirection='row'>
                        {mostraNotifica ?
                        <Grid item xs={1} order={disposizione.ordine} display='flex' justifyContent='start' alignItems='center'>
                            <CircleIcon color="warning" sx={[(disposizione.allineamentoTesto === 'left' ? {mr: 1} : {ml:1} ), { fontSize:7}]} />
                        </Grid>
                        : null }
                        <Grid item xs order={!disposizione.ordine} flexDirection='column' display='flex'>
                            <Typography variant="messaggisticaTesto">{record.messaggio}</Typography>
                            <Typography variant="messaggisticaData" component="small" color="text.secondary">Inviato il {moment(record.creazione).format("DD/MM/YYYY, HH:mm:ss")}</Typography>
                            <ReferenceField source="id_utente" reference="utente">
                                <TextField source="nome" />
                                <TextField source="cognome" />
                            </ReferenceField>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
});

const TastoLeggiTutti = () => {
    const idUtente = parseInt(localStorage.getItem('auth_uid'));
    const { data, isLoading } = useListContext();
    const [updateMany, { isLoadingUpdate, errorUpdate }] = useUpdateMany();
    const [messaggiDaSegnare, setMessaggiDaSegnare] = useState(null);
    const refresh = useRefresh();

    function segnaLetti () {
        const arrayIdMessaggi = messaggiDaSegnare.map((v) => { return v.id });
        const stati = messaggiDaSegnare.map(() => { return {stato: 1} });
        // console.log(arrayIdMessaggi, stati);
        updateMany(
            "richiesta_messaggio",
            { ids: arrayIdMessaggi, data: stati },
            {
                onSuccess: () => {
                    refresh();
                },
                // onError: (error) => {
                //     // failure side effects go here 
                //     notify(`Comment approval error: ${error.message}`, { type: 'error' });
                // },
            }
        );
    }

    useEffect(() => {
        if (data) {
            setMessaggiDaSegnare(data.filter((v) => {
                // console.log(v.id, v.id_utente !== idUtente, v.stato)
                if (v.id_utente !== idUtente && v.stato === false) {
                    return true
                }
            }))
        }
    }, [data]);

    useEffect(() => {
        // console.log(messaggiDaSegnare);
        if (messaggiDaSegnare && messaggiDaSegnare.length > 0) {
            segnaLetti();
            // console.log(messaggiDaSegnare, 'da segnare come letti');
        }
    }, [messaggiDaSegnare]);

    if (isLoading) return null;

    return (
        null
        // <ListContextProvider value={{ data }}>
        //     {messaggiDaSegnare && messaggiDaSegnare.length > 0 ?
        //     <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
        //         <Button label="Segna tutti come letti" variant="outlined" icon={<MarkEmailReadIcon />} fullWidth sx={{m:1}} onClick={() => segnaLetti()} />
        //     </div>
        //     : null}
        // </ListContextProvider>
    )
}

export const MessaggiLista = ({dettagli: record}) => {
    const { permissions } = usePermissions();
    // const refFine = useRef(null)

    // useEffect(() => {
    //     if (refFine && refFine.current)
    //     refFine.current.scrollIntoView()
    // }, []);

    return (
        <ListBase
            resource="richiesta_messaggio"
            disableSyncWithLocation
            filter={{ id_richiesta: record.id }}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={100}
            exporter={false}
            sx={{ backgroundColor:'#ff0000',  '& .MuiCardContent-root': {display: 'none'} }}
        >
            <CustomDatagrid />
            {permissions === base64_encode(admB64) ?
                <TastoLeggiTutti />
            : null}
            {/* <Datagrid
                bulkActionButtons={false}
                hover={false}
                empty={<div style={{ display: 'none', backgroundColor: 'red', height: '1px'}}></div>}
                sx={{ '& .RaDatagrid-row td': { border: 0 }, '& .RaDatagrid-thead': { display: 'none' }, '& .RaDatagrid-thead th': { border: 0 }, py:1, overflowX: 'hidden', height: "max-content" }}
            >
                <BloccoSingoloMessaggio />
            </Datagrid> */}
            {/* <div id="fine_lista" ref={refFine}></div> */}
        </ListBase>
    )
};