import React from "react";
import { useMediaQuery } from '@mui/material';

const IconaValidataDesktop = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            clipRule="evenodd"
            viewBox="0 0 1921 300"
            preserveAspectRatio="xMidYMin slice"
            {...props}
        >
            <path fill="none" d="M0.759 0H1920.759V300H0.759z"></path>
            <circle
                cx="158.573"
                cy="150"
                r="127.813"
                fill="#ebebeb"
                stroke="#4caf50"
                strokeWidth="20"
            ></circle>
            <circle
                cx="960.759"
                cy="148.426"
                r="127.813"
                fill="#273582"
                stroke="#273582"
                strokeWidth="20"
            ></circle>
            <circle
                cx="1762.95"
                cy="148.426"
                r="127.813"
                fill="#ebebeb"
                stroke="#b3b3b3"
                strokeWidth="20"
            ></circle>
            <path
                fill="none"
                stroke="#273582"
                strokeLinecap="square"
                strokeWidth="20"
                d="M334.671 150h450"
            ></path>
            <path
                fill="none"
                stroke="#b3b3b3"
                strokeLinecap="square"
                strokeWidth="20"
                d="M1136.85 150h450"
            ></path>
            <path
                fill="#4caf50"
                fillRule="nonzero"
                d="M182.017 224.296c0 8.82-4.77 13.23-14.31 13.23-1.98 0-3.735-.855-5.265-2.565-1.53-1.71-2.295-4.365-2.295-7.965l-.81-68.58c0-22.68.27-44.01.81-63.99-6.12 5.4-12.06 11.61-17.82 18.63s-9.675 11.7-11.745 14.04c-2.07 2.34-4.77 4.635-8.1 6.885s-6.435 3.375-9.315 3.375-5.175-.99-6.885-2.97c-1.71-1.98-2.565-4.41-2.565-7.29s2.115-6.795 6.345-11.745c4.23-4.95 19.845-21.735 46.845-50.355 4.14-3.78 9.36-5.67 15.66-5.67 3.06 0 5.58.9 7.56 2.7 1.98 1.8 2.97 3.87 2.97 6.21l-1.89 72.63.81 83.43z"
            ></path>
            <path
                fill="#fff"
                fillRule="nonzero"
                d="M974.799 237.526l-39.69-.54h-5.4c-17.82 0-26.73-3.42-26.73-10.26 0-4.86.9-9.45 2.7-13.77 1.8-4.32 3.51-7.56 5.13-9.72 1.62-2.16 4.86-5.76 9.72-10.8 4.86-5.04 8.37-8.82 10.53-11.34 2.16-2.52 8.685-8.91 19.575-19.17 10.89-10.26 19.08-19.53 24.57-27.81 5.49-8.28 8.235-17.01 8.235-26.19 0-14.22-5.67-23.4-17.01-27.54a31.388 31.388 0 00-9.99-1.62c-7.74 0-15.48 3.33-23.22 9.99-3.06 2.88-6.3 4.32-9.72 4.32-1.08 0-2.07-.09-2.97-.27-4.5-1.08-6.75-4.23-6.75-9.45 0-7.02 4.95-12.87 14.85-17.55 10.62-5.04 22.185-7.56 34.695-7.56 12.51 0 22.77 4.545 30.78 13.635 8.01 9.09 12.015 20.385 12.015 33.885 0 19.26-5.94 35.82-17.82 49.68-5.58 6.48-11.25 12.51-17.01 18.09-18.18 17.28-30.33 30.42-36.45 39.42 8.1.54 18.135.81 30.105.81 11.97 0 26.775-.72 44.415-2.16 2.7.54 4.905 1.755 6.615 3.645 1.71 1.89 2.565 4.005 2.565 6.345 0 6.12-3.24 10.35-9.72 12.69-6.48 2.16-17.82 3.24-34.02 3.24z"
            ></path>
            <path
                fill="#b3b3b3"
                fillRule="nonzero"
                d="M1760.11 79.306c-10.62 0-20.52 4.59-29.7 13.77-2.52 1.8-4.77 2.7-6.75 2.7-1.98 0-3.69-.18-5.13-.54-4.14-1.26-6.21-4.14-6.21-8.64 0-7.38 4.68-13.86 14.04-19.44 9.36-5.58 20.43-8.37 33.21-8.37 12.78 0 23.445 3.375 31.995 10.125s12.825 15.525 12.825 26.325c0 10.8-1.215 19.8-3.645 27-2.43 7.2-7.695 13.14-15.795 17.82 19.08 7.2 28.62 20.16 28.62 38.88 0 15.84-4.77 29.475-14.31 40.905-9.54 11.43-21.195 17.145-34.965 17.145-13.77 0-25.875-3.285-36.315-9.855-10.44-6.57-15.66-13.995-15.66-22.275 0-1.62.9-3.51 2.7-5.67 1.8-2.16 4.41-3.24 7.83-3.24s7.11 1.575 11.07 4.725c3.96 3.15 8.46 6.3 13.5 9.45 5.04 3.15 10.71 4.725 17.01 4.725 18 0 27-11.25 27-33.75 0-8.64-3.105-15.615-9.315-20.925-6.21-5.31-14.985-8.055-26.325-8.235-2.34-.18-4.095-.36-5.265-.54-1.17-.18-2.7-.99-4.59-2.43-1.89-1.44-2.835-3.69-2.835-6.75 0-6.48 3.6-9.99 10.8-10.53 7.2-.54 13.635-3.78 19.305-9.72 5.67-5.94 8.505-13.41 8.505-22.41 0-5.04-2.16-9.675-6.48-13.905-4.32-4.23-9.36-6.345-15.12-6.345z"
            ></path>
        </svg>
    );
}

const IconaValidataMobile = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            clipRule="evenodd"
            viewBox="0 0 1010 559"
            preserveAspectRatio="xMidYMin slice"
            {...props}
        >
            <path
                fill="none"
                d="M0.306 0.42H1009.7460000000001V558.6379999999999H0.306z"
            ></path>
            <circle
                cx="851.932"
                cy="277.954"
                r="127.813"
                fill="#ebebeb"
                stroke="#b3b3b3"
                strokeWidth="20"
            ></circle>
            <path
                fill="#b3b3b3"
                fillRule="nonzero"
                d="M849.097 208.834c-10.62 0-20.52 4.59-29.7 13.77-2.52 1.8-4.77 2.7-6.75 2.7-1.98 0-3.69-.18-5.13-.54-4.14-1.26-6.21-4.14-6.21-8.64 0-7.38 4.68-13.86 14.04-19.44 9.36-5.58 20.43-8.37 33.21-8.37 12.78 0 23.445 3.375 31.995 10.125s12.825 15.525 12.825 26.325c0 10.8-1.215 19.8-3.645 27-2.43 7.2-7.695 13.14-15.795 17.82 19.08 7.2 28.62 20.16 28.62 38.88 0 15.84-4.77 29.475-14.31 40.905-9.54 11.43-21.195 17.145-34.965 17.145-13.77 0-25.875-3.285-36.315-9.855-10.44-6.57-15.66-13.995-15.66-22.275 0-1.62.9-3.51 2.7-5.67 1.8-2.16 4.41-3.24 7.83-3.24s7.11 1.575 11.07 4.725c3.96 3.15 8.46 6.3 13.5 9.45 5.04 3.15 10.71 4.725 17.01 4.725 18 0 27-11.25 27-33.75 0-8.64-3.105-15.615-9.315-20.925-6.21-5.31-14.985-8.055-26.325-8.235-2.34-.18-4.095-.36-5.265-.54-1.17-.18-2.7-.99-4.59-2.43-1.89-1.44-2.835-3.69-2.835-6.75 0-6.48 3.6-9.99 10.8-10.53 7.2-.54 13.635-3.78 19.305-9.72 5.67-5.94 8.505-13.41 8.505-22.41 0-5.04-2.16-9.675-6.48-13.905-4.32-4.23-9.36-6.345-15.12-6.345z"
            ></path>
            <circle
                cx="158.144"
                cy="277.954"
                r="127.813"
                fill="#ebebeb"
                stroke="#4caf50"
                strokeWidth="20"
            ></circle>
            <path
                fill="#4caf50"
                fillRule="nonzero"
                d="M178.394 353.824c0 8.82-4.77 13.23-14.31 13.23-1.98 0-3.735-.855-5.265-2.565-1.53-1.71-2.295-4.365-2.295-7.965l-.81-68.58c0-22.68.27-44.01.81-63.99-6.12 5.4-12.06 11.61-17.82 18.63s-9.675 11.7-11.745 14.04c-2.07 2.34-4.77 4.635-8.1 6.885s-6.435 3.375-9.315 3.375-5.175-.99-6.885-2.97c-1.71-1.98-2.565-4.41-2.565-7.29s2.115-6.795 6.345-11.745c4.23-4.95 19.845-21.735 46.845-50.355 4.14-3.78 9.36-5.67 15.66-5.67 3.06 0 5.58.9 7.56 2.7 1.98 1.8 2.97 3.87 2.97 6.21l-1.89 72.63.81 83.43z"
            ></path>
            <g>
                <circle cx="505.026" cy="279.528" r="249.068" fill="#273582"></circle>
                <path
                    fill="#fff"
                    fillRule="nonzero"
                    d="M532.385 450.088l-77.343-1.052h-10.523c-34.726 0-52.088-6.665-52.088-19.994 0-9.47 1.754-18.415 5.261-26.833 3.508-8.418 6.84-14.732 9.997-18.941s9.47-11.225 18.941-21.046c9.471-9.821 16.311-17.187 20.52-22.098 4.209-4.911 16.924-17.363 38.145-37.356 21.221-19.994 37.181-38.058 47.879-54.193 10.699-16.135 16.048-33.147 16.048-51.036 0-27.711-11.049-45.599-33.147-53.667a61.156 61.156 0 00-19.468-3.157c-15.083 0-30.165 6.489-45.248 19.468-5.963 5.612-12.277 8.418-18.941 8.418-2.105 0-4.034-.176-5.788-.526-8.769-2.105-13.154-8.243-13.154-18.415 0-13.68 9.646-25.08 28.938-34.2 20.695-9.821 43.232-14.732 67.61-14.732s44.371 8.857 59.98 26.57c15.609 17.714 23.414 39.724 23.414 66.032 0 37.531-11.575 69.801-34.726 96.81-10.873 12.628-21.922 24.378-33.147 35.252-35.427 33.673-59.103 59.279-71.029 76.817 15.784 1.052 35.339 1.578 58.665 1.578s52.176-1.403 86.551-4.209c5.261 1.053 9.558 3.42 12.89 7.103 3.332 3.683 4.999 7.805 4.999 12.365 0 11.926-6.314 20.168-18.942 24.728-12.627 4.21-34.725 6.314-66.294 6.314z"
                ></path>
            </g>
        </svg>
    );
}

const IconaValidata = ({ ...props }) => {
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));

    return (
        isTablet ?
            <IconaValidataMobile {...props.mobile} />
            :
            <IconaValidataDesktop {...props.desktop} />
    )
}

export default React.memo(IconaValidata);