import * as React from "react";
import { Edit, SimpleForm, TextInput, useGetOne, required } from 'react-admin';
import { useParams } from "react-router-dom";
import { Typography } from '@mui/material';
import { HeaderSezione } from '../generale';
import { HelpOutline } from '@mui/icons-material';

export const FaqModifica = () => {
    const { id } = useParams();

    const TitoloSezione = () => {
        return <span>Modifica della Faq: <b>{`"(ID: ${id})"`}</b></span>;
    };

    // React.useEffect(() => {
    //     document.title = "Modifica la FAQ";
    // }, []);


    return (
        <Edit title={<TitoloSezione />}>
            <SimpleForm>
                <HeaderSezione icona={HelpOutline} titolo="Modifica F.A.Q." />
                <TextInput source="domanda" fullWidth multiline rows={3} validate={[required()]} />
                <TextInput source="risposta" fullWidth multiline rows={3} validate={[required()]} sx={{ mt: 0.5 }} />
            </SimpleForm>
        </Edit>
    )
};