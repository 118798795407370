import { useState } from 'react';
import { useEditContext, useRefresh, useNotify } from 'react-admin';
import { Box, Card, Button, CardContent, Typography, CardMedia, Alert, Modal, Grid, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { logger, HeaderSezione, apiFullUrl } from '../generale';
import { LoadingComponent } from "../LoadingComponent";
import ModificaAnnulla from "../../includes/img/modifica-annulla-user.jpg";
import axios from 'axios';

export const BannerModificaAnnullaRichiestaUtente = ({ stato: statoRichiesta, openModalMessaggistica, dettagli, messaggio }) => {
    const { record, isLoading } = useEditContext();
    const [statoModalAnnullamento, setStatoModalAnnullamento] = useState(false);
    const [annullamentoInCorso, setAnnullamentoInCorso] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    function handleConfermaAnnullamento() {
        // console.log('Conferma annullamento');
        setAnnullamentoInCorso(true)
        setStatoModalAnnullamento(false)

        axios.delete(`${apiFullUrl}/cancel-request/${record.id}`, {
            headers: {
                'X-API-Key': localStorage.getItem('auth_api_key')
            }
        })
        .then(response => {
            console.log(response.data.message);
            if (response.data.message === "OK") {
                notify('La tua richiesta è stata annullata', { type: 'success' });
                refresh();
            } else {
                notify('Non è stato possibile annullare la richiesta. Riprova più tardi oppure contattaci', { type: 'error' });
            }
            setAnnullamentoInCorso(false)
        })
        .catch(() => {
            notify('Si è verificato un errore nell\'operazione. Riprova più tardi oppure contattaci', { type: 'error' });
            setAnnullamentoInCorso(false)
        });
    }

    const titolo = (() => {
        if (statoRichiesta === 1) {
            return "Richiedi una modifica o annulla"
        } else {
            return "Richiedi una modifica"
        }
    })()
    if (isLoading) return null
    dettagli(record)

    return (
        <>
            <Card variant="outlined" sx={{ backgroundColor: 'primary' }}>
                <CardContent>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} sm={4} lg={3}>
                            <CardMedia component="img" height="auto" src={ModificaAnnulla} />
                        </Grid>
                        <Grid item xs={12} sm={8} lg>
                            {annullamentoInCorso ?
                            <LoadingComponent testo="Attendere il completamento dell'operazione" />
                            :
                            <>
                                <HeaderSezione icona={Edit} titolo={titolo} />
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm>
                                        <Alert severity="info" icon={false}>
                                            <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <Typography component="p" variant="p">Se sono cambiate le tue esigenze faccelo sapere</Typography>
                                                <Button variant='contained' color='primary' sx={{ width: { xs: '100%', sm: 'auto' }, px: 4 }} onClick={() => {
                                                    openModalMessaggistica(true)
                                                    messaggio('Richiedo la modifica della mia richiesta perché ...')
                                                }}>Richiedi modifica</Button>
                                            </Box>
                                        </Alert>
                                    </Grid>
                                    {statoRichiesta === 1 ?
                                        <Grid item xs={12} sm>
                                            <Alert severity="error" icon={false}>
                                                <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                    <Typography component="p" variant="p">Se vuoi annullare la tua richiesta sei ancora in tempo</Typography>
                                                    <Button variant='contained' color='error' sx={{ width: { xs: '100%', sm: 'auto' }, px: 4 }} onClick={() => setStatoModalAnnullamento(true)}>Annulla richiesta</Button>
                                                </Box>
                                            </Alert>
                                        </Grid>
                                        : null}
                                </Grid>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                </Box>
                            </>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Dialog open={statoModalAnnullamento} onClose={() => setStatoModalAnnullamento(false)}>
                <DialogContent>
                    <Typography component="p" variant="h5">Sei sicuro di voler annullare la tua richiesta di viaggio?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={() => setStatoModalAnnullamento(false)}>No</Button>
                    <Button variant='contained' color='primary' onClick={handleConfermaAnnullamento}>Si</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}