import { useCallback, useEffect, useState } from 'react';
import { useLogin, useNotify, useRefresh, useRedirect, useUpdate } from 'react-admin';
import { Box, Paper, Grid, Container, TextField, Link, Button, Typography, Divider, useMediaQuery } from '@mui/material';
// import bcrypt from 'bcryptjs';
import { apiFullUrl, apiBaseUrl, LogoImg, LoginBg, titoloPagina, checkRedirectAlViaggio, Footer } from "./components/generale";
import { LoadingComponent } from "./components/LoadingComponent";
import { Login, ArrowForwardIos } from '@mui/icons-material';
// import TastoLoginGoogle from './LoginGoogle';
import LoginSocial from './LoginSocial';
import ResetPassword from "./ResetPassword";
import moment from 'moment';

export const LoginForm = ({ referrer, idViaggio }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginOrReset, setLoginOrReset] = useState(true);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [update] = useUpdate();

    const onlyMobile = useMediaQuery(theme => theme.breakpoints.only('xs'));

    const handleLogin = (e) => {
        setLoadingLogin(true);
        e.preventDefault();
            login({ username: email, password })
                .then(() => {
                    // notify('Benvenuto.', { type: 'success' })
                    update(
                        'utente', {
                            id: localStorage.getItem('auth_uid'),
                            data: {
                                ultimo_login: moment().format('YYYY-MM-DD H:m:s'),
                                api_key_nuova: 0
                            },
                            previousData: {
                                ultimo_login: localStorage.getItem("auth_last_login"),
                                api_key_nuova: localStorage.getItem("auth_api_key_status"),
                            }
                        }
                    ).then(() => {
                        localStorage.removeItem("auth_last_login");
                        localStorage.removeItem("auth_api_key_status");
                    })

                    checkRedirectAlViaggio(idViaggio)
                    .then((res) => {
                        // console.log(res);
                        if (res.action === 'redirect')
                            redirect('/richiesta/create/' + sessionStorage.getItem("vid"))
                        else if (res.action === 'refresh')
                            refresh();
                        // setLoadingLogin(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }).catch(() => {
                    setLoadingLogin(false);
                    notify('Le credenziali inserite non sono corrette. Verifica e riprova.', { type: 'error' });
                })
    };

    // useEffect(() => {
    //     console.log(loadingLogin);
    // }, [loadingLogin]);
    // console.log(sessionStorage.getItem("vid"), idViaggio);

    return (
        !loadingLogin ?
            <Container maxWidth="sm">
                <Grid container mb={5}>
                    <Grid item xs>
                        <Typography component='h3' variant='h3' textAlign='center' color='secondary'>
                            {referrer === 'no_login' ? "Per inviare una richiesta di prenotazione" : null}
                        </Typography>
                        <Typography component='h3' variant='h3' textAlign='center' color='primary'>
                            {loginOrReset === true ? "Accedi alla tua Area personale" : "Reimposta la password del tuo account"}
                        </Typography>
                    </Grid>
                </Grid>
    
                {loginOrReset === true ?
                <>
                    <form onSubmit={handleLogin}>
                        <Grid container spacing={2} display='flex' justifyContent='center'>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="username"
                                    label="E-Mail"
                                    variant="outlined"
                                    type="email"
                                    fullWidth
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="password"
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs sx={{ mt: 0 }} display='flex' justifyContent='center'>
                                <Button type='submit' variant='contained' color='secondary' startIcon={<Login />} label='Accedi' size='large' fullWidth={onlyMobile ? true : false}>Accedi</Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Divider sx={{ my: 2 }}><Typography variant='p'>oppure</Typography></Divider>
                    <Grid container>
                        <Grid item sx={{ width: '100%' }}>
                            <LoginSocial idViaggio={idViaggio} statoLogin={setLoadingLogin} />
                            {/* <TastoLoginGoogle tipo='login' /> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={12} textAlign='center'>
                            <Link underline="hover" size="small" onClick={() => { setLoginOrReset(!loginOrReset) }}><small>Hai dimenticato la password?</small></Link>
                            <br />
                            <Link underline="hover" size="small" onClick={() => { window.location.href = '/#/registrazione' }}><small>Non sei ancora iscritto? Registrati subito</small></Link>
                        </Grid>
                    </Grid>
                </>
                : 
                <ResetPassword pagina='login' aggiornamento={setLoginOrReset} />
                }
                <Footer sx={{  backgroundColor: 'transparent' }} />
            </Container>
        :
        <LoadingComponent testo='Stiamo verificando le credenziali, attendere...' />
    )
}

const MyLoginPage = () => {
    
    useEffect(() => {
        document.title = titoloPagina;
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: "100vh", backgroundImage: `url(${LoginBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Paper sx={{ py: 3, mx: 2, my: 3, backgroundColor: '#ffffffe6' }}>
                <Box
                    component="img"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                    src={LogoImg}
                />
                <LoginForm />
            </Paper>
        </Box>
    );
};

export default MyLoginPage;