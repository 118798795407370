import { useEffect, useState, memo, useRef } from 'react';
import { List, Form, Datagrid, TextField, ReferenceField, Confirm, EditButton, FunctionField, usePermissions, useRecordContext, Link, TextInput, ReferenceInput, AutocompleteInput, SelectInput, Button, useListContext, useRefresh, useNotify, useUnselectAll, useCreate, useReference, useGetList } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Tooltip, Modal, Grid, AlertTitle, useMediaQuery, FormControlLabel, Checkbox, TextField as TextFieldMui } from '@mui/material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import { logger, valutaFormat, StatiRichiesta, admB64, HeaderSezione } from '../generale';
import {calcoloPreventivo} from './Preventivo';
import { ViaggioPreview } from '../Viaggi/ViaggioPreview';
import IconaStatoViaggio from "../IconaStatoViaggio";
import { IconaStatoRichiesta } from "../IconaStatoRichiesta";
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { encode as base64_encode } from 'base-64';

export const RichiestaDetails = ({preventivo: p}) => {
    const record = useRecordContext();
    // console.log(record);
    const { permissions } = usePermissions();
    const { referenceRecord: viaggio, isLoadingViaggio, errorViaggio } = useReference({
        reference: 'viaggio',
        id: record.id_viaggio,
        // trovare il modo per filtrare solo la colonna 'suppl_singola' perché le altre in questo caso non servono. al momento il codice ritorna tutti i valori di 'viaggio'
        meta:{
            include:'suppl_singola'
        }
    });

    if (isLoadingViaggio || errorViaggio || viaggio === undefined) return null;
    // console.log("viaggio", viaggio);
    // console.log(viaggio.suppl_singola ?? 0);
    const obj = {
        prezzo: record.prezzo,
        suppl_singola: viaggio.suppl_singola ?? 0,
        n_singole: record.cam_singola ?? 0,
        acconto: 20,
        sconto: {
            perc:record.sconto_perc ?? 0,
            amm:record.sconto_amm ?? 0
        },
        partecipanti: {
            adulti: record.n_adulti ?? 0,
            bambini_1: record.n_bambini_1 ?? 0,
            bambini_2: record.n_bambini_2 ?? 0,
            bambini_3: record.n_bambini_3 ?? 0,
            bambini_4: record.n_bambini_4 ?? 0
        },
        totPartecipanti: (record.n_adulti + record.n_bambini_3 + record.n_bambini_4)
    };
    const preventivo = calcoloPreventivo(obj, null, p.configSconti);

    // console.log(record);
    // console.log(obj);
    // console.log(preventivo);
    if (!record || !viaggio) return <LoadingComponent />;
    return (
        <Grid container spacing={2} sx={{ py: 4 }}>
            {record.note_pub ?
            <Grid item xs={6} md={12} order={{ xs: 12, sm: 1 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Note</Typography>
                        {record.note_pub}
                    </CardContent>
                </Card>
            </Grid>
            : null}
            <Grid item xs={6} sm={6} md={4} xl order={{ xs: 1, sm: 2 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Riepilogo</Typography>
                        <ReferenceField source="id_stato_dati" reference="stato_dati" link={false}>
                            <FunctionField render={record => `Dati: ${(record.nome || 'N.D.')}`} />
                        </ReferenceField>
                        <br />
                        <ReferenceField source="id_stato_pagamenti" reference="stato_pagamenti" link={false}>
                            <FunctionField render={record => `Pagamenti: ${record.nome}`} />
                        </ReferenceField>
                        <hr />
                        Prezzo:
                        <span style={{ marginLeft: '5px' }}>{valutaFormat.format(preventivo.totFinale)}{(preventivo.totSconto > 0 ? <><s style={{ marginLeft: '10px' }}>{valutaFormat.format(preventivo.totParziale)}</s> (- {(preventivo.percSconto > 0 ? `${preventivo.percSconto}%` : valutaFormat.format(preventivo.totSconto))})</> : null)}</span>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} sm={6} md={4} xl order={{ xs: 2, sm: 3 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Dettaglio partecipanti</Typography>
                        {record.n_adulti ? <div>{`Adulti: ${record.n_adulti}`}</div> : null}
                        {record.n_bambini_1 ? <div>{`Bambini 0-2: ${record.n_bambini_1}`}</div> : null}
                        {record.n_bambini_3 ? <div>{`Bambini 3-13: ${record.n_bambini_3}`}</div> : null}
                        {record.n_bambini_4 ? <div>{`Bambini 14-17: ${record.n_bambini_4}`}</div> : null}
                        {record.n_bambini_2 ? <div>{`Di cui sotto 1 mt: ${record.n_bambini_2}`}</div> : null}
                    </CardContent>
                </Card>
            </Grid>
            {record.id_stato_richiesta === 2 ?
            <Grid item xs={6} sm={6} md={4} xl order={{ xs: 3, sm: 4 }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography component="h3" variant="p">Dettaglio camere</Typography>
                        {record.cam_singola ? <div>{`${parseInt(record.cam_singola) === 1 ? 'Singola' : 'Singole'}: ${record.cam_singola}`}</div> : null}
                        {record.cam_doppia ? <div>{`${parseInt(record.cam_doppia) === 1 ? 'Doppia' : 'Doppie'}: ${record.cam_doppia}`}</div> : null}
                        {record.cam_matrimoniale ? <div>{`${parseInt(record.cam_matrimoniale) === 1 ? 'Matrimoniale' : 'Matrimoniali'}: ${record.cam_matrimoniale}`}</div> : null}
                        {record.cam_tripla ? <div>{`${parseInt(record.cam_tripla) === 1 ? 'Tripla' : 'Triple'}: ${record.cam_tripla}`}</div> : null}
                        {record.cam_quadrupla ? <div>{`${parseInt(record.cam_quadrupla) === 1 ? 'Quadrupla' : 'Quadruple'}: ${record.cam_quadrupla}`}</div> : null}
                    </CardContent>
                </Card>
            </Grid>
            : null}
        </Grid>
    );
}

const ViaggioPreviewMemo = memo(ViaggioPreview);

export const RichiestaArchivioList = () => {
    const { idViaggio } = useParams();
    const { permissions } = usePermissions();
    const idUtente = localStorage.getItem('auth_uid');
    // const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente } : (viaggioAttivo ? {id_viaggio: viaggioAttivo} : null));
    const permFilter = (permissions !== base64_encode(admB64) ? { id_utente: idUtente, archiviata: 1 } : (idViaggio ? {id_viaggio: idViaggio, archiviata: 1} : {archiviata: 1}));
    const { data: configSconti, isLoading, error } = useGetList('configurazioni',{ 
        pagination: { perPage: 99 },
        sort: { field: 'id', order: 'ASC' },
        filter: { nome_in: ['sconto_bambini_1','sconto_bambini_3'] }
    });
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const filtersAdmin = [
        <TextInput source="id_sw" label="Cerca per ID" alwaysOn resettable />,
        <TextInput source="cognome_cs" label="Cerca per cognome" alwaysOn resettable />,
        <ReferenceInput source="id_stato_richiesta" reference="stato_richiesta" alwaysOn resettable>
            <SelectInput label="Stato richiesta" optionText="nome" optionValue="id" />
        </ReferenceInput>,
        (!idViaggio ?
        <ReferenceInput source="id_viaggio" reference="viaggio" alwaysOn resettable>
            <AutocompleteInput
                label="Viaggio"
                // optionText="titolo"
                optionText={d => `${d.titolo} ${(d.data_inizio !== null && d.data_inizio !== '' ? (d.data_inizio !== d.data_fine && d.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(d.data_inizio).format("DD/MM/YYYY")}` : ``) + (d.data_fine !== null && d.data_fine !== '' && d.data_fine !== '0000-00-00' ? (d.data_inizio !== d.data_fine && d.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(d.data_fine).format("DD/MM/YYYY")}` : ``) + `)`}`}
                optionValue="id"
                filterToQuery={txt => ({ titolo_cs: txt })}
            />
        </ReferenceInput>
        :<EditButton
            label="Vedi tutti"
        />)
    ];
    
    const RichiesteBulkActionButtons = () => {
        const { selectedIds, data: dettagli, isLoadingSelected } = useListContext();
        const [mostraModalMessaggio, setMostraModalMessaggio] = useState(false);
        const refresh = useRefresh();
        const notify = useNotify();
        const unselectAll = useUnselectAll('richiesta');
        // console.log(dettagli);
        
        const FormMessaggio = () => {
            const [testoMessaggio, setTestoMessaggio] = useState(null);
            const [conteggioInvii, setConteggioInvii] = useState(0);
            // const [verifica, setVerifica] = useState(false);
            const [create, { isLoading, error }] = useCreate();
            const inviaMessaggio = () => {
                selectedIds.map((idRichiesta, index) => {
                    logger(16, testoMessaggio, null, null, idRichiesta, idUtente).then((res) => {
                        create('richiesta_messaggio', {
                            data: {
                                id_richiesta: idRichiesta,
                                id_utente: idUtente,
                                messaggio: testoMessaggio
                            }
                        });
                        if (res.data.id)
                            setConteggioInvii(conteggioInvii+1);
                    })
                    // console.log(index, (selectedIds.length-1));
                    return null;
                })
                setMostraModalMessaggio(false);
                unselectAll();
                notify('Operazione completata', { type: 'success' });
                setTestoMessaggio(null);
            }

            return (
                <Grid container margin={2} style={{backgroundColor:'#fff'}}>
                    <Grid item xs={12} md={12}>
                        <Box mx={5} mt={5}>
                            <HeaderSezione titolo='Invia un messaggio per le richieste selezionate' />
                            {/* <AlertTitle><b>Invia un messaggio per le richieste selezionate</b></AlertTitle> */}
                            <TextFieldMui label='Messaggio' fullWidth multiline rows={5} onChange={e => setTestoMessaggio(e.target.value !== '' ? e.target.value : null)}></TextFieldMui>
                        </Box>
                        <Box mx={5} mb={5}>
                            <Button label='Invia messaggio' size='large' variant='contained' disabled={!testoMessaggio} onClick={inviaMessaggio}></Button>
                        </Box>
                    </Grid>
                </Grid>
            )
        }

        if (!dettagli) return null;

        return (
            <>
                <Form>
                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={1}>
                        <Grid item>
                            <Button label='Invia messaggio' onClick={() => setMostraModalMessaggio(!mostraModalMessaggio)} sx={{ mr: 3}}></Button>
                        </Grid>
                    </Grid>
                </Form>
                <Modal open={mostraModalMessaggio} onClose={() => setMostraModalMessaggio(!mostraModalMessaggio)} keepMounted style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <FormMessaggio />
                </Modal>
            </>
        )
    };

    return (
        <Card sx={{ mt: 1 }}>
            <CardContent py={{ xs: 2, md: 3, xl: 4 }}>
                <Grid container columnSpacing={2}>
                    {idViaggio && permissions === base64_encode(admB64) ?
                        <Grid item xs={12} sm={12} md={4} xl={3}>
                            <ViaggioPreviewMemo id={idViaggio} />
                        </Grid>
                    : null }
                    <Grid item xs={12} sm={12} md={idViaggio && permissions === base64_encode(admB64) ? 8 : 12} xl={idViaggio && permissions === base64_encode(admB64) ? 9 : 12}>
                        <List resource="richiesta" filter={permFilter} filters={filtersAdmin} sort={{ field: 'id', order: 'DESC' }} perPage={100} exporter={false} empty={<EmptyPage testo='richieste' id_viaggio={idViaggio} />} actions={false} title='Archivio richieste' disableSyncWithLocation>
                            <Datagrid bulkActionButtons={false ?? <RichiesteBulkActionButtons />} expandSingle expand={<RichiestaDetails preventivo={{configSconti: configSconti}} />} empty={<Box sx={{mx:2}}><EmptyPage testo='richieste' /></Box>}>
                                {!isSmall ? <TextField source="id" label="ID" /> : null}
                                <FunctionField sortable={false} label="Nominativo" render={record => `${record.cognome} ${record.nome} (x ${((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null))})`} />
                                {/* <TextField source="fermata" label="Fermata" /> */}
                                {!idViaggio ?
                                <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                    <FunctionField sortable={false} render={record => {
                                        return <Link to={`../viaggio/${record.id}`}>{record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`}</Link>
                                    }} />
                                </ReferenceField>
                                : null }
                                {!idViaggio ?
                                <ReferenceField source="id_viaggio" reference="viaggio" label="Stato viaggio" link={false} textAlign='center'>
                                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio">
                                        <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                                            return <IconaStatoViaggio vista='admin' stato={record.id} />
                                        }} />
                                    </ReferenceField>
                                </ReferenceField>
                                : null }
                                <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                                    return <IconaStatoRichiesta stato={record.id_stato_richiesta} />
                                }} />
                                {/* <FunctionField label="Data creazione" render={record => `${moment(record.creazione).format("DD/MM/YY HH:mm")}`} /> */}
                                <EditButton label={permissions === base64_encode(admB64) ? 'Modifica' : 'Vedi' } startIcon={null} />
                            </Datagrid>
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};