import * as React from "react";
import { Tooltip } from '@mui/material';

// Icone
import IconaInLavorazione from "../includes/icone/richiesta-1";
import IconaValidata from "../includes/icone/richiesta-2";
import IconaConfermata from "../includes/icone/richiesta-3";
import IconaNonAccettata from "../includes/icone/richiesta-4";
import IconaRifiutata from "../includes/icone/richiesta-5";
import IconaAnnullata from "../includes/icone/richiesta-6";

export const IconaStatoRichiesta = ({stato, ...props}) => {
    switch (parseInt(stato)) {
        case 1:
            return <Tooltip title="In lavorazione"><div><IconaInLavorazione mobile={{ ...props.mobile }} desktop={{ ...props.desktop }} /></div></Tooltip>
        case 2:
            return <Tooltip title="Validata"><div><IconaValidata mobile={{ ...props.mobile }} desktop={{ ...props.desktop }} /></div></Tooltip>
        case 3:
            return <Tooltip title="Confermata"><div><IconaConfermata mobile={{ ...props.mobile }} desktop={{ ...props.desktop }} /></div></Tooltip>
        case 4:
            return <Tooltip title="Non accettata"><div><IconaNonAccettata mobile={{ ...props.mobile }} desktop={{ ...props.desktop }} /></div></Tooltip>
        case 5:
            return <Tooltip title="Rifiutata"><div><IconaRifiutata mobile={{ ...props.mobile }} desktop={{ ...props.desktop }} /></div></Tooltip>
        case 6:
            return <Tooltip title="Annullata"><div><IconaAnnullata mobile={{ ...props.mobile }} desktop={{ ...props.desktop }} /></div></Tooltip>
        default:
            return;
    }
}