import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, BooleanField, FunctionField, SearchInput, SelectInput, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';

export const DaLeggereList = () => {

    const filters = [
        <ReferenceInput source="id_utente" reference="utente" alwaysOn>
            <TextInput source="nominativo_cs" label="Cerca per nominativo" alwaysOn />
        </ReferenceInput>,
        <ReferenceInput source="id_viaggio" reference="viaggio" alwaysOn>
            <AutocompleteInput
                label="Viaggio"
                // optionText="titolo"
                optionText={d => `${d.titolo} ${(d.data_inizio !== null && d.data_inizio !== '' ? (d.data_inizio !== d.data_fine && d.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(d.data_inizio).format("DD/MM/YYYY")}` : ``) + (d.data_fine !== null && d.data_fine !== '' && d.data_fine !== '0000-00-00' ? (d.data_inizio !== d.data_fine && d.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(d.data_fine).format("DD/MM/YYYY")}` : ``) + `)`}`}
                optionValue="id"
                filterToQuery={txt => ({ titolo_cs: txt })}
            />
        </ReferenceInput>
    ];

    return (
        <List filters={filters} exporter={false} empty={<EmptyPage testo='messaggi' />} perPage={50}>
            <Datagrid bulkActionButtons={false} rowClick={(id, res, record) => {
                // console.log(record.id_richiesta);
                return `/richiesta/${record.id_richiesta}`
                }}>
                <TextField source="id" />
                <TextField source="messaggio" label="Ultimo messaggio" />
                {/* <ReferenceField source="id_utente" reference="utente" label="Inviato da" link={false}>
                    <TextField source="nominativo" />
                </ReferenceField> */}
                <TextField source="nominativo" label="Inviato da" />
                <FunctionField sortable={false} label="Inviato il" render={record => moment(record.creazione).format("DD/MM/YYYY HH:mm")} />
                <ReferenceField source="id_richiesta" reference="richiesta" label="Viaggio" link={false}>
                    <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                        <FunctionField sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} />
                    </ReferenceField>
                </ReferenceField>
            </Datagrid>
        </List>
    )
};