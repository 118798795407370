import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, WrapperField, BooleanField, FunctionField, SearchInput, SelectInput, TextInput } from 'react-admin';
import { ListaRuoliUtenti } from '../generale';

export const UtentiList = () => {

    const filters = [
        // <SearchInput source="email_cs" alwaysOn />,
        <TextInput source="nominativo_cs" label="Cerca per nominativo" alwaysOn />,
        <TextInput source="email_cs" label="Cerca per email" alwaysOn />,
        <SelectInput
            alwaysOn
            source="stato"
            label="Stato"
            choices={[
                { id: 1, stato: 'Attivi' },
                { id: 0, stato: 'Disattivi' }
            ]}
            optionText="stato"
            optionValue="id"
        />,
        <ListaRuoliUtenti alwaysOn />
    ];

    return (
        <List filters={filters} exporter={false} sort={{ field: 'id', order: 'DESC' }} perPage={100}>
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="id" />
                <WrapperField label="Nominativo">
                    <TextField source="cognome" /> <TextField source="nome" />
                </WrapperField>
                <TextField source="email" />
                <TextField source="telefono" label="Cellulare" />
                <ReferenceField source="id_ruolo" reference="utente_ruolo" label="Ruolo" link={false}>
                    <TextField source="descr" />
                </ReferenceField>
                {/* <BooleanField source="stato" /> */}
            </Datagrid>
        </List>
    )
};