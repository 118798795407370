import React from "react";

function IconaSpettacoli() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeMiterlimit="10"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <g fillRule="nonzero" stroke="#fff" strokeWidth="2">
        <path d="M451.767 329.336c-4.36 1.12-9.476 2.254-14.97 3.294a320.76 320.76 0 01-17.278 2.861c-5.899.824-11.838 1.445-17.413 1.904-5.575.486-10.799.783-15.308.877-8.99.283-15.078-.229-15.078-.229s5.723-2.133 14.457-4.293c4.36-1.12 9.476-2.254 14.957-3.294a320.948 320.948 0 0117.292-2.875 326.236 326.236 0 0117.413-1.917c5.575-.486 10.799-.783 15.294-.877m1.12 104.764c-24.432 10.907-48.595 10.002-53.968-2.012-5.359-12.027 10.097-30.615 34.53-41.522s48.582-10.002 53.955 2.011c5.372 12.028-10.084 30.616-34.517 41.523zm38.08 147.865c-15.132 39.538-44.937 69.248-80.857 85.285-35.907 16.023-77.928 18.385-117.48 3.253-9.138-3.496-13.715-13.742-10.218-22.88 3.509-9.152 13.741-13.728 22.894-10.232 60.838 23.299 129.277-7.263 152.562-68.101 3.497-9.153 13.742-13.715 22.894-10.219 9.139 3.496 13.715 13.742 10.205 22.894zm-327.344-44.829c-5.359-12.028 10.097-30.616 34.529-41.523 24.42-10.907 48.582-10.002 53.955 2.025 5.359 12.014-10.084 30.602-34.516 41.509-24.433 10.907-48.596 10.002-53.968-2.011zm-23.299-68.75c3.078-3.293 6.79-6.992 10.866-10.812a336.172 336.172 0 0113.054-11.69c4.549-3.847-29.482 31.142-25.027 27.753 4.454-3.388 47.408-45.139 51.16-47.637 7.424-5.062 12.837-7.896 12.837-7.896s-3.671 4.873-9.894 11.379c-3.078 3.28-6.776 6.979-10.867 10.813-4.063 3.833-8.49 7.842-13.039 11.689a321.016 321.016 0 01-13.675 10.948c-4.441 3.415-8.693 6.452-12.445 8.95m197.662 91.899a2.535 2.535 0 013.266-1.458c12.662 4.846 26.917-1.512 31.763-14.173a2.561 2.561 0 013.28-1.472c1.296.5 1.944 1.971 1.445 3.28-2.916 7.641-8.667 13.378-15.605 16.469-6.938 3.091-15.051 3.55-22.691.634a2.543 2.543 0 01-1.458-3.28zm-72.327-228.345c-83.017 37.054-238.415 58.45-238.415 58.45s103.482 224.093 151.443 290.209C228.498 745.03 365.47 832.785 466.4 787.74c100.93-45.059 125.565-205.627 108.301-284.459-17.454-79.683-115.104-305.922-115.104-305.922s-106.829 95.679-191.602 133.529z"></path>
        <path d="M952.216 537.006c-3.888-2.254-8.329-5.035-12.972-8.153a305.02 305.02 0 01-14.322-10.097 327.53 327.53 0 01-13.742-10.853c-4.306-3.577-8.234-7.047-11.515-10.124-6.6-6.102-10.583-10.745-10.583-10.745s5.589 2.497 13.31 7.087c3.901 2.254 8.329 5.035 12.986 8.153 4.657 3.104 9.544 6.506 14.322 10.083a326.497 326.497 0 0113.755 10.853c4.307 3.564 8.235 7.033 11.515 10.124 6.601 6.102-2.335 10.745-2.335 10.745s7.316-2.483-.419-7.073zm-69.991 71.732c-25.067-9.381-41.63-26.984-37.014-39.308 4.604-12.338 28.658-14.727 53.726-5.345 25.053 9.368 41.63 26.97 37.013 39.308-4.616 12.324-28.671 14.714-53.725 5.345zm-92.034 173.176a17.6 17.6 0 01-13.553.459 17.605 17.605 0 01-9.922-9.247c-26.997-59.273-97.191-85.555-156.477-58.557-8.909 4.049-19.411.121-23.475-8.788-4.049-8.909-.135-19.411 8.788-23.474 77.064-35.097 168.33-.945 203.426 76.133 4.05 8.909.122 19.411-8.787 23.474zM603.867 479.11c4.617-12.324 28.672-14.727 53.725-5.346 25.068 9.382 41.644 26.971 37.027 39.309-4.616 12.338-28.671 14.727-53.725 5.345-25.067-9.381-41.63-26.984-37.027-39.308zm31.655-65.321c4.495-.189 9.732-.216 15.334-.081 5.589.108 11.542.392 17.495.837 5.939.446 11.865 1.08 17.427 1.809 5.548.702 10.718 1.512 15.132 2.362 8.855 1.62 14.7 3.402 14.7 3.402s-6.047.878-15.038 1.148c-4.495.175-9.732.216-15.321.081-5.602-.122-11.555-.378-17.508-.837a319.63 319.63 0 01-17.426-1.782c-5.548-.702-10.718-1.526-15.133-2.376-8.855-1.606-14.7-3.402-14.7-3.402s6.048-.891 15.038-1.161zm75.957 208.759a2.54 2.54 0 013.348 1.255c5.616 12.351 20.235 17.818 32.586 12.189a2.541 2.541 0 013.348 1.256 2.529 2.529 0 01-1.256 3.361c-7.451 3.388-15.564 3.442-22.691.769-7.1-2.659-13.202-8.031-16.59-15.469a2.533 2.533 0 011.255-3.361zM525.75 735.924c19.843 58.179 60.434 117.385 115.158 137.876C744.43 912.541 874.666 815.472 917.7 747.195c43.493-69.006 132.72-298.7 132.72-298.7s-143.236-6.831-230.195-39.376c-85.15-31.857-210.85-125.687-210.85-125.687s-25.121 69.802-49.824 146.178"></path>
      </g>
    </svg>
  );
}

export default IconaSpettacoli;