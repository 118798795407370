import React, { useState, useEffect } from 'react';
import { TextInput, Button } from 'react-admin';
import { Button as ButtonMui } from '@mui/material';
import { useFormContext } from "react-hook-form";

export const generatePassword = (
  passwordLength = 20,
  uppercase = true,
  lowercase = true,
  numbers = true,
  symbols = true,
) => {
  let password = '';
  const random = (min = 0, max = 1) => {
    return Math.floor(Math.random() * (max + 1 - min) + min)
  }
  const randomLower = () => {
    return String.fromCharCode(random(97, 122))
  }
  const randomUpper = () => {
    return String.fromCharCode(random(65, 90))
  }
  const randomSymbol = () => {
    const symbols = "*$%@#&!?."
    return symbols[random(0, symbols.length - 1)]
  }
  for (let i = 0; i < passwordLength; i++) {
    let choice;
    if (i === 0) {
      choice = random(0, 1)
    } else {
      choice = random(0, 3)
    }
    if (lowercase && choice === 0) {
      password += randomLower()
    } else if (uppercase && choice === 1) {
      password += randomUpper()
    } else if (symbols && choice === 2) {
      password += randomSymbol()
    } else if (numbers && choice === 3) {
      password += random(0, 9)
    } else {
      i--
    }
  }
  return password;
}

const PasswordGenerator = ({updatePwd, pwdBase = false}) => {
  const [password, setPassword] = useState('')
  const [passwordLength, setPasswordLength] = useState(12)
  const [uppercase, setUppercase] = useState(true)
  const [lowercase, setLowercase] = useState(true)
  const [numbers, setNumbers] = useState(true)
  const [symbols, setSymbols] = useState(true)
  const [errors, setErrors] = useState({})
  const { setValue } = useFormContext();
  setValue("temp", password);

  const generatePassword = () => {
    setErrors({})
    if (!uppercase && !lowercase && !numbers && !symbols) {
      return setErrors('At least one character type must be selected')
    } else if (passwordLength === '0') {
      return setErrors('Password length cannot be 0')
    } else if (passwordLength === '') {
      return setErrors('Invalid password length')
    } else if (passwordLength > 80) {
      return setErrors('Password length cannot exceed 80 characters')
    }

    let password = ''
    for (let i = 0; i < passwordLength; i++) {
      let choice = random(0, 3)
      if (lowercase && choice === 0) {
        password += randomLower()
      } else if (uppercase && choice === 1) {
        password += randomUpper()
      } else if (symbols && choice === 2) {
        password += randomSymbol()
      } else if (numbers && choice === 3) {
        password += random(0, 9)
      } else {
        i--
      }
    }
    setPassword(password);
    updatePwd(password);
  }

  const random = (min = 0, max = 1) => {
    return Math.floor(Math.random() * (max + 1 - min) + min)
  }

  const randomLower = () => {
    return String.fromCharCode(random(97, 122))
  }

  const randomUpper = () => {
    return String.fromCharCode(random(65, 90))
  }

  const randomSymbol = () => {
    const symbols = "*$%@#&!?."
    return symbols[random(0, symbols.length - 1)]
  }

  useEffect(() => {
    generatePassword()
  }, [])

  useEffect(() => {
    if (pwdBase && password) {
      return password;
    }
  }, [password])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'stretch'}}>
      {errors.length && <li className='error'>{errors}</li>}
      <TextInput source='temp' label='Password' value={password} fullWidth disabled />
      <ButtonMui sx={{ mt: '-16px', px: 2.5, mb: '20px', ml: 2, '& .MuiButton-startIcon': { mx: 0 } }} variant="contained" onClick={generatePassword} icon={false}>Genera</ButtonMui>
    </div>
  )
}

export default PasswordGenerator
