import * as React from 'react';
import { Link } from 'react-admin';
import { Box, Grid, Alert, AlertTitle, Typography, Button } from '@mui/material';

export const EmptyPage = ({ testo }) => {

    return (
        <Grid container spacing={2} sx={{ width: '100%' }}>
            <Grid item xs={12} md={12}>
                <Alert severity="info" sx={{ mt: 3, py: 2 }}>
                    <AlertTitle>Info</AlertTitle>
                    <Typography variant="p" sx={{ lineHeight: 1, fontSize: 16 }}>Non ci sono {testo} da mostrare</Typography>
                </Alert>
            </Grid>
        </Grid>
    )
}