import * as React from "react";
import { List, Pagination, useGetList, TextField, ReferenceField, WithRecord, Button, ShowButton, FunctionField, usePermissions, useListContext, useRecordContext, TextInput, ReferenceInput, SelectInput, useStore, useRedirect, useListController, ListContextProvider } from 'react-admin';
import {
    Typography,
    Box,
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardHeader,
    CardContent,
    useMediaQuery
} from '@mui/material';
import { CalendarMonth, Place, Euro, Info, Ballot } from '@mui/icons-material';
import { publicUrl, valutaFormat, localeITmoment, myTheme, divideString, ResponsiveBreakpoint } from '../generale';
import { LoadingComponent } from '../LoadingComponent';
import { IconaCategoriaViaggio } from '../IconaCategoriaViaggio';
import { EmptyPage } from '../EmptyPage';
import moment from 'moment';
import "./GoogleFonts.css";

export const BloccoViaggioCompatto = ({record, i, nuovaRichiesta}) => {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('xl'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return (
        <Grid item xs={12} lg={6} xl={6} xxl={4}>
            <Card sx={{ maxWidth: "unset" }} onClick={() => nuovaRichiesta({ id: record.id })}>
                <CardActionArea>
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <CardMedia
                                component="img"
                                style={{ height: '100%', aspectRatio: '991 / 661' }}
                                src={`${publicUrl}/${record.img_intro}`}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <CardHeader
                                title={record.titolo}
                                titleTypographyProps={{
                                    fontFamily: 'Patrick Hand',
                                    // textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 18,
                                    paddingBottom: 0,
                                    color:'primary',
                                    fontWeight: 'bold'
                                }}
                                subheader={
                                    // isTablet && !isMobile ?
                                    <Box display='flex' alignItems='_center' justifyContent='_center' mt={1}>
                                        {/* <Place color="primary.contrastText" sx={{ mr: 1 }} /> */}
                                        {divideString(record.descr_breve, 35)} {(record.descr_breve).length > 40 ? '...' : null}
                                    </Box>
                                    // : null
                                }
                                subheaderTypographyProps={{
                                    // color:'primary.contrastText',
                                    fontSize: 14,
                                    padding: 0
                                }}
                            />

                            <CardContent sx={{ pt: 0 }} variant="boxViaggioUtente">

                                <Box display="flex" flexDirection='column' fontSize={16} fontWeight='bold' textAlign='right' justifyContent='space-between'>
                                    <Box border='0px solid #000' mt={0.5} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                        <Box display='flex' alignItems='center' justifyContent='start'>
                                            <CalendarMonth color="primary" sx={{ fontSize: {xs: 20, xl: 25} }} />
                                            {/* {!isMobile ? <Typography component='small' variant="small" color='primary' textTransform='uppercase' mr={{ xs: 1, xl: 0.5 }}>Data</Typography> : null} */}
                                            <Typography component='p' variant="p" ml={1}>{(record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` Dal ` : ``) + `${moment(record.data_inizio).format("DD MMM")}` : ``) +
                                            (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD MMM")}` : ``)}</Typography>
                                        </Box>
                                    </Box>

                                    <Box border='0px solid #000' mt={0.5} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                        <Box display='flex' alignItems='center' justifyContent='start'>
                                            <Euro color="primary" sx={{ fontSize: {xs: 20, xl: 25} }} />
                                            {/* {!isMobile ? <Typography component='small' variant="small" color='primary' textTransform='uppercase' mr={{ xs: 1, xl: 0.5 }}>Prezzo</Typography> : null} */}
                                            {/* <p style={{ margin: 0 }}>{valutaFormat.format(record.prezzo)}</p> */}
                                            <Typography component='p' variant="p" ml={1}>{valutaFormat.format(record.prezzo)}</Typography>
                                        </Box>
                                    </Box>

                                    <Box border='0px solid #000' mt={0.5} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                        <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false} record={record}>
                                            <Box display='flex' alignItems='center' justifyContent='start'>
                                                <Info color="primary" sx={{ fontSize: {xs: 20, xl: 25} }} />
                                                {/* {!isMobile ? <Typography component='small' variant="small" color='primary' textTransform='uppercase' mr={{ xs: 1, xl: 0.5 }}>Stato</Typography> : null} */}
                                                <WithRecord label="nome" render={record => <Typography component='p' variant="p" ml={1}>{record.nome}</Typography>} />
                                            </Box>
                                        </ReferenceField>
                                    </Box>
                                </Box>
                                
                            </CardContent>
                        </Grid>
                    </Grid>

                </CardActionArea>
            </Card>
        </Grid>
    )
}

export const BloccoViaggioEsteso = ({record, i, nuovaRichiesta}) => {
    return (
        <Grid item xs={12} sm={6} lg={4} xl={3} xxl={3}>
            <Card sx={{ maxWidth: "unset" }} onClick={() => nuovaRichiesta({ id: record.id })}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        style={{ width: '100%', aspectRatio: '991 / 661' }}
                        src={`${publicUrl}/${record.img_intro}`}
                    />
                    <CardHeader
                        title={record.titolo}
                        titleTypographyProps={{
                            fontFamily: 'Patrick Hand',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontSize: 22,
                            paddingBottom: 0,
                            color:'primary.contrastText',
                            fontWeight: 'bold'
                        }}
                        sx={{
                            pb: 2,
                            background:`linear-gradient(to bottom right, ${myTheme.palette.primary.light}, ${myTheme.palette.primary.dark})`
                            // backgroundColor:'primary.light'
                        }}
                        subheader={
                            <Box display='flex' alignItems='center' justifyContent='center' mt={1}>
                                <Place color="primary.contrastText" sx={{ mr: 1 }} />
                                {record.descr_breve}
                            </Box>
                        }
                        subheaderTypographyProps={{
                            color:'primary.contrastText',
                            padding: 0
                        }}
                    />

                    <CardContent sx={{ pt: 2 }} variant="boxViaggioUtente">

                        <IconaCategoriaViaggio categoria={record.tipo} />

                        <Box display="flex" flexDirection='row' fontSize={16} fontWeight='bold' textAlign='right' justifyContent='space-between'>
                            <Box border='0px solid #000' py={1} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                <Box display='flex' alignItems='center' justifyContent='start'>
                                    <CalendarMonth color="primary" />
                                    <Typography component='small' variant="small" color='primary' textTransform='uppercase'>Data</Typography>
                                </Box>
                                <p style={{ margin: 0 }}>{(record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` Dal ` : ``) + `${moment(record.data_inizio).format("DD MMM")}` : ``) +
                                (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD MMM")}` : ``)}</p>
                            </Box>
                            <Box border='0px solid #000' py={1} fontSize={15} fontWeight='bold' textAlign='right' flex={1}>
                                <Box display='flex' alignItems='center' justifyContent='end'>
                                    <Typography component='small' variant="small" color='primary' textTransform='uppercase'>Categoria</Typography>
                                    <Ballot color="primary" />
                                </Box>
                                {record.tipo}
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection='row' fontSize={16} fontWeight='bold' textAlign='right' justifyContent='space-between'>
                            <Box border='0px solid #000' py={1} fontSize={15} fontWeight='bold' textAlign='left' flex={1}>
                                <Box display='flex' alignItems='center' justifyContent='start'>
                                    <Euro color="primary" />
                                    <Typography component='small' variant="small" color='primary' textTransform='uppercase'>Prezzo</Typography>
                                </Box>
                                <p style={{ margin: 0 }}>{valutaFormat.format(record.prezzo)}</p>
                            </Box>
                            <Box border='0px solid #000' py={1} fontSize={15} fontWeight='bold' textAlign='right' flex={3}>
                                <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Stato" link={false} record={record}>
                                    <Box display='flex' alignItems='center' justifyContent='end'>
                                        <Typography component='small' variant="small" color='primary' textTransform='uppercase'>Stato</Typography>
                                        <Info color="primary" />
                                    </Box>
                                    <WithRecord label="nome" render={record => <p style={{ margin: 0 }}>{record.nome}</p>} />
                                </ReferenceField>
                            </Box>
                        </Box>
                        
                    </CardContent>

                    {/* <CardContent>
                    {(record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` Dal ` : ``) + `${moment(record.data_inizio).format("DD/MM/YYYY")}` : ``) +
                    (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YYYY")}` : ``)}
                    <br />
                    {record.descr_breve}
                </CardContent> */}
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export const CardWrapper = () => {
    const { data } = useListContext();
    const [viaggioAttivo, setViaggioAttivo] = useStore('viaggio_attivo', null);
    const redirect = useRedirect();
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));
    moment.updateLocale('it', localeITmoment);
    const handleNuovaRichiesta = ({ id }) => {
        // console.log(id);
        setViaggioAttivo(id);
        redirect(`/richiesta/create/${id}`);
    }

    React.useEffect(() => {
        setViaggioAttivo(null);
    }, []);

    if (!data) return <LoadingComponent />;
    // console.log(data);
    return (
        <Grid container spacing={2} sx={{ px: 2, my: 2 }}>
            {data.map((record, i) => {
                // console.log(record);
                return (
                    isDesktop ?
                    <BloccoViaggioEsteso key={i} record={record} nuovaRichiesta={handleNuovaRichiesta} />
                    :
                    <BloccoViaggioCompatto key={i} record={record} nuovaRichiesta={handleNuovaRichiesta} />
                )
            })}
        </Grid>
    )
}

export const ViaggiListUtente = () => {
    const listContext = useListController();
    const permFilter = { stato: 1 };
    const { data: categorieViaggio, isLoadingCatViaggio, errorCatViaggio } = useGetList(
        'tipo_viaggio',
        {
            pagination: { page: 1, perPage: 99 },
            sort: { field: 'tipo', order: 'ASC' }
        }
    );
    // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    if (isLoadingCatViaggio) return <LoadingComponent />;
    if (errorCatViaggio) return null;
    if (!categorieViaggio) return null;

    const filters = [
        <TextInput source="titolo_cs" label="Cerca per titolo" alwaysOn resettable />,
        <SelectInput alwaysOn resettable source="tipo" label="Categoria" optionText="tipo" optionValue="tipo"
            choices={
                categorieViaggio.map(
                    (record) => {
                        if (record.n_attivi > 0)
                            return record
                        else
                            return null
                    }
                )}
        />
    ];

    if (!listContext.data) return null;

    return (
        <List
            resource="viaggio"
            // resource="viaggio_dettagli"
            filter={permFilter}
            filters={filters}
            // l'ordinamento per data non funziona, da verificare!
            sort={{ field: 'data_inizio', order: 'ASC' }}
            title="Viaggi"
            perPage={40}
            pagination={<Pagination rowsPerPageOptions={[12, 24, 36, 40]} sx={{ my: 3 }} />}
            exporter={false}
            actions={false}
            empty={<EmptyPage testo='viaggi' />}
            sx={{ pt: {xs: 2 }}}
        >
            <CardWrapper />
        </List>
    )
};