import * as React from "react";
import { useUpdate, useRedirect, useNotify, Create, DateInput, SimpleForm, FormTab, TextInput, BooleanInput, required, email, PasswordInput, SelectInput, useRecordContext } from 'react-admin';
import { Grid, InputAdornment } from '@mui/material';
import { PermIdentity } from '@mui/icons-material';
import { apiBaseUrl, logger, ListaRuoliUtenti, verificaCF, HeaderSezione } from '../generale';
import moment from 'moment';
import axios from 'axios';
import PasswordGenerator from '../PasswordGenerator';

export const UtenteNuovo = () => {
    const idUtente = localStorage.getItem('auth_uid');
    const [update, { isLoading, error }] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const [temp, setTemp] = React.useState('');
    // const randomPassword = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    // console.log(randomPassword);

    // if (isLoading) return '<p>Caricamento</p>';

    const onSuccess = ({ id }) => {
        var bodyFormData = new FormData();
        bodyFormData.append('uid', id);
        bodyFormData.append('action', 'generate');
        bodyFormData.append('temp', temp);
        logger(4, null, id, null, null, idUtente);

        axios.post(apiBaseUrl + '/operation/pwd-hasher.php', bodyFormData)
        .then(response => {
            // console.log(response);
            update(
                'utente',
                { id, data: { hashed_pwd: response.data.hashed_pwd } }, {
                // { id, data: { hashed_pwd: response.data.hashed_pwd, temp: response.data.pwd } }, {
                onSuccess: () => {
                    notify('Utente creato correttamente', { type: 'success' });
                    redirect(`/utente`);
                },
            }
            )
        });
    };
    
    const onError = (error) => {
        if (error.message === "Duplicate key exception")
        notify('Indirizzo email già utilizzato', { type: 'error' });
        else
        notify(`${error.message}`, { type: 'error' });
    }
    // React.useEffect(() => {
    //     console.log(temp);
    // }, [temp]);

    return (
        <Create resource="utente" title="Nuovo Utente" mutationOptions={{ onSuccess, onError }} sx={{ pb: 8 }}>
            <SimpleForm>
                <HeaderSezione icona={PermIdentity} titolo="Crea un nuovo account utente" />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextInput fullWidth source="email" label="E-Mail" type="email" validate={[required(), email()]} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <ListaRuoliUtenti richiesto={true} fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <PasswordGenerator updatePwd={setTemp} />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="nome" label="Nome" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="cognome" label="Cognome" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="telefono" label="Cellulare" type="text" validate={[required()]} fullWidth InputProps={{ startAdornment: <InputAdornment position="start">+39</InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="comune" label="Comune di residenza" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="indirizzo" label="Indirizzo di residenza" type="text" validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <TextInput source="comune_nascita" label="Comune di nascita" type="text" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <DateInput source="data_nascita" label="Data di nascita" sx={{ width: '100%' }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <BooleanInput source="stato" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};