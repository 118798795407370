import * as React from 'react';
import { CircularProgress, Box, Typography, useMediaQuery } from '@mui/material';

export const LoadingComponent = ({testo = null}) => {
    // console.log('LoadingComponent');
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }} mx={2}>
            <CircularProgress /><br />
            <Typography variant="h6">{testo ?? 'Caricamento in corso, attendere...'}</Typography>
        </Box>
    )
}