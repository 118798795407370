import { useEffect, useState, useRef } from 'react';
import { List, ListBase, Form, Datagrid, TextField, ReferenceField, WithRecord, Link, Confirm, EditButton, FunctionField, usePermissions, useRedirect, useRecordContext, useGetList, useGetOne, useDeleteMany, TextInput, ReferenceInput, AutocompleteInput, useUpdateMany, SelectInput, useListContext, useRefresh, useNotify, useUnselectAll, useCreate, useDataProvider, useReference, ShowButton, RecordContext, useStore, dataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box, Card, CardMedia, Button, CardContent, Typography, Grid, useMediaQuery, FormControlLabel, Checkbox, TextField as TextFieldMui } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import { logger, valutaFormat, StatiRichiesta, admB64 } from '../generale';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { encode as base64_encode } from 'base-64';
import IconaStatoViaggio from "../IconaStatoViaggio";
import { IconaStatoRichiesta } from "../IconaStatoRichiesta";
import "../Viaggi/GoogleFonts.css";

export const RichiestaArchivioListUtente = () => {
    // const [partecipanti, setPartecipanti] = useState({ adulti: 0, bambini_1: 0, bambini_3: 0, bambini_4: 0 });
    // const [postiLetto, setPostiLetto] = useState({ singole: 0, doppie: 0, matrimoniali: 0, triple: 0, quadruple: 0 });
    // const [viaggio, setViaggio] = useState(null);
    const { idViaggio } = useParams();
    // const { data: viaggio } = useGetOne('viaggio', { id: idViaggio });
    const idUtente = localStorage.getItem('auth_uid');
    const permFilter = { id_utente: idUtente, archiviata: 1 };
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
    const redirect = useRedirect();

    const PiccoleRes = () => {
        return (
            <Datagrid
                bulkActionButtons={false}
                empty={<Box sx={{ mx: 2 }}><EmptyPage testo='richieste' /></Box>}
                sx={{
                    '& .RaDatagrid-row td': { border: 0 },
                    '& .RaDatagrid-thead': { display: 'none' },
                    '& .RaDatagrid-thead th': { border: 0 },
                    '& .RaDatagrid-rowCell': { display: 'block' },
                    py: 1,
                    overflowX: 'hidden',
                    height: "max-content"
                }}
            >
                <Card elevation={isMobile ? 4 : 0}>
                    <CardContent variant="boxViaggioUtenteV2">
                        <Grid container px={isMobile ? 2 : 0} py={1}>
                            <Grid item xs={12} md={9}>
                                <Grid container columnSpacing={2} rowSpacing={1}>
                                    <Grid item xs={12} textAlign='center'>
                                        <FunctionField sortable={false} label="Nominativo" sx={{ textAlign: 'center', fontWeight:'bold' }} render={record => {
                                            const partecipanti = ((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null));
                                            return `${record.nome} ${record.cognome} (${partecipanti} ${(partecipanti === 1 ? 'partecipante' : 'partecipanti')})`
                                        }}/>
                                        <Typography sx ={{mr: 1}} display='block' component='small' variant='subtitle2' mb={0} pb={0}>per</Typography>
                                        <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                            {/* <FunctionField sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} /> */}
                                            <FunctionField sortable={false} sx={{ textAlign:'center' }} render={record => <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', mb: 2 }} variant="h4" color='primary'>{record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`}</Typography>} />
                                        </ReferenceField>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <FunctionField sortable={false} label="Nominativo" render={record => record.nominativo + " (x " + ((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null)) + ")"} />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <FunctionField label="Richiesta" sortable={false} sx={{ textAlign:'center' }} render={record => {
                                            return <IconaStatoRichiesta stato={record.id_stato_richiesta} mobile={{ style: { maxWidth: '80px' } }} />
                                        }} />
                                        <Typography sx ={{ mr: 1, textAlign: 'center' }} component='small' variant='p' mb={0} pb={0}>La tua richiesta è 
                                            <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" label="Richiesta" link={false}>
                                                <FunctionField label="Richiesta" sortable={false} textAlign="center" sx={{ ml: 0.5, fontWeight: 'bold', textDecoration: 'underline' }} render={record => {
                                                    return record.nome
                                                }} />
                                            </ReferenceField>
                                        </Typography>
                                        {/* <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" label="Richiesta" link={false}>
                                            <TextField source="nome" />
                                        </ReferenceField> */}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                            <FunctionField label="Richiesta" sortable={false} sx={{ textAlign:'center' }} render={record => {
                                                return <IconaStatoViaggio stato={record.id_stato_viaggio} mobile={{ style: { maxWidth: '80px' } }} />
                                            }} />
                                        </ReferenceField>
                                        <Typography sx ={{ mr: 1, textAlign: 'center' }} component='small' variant='p' mb={0} pb={0}>Il viaggio è
                                        <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false}>
                                            <ReferenceField source="id_stato_viaggio" reference="stato_viaggio" label="Viaggio" link={false}>
                                                <FunctionField label="Viaggio" sortable={false} textAlign="center" sx={{ ml: 0.5 }} render={record => {
                                                    return <span style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: 0, paddingTop:0  }}>{record.nome}</span>
                                                }} />
                                            </ReferenceField>
                                        </ReferenceField>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} mt={2} display='flex' alignItems='center'>
                                <Grid container>
                                    <Grid item mt={{xs:1.2, md: 0}} xs={12} display='flex' justifyContent='center'>
                                        {/* <EditButton label='Vedi' startIcon={null} /> */}
                                        <WithRecord render={record => <Button onClick={() => redirect('edit', 'richiesta', record.id)} fullWidth={(isTablet ? true : false)}>Vedi</Button>} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Datagrid>
        )
    }

    const GrandiRes = () => {
        return (
            <Datagrid bulkActionButtons={false} empty={<Box sx={{ mx: 2 }}><EmptyPage testo='richieste' /></Box>} >
                <FunctionField sortable={false} label="Nominativo" render={record => `${record.nome} ${record.cognome} (x ${((record.n_adulti || null) + (record.n_bambini_1 || null) + (record.n_bambini_3 || null) + (record.n_bambini_4 || null))})`} />
                <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false} sortable={false}>
                    <FunctionField sortable={false} render={record => record.titolo + (record.data_inizio !== null && record.data_inizio !== '' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` (Dal ` : ` (`) + `${moment(record.data_inizio).format("DD/MM")}` : ``) + (record.data_fine !== null && record.data_fine !== '' && record.data_fine !== '0000-00-00' ? (record.data_inizio !== record.data_fine && record.data_fine !== '0000-00-00' ? ` al ` : ``) + `${moment(record.data_fine).format("DD/MM/YY")}` : ``) + `)`} />
                </ReferenceField>
                <ReferenceField source="id_viaggio" reference="viaggio" label="Viaggio" link={false} sortable={false}>
                    <ReferenceField source="id_stato_viaggio" reference="stato_viaggio">
                        {/* <TextField source="nome" /> */}
                        <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                            return <IconaStatoViaggio stato={record.id} />
                        }} />
                    </ReferenceField>
                </ReferenceField>
                {/* <ReferenceField source="id_stato_richiesta" reference="stato_richiesta" label="Richiesta" link={false}>
                    <TextField source="nome" />
                </ReferenceField> */}
                <FunctionField label="Richiesta" sortable={false} textAlign="center" render={record => {
                    return <IconaStatoRichiesta stato={record.id_stato_richiesta} />
                }} />
                <WithRecord render={record => <Button onClick={() => redirect('edit', 'richiesta', record.id)} fullWidth={(isTablet ? true : false)}>Vedi</Button>} />
            </Datagrid>
        )
    }

    return (
        <Grid container>
            <Grid item xs md={4} lg={3}
                sx={{ 
                    display:'flex', 
                    alignItems:'center', 
                    flexDirection: 'column' 
                }}
            >
                <Box sx={{ position: 'sticky', top: 100 }} p={2} display='flex' flexDirection='column' alignItems='center'>
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/logo.png`)}
                        sx={{ maxWidth: { xs: "130px", md: "200px"} }}
                    />
                    <CardMedia
                        component="img"
                        image={require(`../../includes/img/richieste-archiviate-user.jpg`)}
                        sx={{ maxWidth: { xs: "200px", md: "100%"} }}
                    />
                    <Typography component="h3" variant="titoloSezione" color="primary" textAlign='center'>Le tue richieste archiviate</Typography>
                    <Typography component="p" variant="p" textAlign='center' mt={2}>Consulta le tue richieste passate.</Typography>
                    <Link to="/richiesta">
                        <Button variant="contained" startIcon={<ArrowBackIos />} sx={{ mt: { xs: 1, lg: 2 } }}><Typography variant="p">Indietro</Typography></Button>
                    </Link>
                </Box>
            </Grid>
            <Grid item md={8} lg={9}>
                <List resource="richiesta" filter={permFilter} filters={false} sort={{ field: 'id', order: 'DESC' }} perPage={20} exporter={false} empty={<EmptyPage testo='richieste' />} actions={false} sx={{ mt: 3 }} title='Archivio richieste'>
                    {isTablet ?
                        <PiccoleRes />
                        :
                        <GrandiRes />
                    }
                </List>
            </Grid>
        </Grid>
    )
};