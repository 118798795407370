import * as React from "react";
import { Create, SimpleForm, TextInput, useGetOne, required } from 'react-admin';
import { useParams } from "react-router-dom";
import { HeaderSezione } from '../generale';
import { HelpOutline } from '@mui/icons-material';

export const FaqNuovo = () => {

    const TitoloSezione = () => {
        return <span>Creazione di una Faq</span>;
    };

    // React.useEffect(() => {
    //     document.title = "Creazione di una FAQ";
    // }, []);


    return (
        <Create resource="faq" title={<TitoloSezione />} redirect="list">
            <SimpleForm>
                <HeaderSezione icona={HelpOutline} titolo="Crea una nuova F.A.Q." />
                <TextInput source="domanda" fullWidth multiline rows={3} validate={[required()]} />
                <TextInput source="risposta" fullWidth multiline rows={3} validate={[required()]} />
            </SimpleForm>
        </Create>
    )
};