import React from "react";

function IconaPasqua() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <path fill="none" d="M0 0H1080V1080H0z"></path>
      <path
        fillRule="nonzero"
        d="M823 269.467C741.456 115.557 640.942 30.799 540 30.799c-100.942 0-201.456 84.758-283 238.668-65.625 123.866-109.727 279.391-109.727 386.991 0 216.569 176.175 392.743 392.727 392.743 216.552 0 392.727-176.174 392.727-392.743 0-107.6-44.102-263.125-109.727-386.991m-563.627 565.49c-24.259-37.987-41.02-81.215-48.172-127.591 1.433 1.78 2.917 3.626 4.515 5.636 16.481 20.848 39.075 49.425 83.671 49.425 44.596 0 67.174-28.577 83.671-49.425 15.277-19.348 22.396-26.615 36.603-26.615 14.189 0 21.309 7.267 36.586 26.615 16.497 20.848 39.075 49.425 83.671 49.425 44.595 0 67.19-28.577 83.687-49.425 15.277-19.348 22.397-26.615 36.586-26.615 14.206 0 21.326 7.267 36.603 26.615 16.497 20.848 39.075 49.425 83.687 49.425 44.596 0 67.174-28.577 83.671-49.425a547.479 547.479 0 014.68-5.834c-7.119 46.442-23.913 89.752-48.205 127.789H259.373zM817.068 675.74c-15.278 19.348-22.397 26.649-36.587 26.649-14.206 0-21.325-7.301-36.603-26.649-16.496-20.831-39.075-49.408-83.687-49.408-44.596 0-67.174 28.577-83.671 49.408-15.293 19.348-22.396 26.649-36.602 26.649-14.19 0-21.309-7.301-36.587-26.649-16.497-20.831-39.075-49.408-83.67-49.408-44.613 0-67.191 28.577-83.688 49.425-15.277 19.331-22.38 26.632-36.586 26.632-14.19 0-21.309-7.301-36.586-26.632-12.46-15.755-28.38-35.861-54.633-44.794 1.961-30.785 7.235-64.85 15.36-100.464h632.944c8.125 35.598 13.399 69.646 15.36 100.431-26.336 8.9-42.289 29.055-54.764 44.81M353.245 408.231l93.377-76.04L540 408.231l93.361-76.04 93.378 76.04 69.514-56.626c17.288 38.696 32.268 79.056 44.266 118.84H239.481c11.998-39.784 26.978-80.128 44.266-118.84l69.498 56.626zm266.899-291.768H459.856C486.851 99.62 513.862 90.836 540 90.836c26.154 0 53.149 8.784 80.144 25.627m-228.731 60.022h297.174c28.066 31.889 55.275 72.068 80.638 119.73l-42.486 34.592-93.378-76.057L540 330.807l-93.378-76.057-93.377 76.057-42.47-34.592c25.363-47.645 52.589-87.841 80.638-119.73m-83.077 718.526h463.328C711.709 953.22 629.966 989.164 540 989.164c-89.983 0-171.709-35.944-231.664-94.153"
      ></path>
    </svg>
  );
}

export default IconaPasqua;