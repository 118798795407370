import React from "react";

function IconaInConferma({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 2253 1257"
      preserveAspectRatio="xMidYMin slice"
      {...props}
    >
      <path fill="none" d="M0.424 0H2250.424V1256.25H0.424z"></path>
      <path
        fill="#fcdd14"
        d="M2083.78 909.693h-187.336c-16.641-82.047-88.786-140.99-172.484-140.99-83.725 0-155.843 58.943-172.538 140.99H699.429c-16.668-82.047-88.786-140.99-172.511-140.99s-155.843 58.943-172.484 140.99h-170.75c-24.287-.027-44.008-19.721-44.036-44.035V343.944c.083-76.436 61.969-138.295 138.377-138.405h1560.66c40.983.11 80.48 14.99 111.313 41.945l-228.236 28.495H350.912c-19.446 0-35.234 15.733-35.234 35.179v211.266c0 19.446 15.788 35.178 35.234 35.178h1371.53l388.783 32.429v292.212c-.028 15.155-12.323 27.45-27.45 27.45z"
      ></path>
      <path fill="#fcdd14" d="M357.831 275.979H1022.72V557.575H357.831z"></path>
      <path
        fillRule="nonzero"
        d="M2165.8 515.355l-108.837-239.898c-39.194-85.266-124.377-140.028-218.279-140.303H278.024c-115.245.138-208.679 93.489-208.79 208.79v521.714c.083 63.178 51.297 114.365 114.449 114.42h170.75c16.641 82.102 88.759 141.018 172.484 141.018 83.725 0 155.843-58.916 172.511-141.018h851.993c16.695 82.102 88.813 141.018 172.538 141.018 83.698 0 155.843-58.916 172.484-141.018h187.336c53.992-.027 97.78-43.788 97.835-97.835v-294c.027-25.14-5.391-50.004-15.816-72.888m-76.298 2.338l-330.362-27.67V342.156l237.395-29.678 92.967 205.215zM526.92 1050.491c-58.338 0-105.619-47.281-105.619-105.619 0-58.311 47.281-105.619 105.619-105.619s105.619 47.308 105.619 105.619c0 58.338-47.281 105.619-105.619 105.619m1197.04 0c-58.365 0-105.619-47.281-105.619-105.619 0-58.311 47.254-105.619 105.619-105.619 58.338 0 105.592 47.308 105.592 105.619 0 58.338-47.254 105.619-105.592 105.619m359.82-140.798h-187.336c-16.641-82.047-88.786-140.99-172.484-140.99-83.725 0-155.843 58.943-172.538 140.99H699.429c-16.668-82.047-88.786-140.99-172.511-140.99s-155.843 58.943-172.484 140.99h-170.75c-24.287-.027-44.008-19.721-44.036-44.035V343.944c.083-76.436 61.969-138.295 138.377-138.405h1560.66c40.983.11 80.48 14.99 111.313 41.945l-228.236 28.495H350.912c-19.446 0-35.234 15.733-35.234 35.179v211.266c0 19.446 15.788 35.178 35.234 35.178h1371.53l388.783 32.429v292.212c-.028 15.155-12.323 27.45-27.45 27.45M386.085 346.364h253.046V487.19H386.085V346.364zm323.458 0h275.05V487.19h-275.05V346.364zm345.462 0h266.248V487.19h-266.248V346.364zm336.661 0h297.081V487.19h-297.081V346.364z"
      ></path>
    </svg>
  );
}

export default IconaInConferma;