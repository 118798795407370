import * as React from "react";
import { SvgIcon } from '@mui/material';

// Icone
import Autunno from '../includes/icone/autunno.js';
import Capodanno from '../includes/icone/capodanno.js';
import Carnevale from '../includes/icone/carnevale.js';
import Concerti from '../includes/icone/concerti.js';
import Divertimento from '../includes/icone/divertimento.js';
import Estate from '../includes/icone/estate.js';
import Ferragosto from '../includes/icone/ferragosto.js';
import Inverno from '../includes/icone/inverno.js';
import Montagna from '../includes/icone/montagna.js';
import Natale from '../includes/icone/natale.js';
import Oktoberfest from '../includes/icone/oktoberfest.js';
import Pasqua from '../includes/icone/pasqua.js';
import Primavera from '../includes/icone/primavera.js';
import Spettacoli from '../includes/icone/spettacoli.js';

export const IconaCategoriaViaggio = ({categoria, ...props}) => {
    switch (categoria) {
        case 'Autunno':
            return <SvgIcon component={Autunno} />
        case 'Capodanno':
            return <SvgIcon component={Capodanno} />
        case 'Carnevale':
            return <SvgIcon component={Carnevale} />
        case 'Concerti':
            return <SvgIcon component={Concerti} />
        case 'Divertimento':
            return <SvgIcon component={Divertimento} />
        case 'Estate':
            return <SvgIcon component={Estate} />
        case 'Ferragosto':
            return <SvgIcon component={Ferragosto} />
        case 'Inverno':
            return <SvgIcon component={Inverno} />
        case 'Montagna':
            return <SvgIcon component={Montagna} />
        case 'Natale':
            return <SvgIcon component={Natale} />
        case 'Oktoberfest':
            return <SvgIcon component={Oktoberfest} />
        case 'Pasqua':
            return <SvgIcon component={Pasqua} />
        case 'Primavera':
            return <SvgIcon component={Primavera} />
        case 'Spettacoli':
            return <SvgIcon component={Spettacoli} />
        default:
            break;
    }
}