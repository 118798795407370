import * as React from "react";
import { Tooltip } from '@mui/material';

// Icone
import Pubblicato from '../includes/icone/autobus-1-pubblicato.js';
import InConferma from '../includes/icone/autobus-2-in-conferma.js';
import Confermato from '../includes/icone/autobus-3-confermato.js';
import UltimiPosti from '../includes/icone/autobus-4-ultimi-posti.js';
import Completo from '../includes/icone/autobus-5-completo.js';
import Annullato from '../includes/icone/autobus-6-annullato.js';

const IconaStatoViaggio = ({stato, vista, ...props}) => {
    switch (stato) {
        case 1:
            return <Tooltip title="Pubblicato"><div><Pubblicato style={{ maxWidth: (vista === 'admin' ? '50px' : '80px'), aspectRatio: 1.81/1}} /></div></Tooltip>
        case 2:
            return <Tooltip title="In via di conferma"><div><InConferma style={{ maxWidth: (vista === 'admin' ? '50px' : '80px'), aspectRatio: 1.81/1}} /></div></Tooltip>
        case 3:
            return <Tooltip title="Confermato"><div><Confermato style={{ maxWidth: (vista === 'admin' ? '50px' : '80px'), aspectRatio: 1.81/1}} /></div></Tooltip>
        case 4:
            return <Tooltip title="Ultimi posti"><div><UltimiPosti style={{ maxWidth: (vista === 'admin' ? '50px' : '80px'), aspectRatio: 1.81/1}} /></div></Tooltip>
        case 5:
            return <Tooltip title="Completo"><div><Completo style={{ maxWidth: (vista === 'admin' ? '50px' : '80px'), aspectRatio: 1.81/1}} /></div></Tooltip>
        case 6:
            return <Tooltip title="Annullato"><div><Annullato style={{ maxWidth: (vista === 'admin' ? '50px' : '80px'), aspectRatio: 1.81/1}} /></div></Tooltip>
        default:
            return;
    }
}

export default React.memo(IconaStatoViaggio);